@import "../../../styles/dashboard/variables.scss";

#PlanningAssistantFilter {
  #DSelect .selectComponent {
    .select__control {
      border-radius: 8px;
      height: auto;
      padding: 4px 0 4px 12px;
    }
    .css-1dimb5e-singleValue {
      color: $secondary300;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .select__indicators {
      .select__indicator svg {
        font-size: 16px;
        height: 16px;
        width: 16px;
      }
    }
  }
}
