@import "./../../../../../styles/dashboard/variables.scss";

#OrderProfit {
  display: flex;
  height: 150px;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  border-radius: 8px;
  border: 1px solid $success;
  background: $successBG;

  flex: 1 0 auto;

  h4 {
    color: $secondary200;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
  }
  h5 {
    color: $success;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    white-space: nowrap;
  }
}

// @media only screen and (max-width: 1500px) {
//   #OrderProfit {
//   }
// }
