@import "./../../../styles/landing/variables.scss";

#ProductShipper.ProductShipper {
  padding: 48px 0;
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 48px;

    .item {
      .img {
        height: 100px;
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .desc {
        h1 {
          color: $primaryContent;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 24px;
        }
        p {
          color: $gray200;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.14px;
        }
      }
    }
  }
  #LinkUnderline {
    justify-content: center;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ProductShipper.ProductShipper {
    .items {
      grid-template-columns: 1fr;
      gap: 12px;
      .item {
        .img {
          background-position: center;
        }
        .desc {
          h1 {
            text-align: center;
            margin-bottom: 12px;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #ProductShipper.ProductShipper {
    .button {
      a {
        font-size: 14px;
      }
    }
  }
}
