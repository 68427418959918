@import "./../../../../styles/dashboard/variables.scss";

#ProfileSidebar {
  width: 100%;
  padding: 12px 8px;
  border-radius: $borderRadius;
  background: $secondary25;
  transition: all 0.3s ease-in-out;
  a {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr;
    gap: 8px;
    .cover {
      background-position: center;
      background-size: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid $secondary50;
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 4px;
      width: 100%;
      opacity: 1;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      white-space: nowrap;

      h1 {
        color: $secondary500;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
      h3 {
        color: $secondary200;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  &.min {
    background: transparent !important;
    padding: 12px 4px;
    transition: all 0.3s ease-in-out;

    a {
      .text {
        opacity: 0;
      }
    }
  }
  &.active {
    background: $secondary300;
    a {
      .text {
        h1 {
          color: $white;
        }
        h3 {
          color: $secondary25;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ProfileSidebar {
    padding: 8px;
  }
}
