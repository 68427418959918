@import "./../../../../styles/landing/variables.scss";
.WebPlatformCarrierItem {
  padding: 48px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  h1 {
    color: $primaryContent;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
  .cover {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 250px;
    width: 100%;
  }
  .text {
    h3 {
      color: $primaryContent;
      font-size: 20px;
      text-align: center;
      font-style: normal;
      line-height: 32px;
      font-weight: 700;
      span {
        border-radius: 18px;
        border: 1px solid $green100;
        background: $green50;
        color: $green700;
        padding: 0px 12px;
        // white-space: nowrap;
      }
    }
    p {
      margin-top: 6px;
      color: $gray75;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.16px;
    }
  }
  #Button {
    display: flex;
    justify-content: center;
    button {
      width: auto;
      border-radius: $borderRadius;
      border: 1px solid $primaryBrand;
      background-color: transparent;
      color: $primaryBrand;
    }
  }
  &.withBackground {
    background-color: $green50;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: $mediaLarge) {
  .WebPlatformCarrierItem {
    padding: 24px 16px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  .WebPlatformCarrierItem {
    .text {
      h3 {
        span {
          border: none;
          background: none;
          padding: 0;
        }
      }
    }
  }
}
