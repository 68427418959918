@import "../../../styles/dashboard/variables.scss";

#StatusHistoryModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 80vh;
  min-height: 100px;
  overflow: auto;
  gap: 24px;
  .StatusHistoryModalContent {
    width: 100%;
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid $secondary50;
    background: #fff;
    .topContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titleDetailsContainer {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        .title {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
        }
        .status {
          display: flex;
          padding: 4px 12px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          border-radius: 34px;
          background: var(--Status--token-Info-BG, #eef2fa);
        }
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 26px;
          color: $secondary300;
        }
      }
    }
    .bottomContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      .leftContainer {
        display: flex;
        align-items: center;
        gap: 16px;
        .itemsContainer {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 18px;
              color: $secondary300;
            }
          }
          .text {
            color: $secondary300;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }
      .date {
        color: $secondary100;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .noUpdates {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100px;

    color: $secondary100;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
