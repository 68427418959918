@import "./../../../styles/dashboard/variables.scss";
#ActivityTable {
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: $secondary0;
  padding: 12px;
  min-height: 360px;
  width: 60%;
  overflow: hidden;
  .ActivityTableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    gap: 8px;
    box-shadow: inset 0px -1px 0px 0px $secondary50;
    .SeeAllButton {
      a {
        color: $info;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

}
@media only screen and (max-width: $mediaLarge) {
  #ActivityTable {
    width: 100%;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ActivityTable {
    .ActivityTableHeader {
      flex-direction: column;
      align-items: flex-start;
      box-shadow: none;

      .SeeAllButton {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
