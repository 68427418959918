@import "./../../../../../styles/dashboard/variables.scss";

#InfoWindowTrailer {
  width: 100%;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  .title {
    padding: 0px 4px;

    h3 {
      color: $secondary300;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .desc {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    .name {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
      overflow: hidden;
      .cover {
        min-width: 30px;
        height: 12px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      h3 {
        color: $secondary300;
        font-size: 13px;
        font-weight: 400;
      }
    }

    .tel {
      gap: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
      h3 {
        color: $secondary300;
        font-size: 13px;
        font-weight: 400;
      }
    }

    #noTrailer {
      white-space: nowrap;
      color: #b95000;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
