@import "../../../../styles/dashboard/variables.scss";

#PlanningAssistantTableTrucksTooltip .PlanningAssistantTableTrucksTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.26);
  background: $secondary0 !important;
  border-radius: $borderRadius;

  color: $secondary300;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  .driverContainer,
  .trailerContainer {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    .title {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .infoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .infoField {
        display: flex;
        align-items: center;
        gap: 4px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }
        .info {
          color: $secondary300;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
