.characterTooltip {
  background-color: #00b56c !important;
  color: #ffffff;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 16px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}
