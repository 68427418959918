@import "./../../../../styles/dashboard/variables.scss";

#ViewInstructionButton {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 27px;
  background: $infoBG;
  cursor: pointer;
  h3 {
    color: $info;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
      color: $info;
    }
  }
}
