@import "./../../../../styles/dashboard/variables.scss";

#ShipperTrackingSectionHeader {
  min-height: 50px;
  width: 100%;
  display: flex;
  padding-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $secondary50;
  gap: 8px;

  .BlockA {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
    }
    h2 {
      color: $secondary500;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .BlockB {
    #DButton {
      button {
        border: 2px solid $secondary500;
        background-color: $white;
        color: $secondary500;
        min-height: 36px;
        min-width: auto;
        font-weight: 600;
        padding: 0px 10px;
        border-radius: 6px;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ShipperTrackingSectionHeader {
    .BlockA {
      .icon {
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
