@import "../../../../../styles/dashboard/variables.scss";

#RejectQuotationField {
  vertical-align: middle;
  padding: 12px 10px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .text {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 16px;
        color: $error;
      }
    }
  }
}
