@import "../../styles/landing/variables.scss";

#Header {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: $headerHeight;
  background-color: $primarySurfance;
  box-shadow: 0px 2px 4px 0px rgba(50, 49, 94, 0.1);
  top: 0;
  transition: top 0.3s ease-in-out;
  &.open {
    top: 42px;
    transition: top 0.3s ease-in-out;
  }

  .headerContainer {
    width: 100%;
    height: $headerHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      .navLeft {
        display: flex;
        gap: 20px;
        align-items: center;
      }
      .navRight {
        display: flex;
        gap: 20px;
        align-items: center;
        .menu {
          display: flex;
          align-items: center;
          gap: 20px;
        }
        .additional {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }

      .mobileMenu {
        display: none;
        position: absolute;
        left: -100vw;
        z-index: 101;
        width: 100vw;
        height: calc(100vh - 80px);
        margin-top: 80px;
        transition: all 0.3s ease-in-out;
        background-color: $primaryContent;
        top: 0;
        opacity: 0;
        overflow-x: auto;

        .mobileMenuContainer {
          width: 100%;
          .list {
            display: flex;
            flex-direction: column;
          }
          .mobileMenuLogo {
            display: flex;
            padding: 12px;
            align-items: center;
            justify-content: center;
          }
        }

        &.open {
          left: 0;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #Header {
    .headerContainer {
      .nav {
        width: auto;
        .navLeft,
        .navRight .menu {
          display: none;
        }
        .mobileMenu {
          display: flex;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #Header {
    &.open {
      top: 0;
    }
  }
}
