@import "../../../../styles/dashboard/variables.scss";

#OrderStatusFieldTable {
  vertical-align: middle;
  padding: 12px 10px;

  .OrderStatusFieldTablecontent {
    display: inline-flex;

    .OrderStatusFieldTablecontentwrap {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 40px;
      white-space: nowrap;
      background-color: orange;
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      p {
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }
}
