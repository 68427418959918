@import "./../../../../styles/dashboard/variables.scss";

#shipperOrdersSidebarHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);

  .header {
    display: flex;
    width: 100%;
    padding: $pageWrapper $pageWrapper 0px $pageWrapper;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    // border-bottom: 1px solid $secondary50;
  }
  .filterContainer {
    width: 100%;
    padding: 16px $pageWrapper;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    #DSearch {
      width: 100%;
    }
    .filterButtonSide {
      border: 1px solid $secondary25;
      color: $secondary300;
      background-color: $secondary25;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 8px;
      padding: 8px 12px;
      min-height: 40px;
      border-radius: $borderRadius;
      cursor: pointer;

      h6 {
        color: $secondary300;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary300;
        }
      }
      &.active {
        border: 1px solid $secondary300;

        background-color: $secondary300;
        h6 {
          color: $white;
        }
        .icon {
          svg {
            color: $white;
          }
        }
      }
    }
    .clearBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $infoBG;
      // background-color: orange;
      border-radius: $borderRadius;
      padding: 8px 8px;
      min-height: 40px;

      svg {
        font-size: 16px;
        color: $info;
      }
    }
  }

  .statusesContainer {
    width: 100%;
    padding-bottom: 12px;
    display: flex;
    gap: 12px;
    white-space: nowrap;

    .statusesContainerWrapper {
      overflow-x: auto;
      display: flex;
      flex-direction: row;
      // gap: 12px;
      white-space: nowrap;
      padding-bottom: 4px;

      &::-webkit-scrollbar {
        width: 3px; /* Example width */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Example background color */
      }

      &::-webkit-scrollbar-thumb {
        background: #888; /* Example thumb color */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Example hover color */
      }

      .status {
        user-select: none;
        padding: 4px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        margin-right: 6px;

        &:first-child {
          margin-left: 24px;
        }
        &:last-child {
          margin-right: 24px;
        }

        border-radius: 24px;
        border: 1px solid $secondary100;
        background: $white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        h4 {
          color: $secondary100;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          transition: all 0.3s ease-in-out;
          white-space: nowrap;
        }
        &.active {
          background: $secondary300;
          border: 1px solid $secondary300;

          h4 {
            color: $white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #shipperOrdersSidebarHeader {
    .header {
      padding: $pageWrapperResponsive $pageWrapperResponsive 0px
        $pageWrapperResponsive;
    }
    .filterContainer {
      padding: 16px $pageWrapperResponsive;
    }

    .statusesContainer {
      .statusesContainerWrapper {
        .status {
          &:first-child {
            margin-left: 12px;
          }
          &:last-child {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
