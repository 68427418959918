@import "./../../styles/dashboard/variables.scss";

#MainTitle {
  h1 {
    color: $primaryContent;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  p {
    color: $primaryBrand;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
