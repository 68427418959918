@import "../../../../styles/landing/variables.scss";

#ForAboutShipper.ForAboutShipper {
  padding-top: 48px;
  padding-bottom: 48px;
  display: block;
  background-color: $grayNormal;
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 32px;
    overflow: hidden;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #ForAboutShipper.ForAboutShipper {
    .container {
      grid-template-columns: 1fr;
    }
  }
}
