@import "./../../../../styles/dashboard/variables.scss";
#BookShipment {
  .BookShipmentLarge {
    #DButton button {
      padding: 0px 12px;
      min-height: 36px;
      font-size: 14px;
      border-radius: 6px;
    }
  }

  .BookShipmentMobile {
    display: none;
    #DIconButton {
      min-height: 36px;
      min-width: 36px;
      border-radius: 6px;
      background-color: $primaryBrand;
      color: $secondary0;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #BookShipment {
    .BookShipmentLarge {
      display: none;
    }
    .BookShipmentMobile {
      display: block;
    }
  }
}
