@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderDimentionGroup {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  .dimentionGroupItem {
    width: 100%;
  }

  .dimentionGroupIconClose {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 22px;
    svg {
      font-size: 16px;
      color: $secondary100;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CreateOrderDimentionGroup {
    max-width: 100%;
    .dimentionGroupIconClose {
      display: none;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderDimentionGroup {
    flex-direction: column;
    .dimentionGroupIconClose {
      display: none;
    }
  }
}
