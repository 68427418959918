@import "./../../../../../../styles/dashboard/variables.scss";

#AssignPartnerButton {
  width: 100%;
  display: flex;
  justify-content: center;
  #DButton button {
    // height: auto;
    padding: 4px 10px;
    color: $primaryBrand;
    border-width: 2px;
    background-color: transparent;
    min-height: 24px;
    font-size: 13px;
    white-space: nowrap;
  }
}
