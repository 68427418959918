@import "./../../../styles/dashboard/variables.scss";

#NotificationModal {
  position: fixed;
  border-radius: 8px;
  background: $secondary0;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.12);
  width: 460px;
  height: 480px;
  left: 70px;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  #NotificationsPageHeader {
    padding: 16px 16px 0px 16px;
    h1 {
      font-size: 19px;
    }
  }
  .NotificationModalContent {
    margin-top: 12px;
    height: 100%;
    overflow-y: auto;
    #NoNotifications {
      p {
        width: 100%;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
  .NotificationModalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $secondary50;
    #DTextButton button {
      color: #2e5aac;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      min-height: auto;
      padding: 8px 0px;
    }
  }
  &.open {
    left: 40px;
    top: 70px;
  }
  &.shipper {
    left: auto;
    top: 65px;
    right: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #NotificationModal {
    width: 80vw;
    max-width: 460px;
    height: 400px;
    left: auto;
    right: 20px;
    top: 70px;
    #NotificationsPageHeader {
      h1 {
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #NotificationModal {
    .NotificationModalContent {
      margin-top: 8px;
    }
    #NotificationsPageHeader {
      padding: 12px 10px 0px 10px;
    }
  }
}
