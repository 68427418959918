@import "./../../../../../styles/dashboard/variables.scss";

#EditOrderPriceModal {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 110px;
  .EditOrderPriceModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
