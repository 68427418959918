@import "../../../../styles/landing/variables.scss";

#SignUpForm.SignUpForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  #FormTitle {
    flex-direction: column-reverse;
  }
  .group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .item {
      width: 100%;
      border: 1px solid $primaryBorder;
      box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
      background: $primarySurfance;
      padding: 20px;
      border-radius: $borderRadiusLarge;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      .desc {
        h2 {
          color: $primaryContent;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          transition: all 0.3s ease-in-out;
          margin-bottom: 24px;
        }
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.5px;
          color: $primaryContent;
          transition: all 0.3s ease-in-out;
        }
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 32px;
          color: $primaryBrand;
          transition: all 0.3s ease-in-out;
        }
      }

      &:hover {
        background: $primaryBrand;
        .desc {
          h2 {
            color: $primarySurfance;
          }
          p {
            color: $primarySurfance;
          }
        }
        .icon {
          svg {
            color: $primarySurfance;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SignUpForm.SignUpForm {
    .group {
      .item {
        .desc {
          h2 {
            font-size: 18px;
            margin-bottom: 12px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
