@import "./../../../styles/landing/variables.scss";
#TmsSolutionCarrier.TmsSolutionCarrier {
  background-color: $grayNormal;
  padding: 48px 0;
  overflow: hidden;
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
    gap: 24px;
    .left {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .right {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      .cover {
        background-position: center;
        // height: 100%;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 440px;
        background-size: contain;
        border-radius: $borderRadiusLarge;
        transition: all 0.4s ease-in-out;
        // box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
      }
    }
  }
  .actions {
    margin-top: 32px;
    display: flex;
    gap: 32px;
    justify-content: center;
    #Button {
      width: auto;
    }
    .requestDemo {
      display: flex;
      align-items: center;
      padding: 9px 24px;
      color: $primaryBrand;
      background-color: transparent;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      border: 1px solid $primaryBrand;
      border-radius: $borderRadius;
      transition: all 0.3s ease-in-out;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: $secondarySurface;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #TmsSolutionCarrier.TmsSolutionCarrier {
    .container {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 12px;
      gap: 12px;
      .left {
        gap: 8px;
      }
      .right {
        grid-row: 1;
        .cover {
          min-height: 300px;
        }
      }
    }
    .actions {
      margin-top: 16px;
      flex-direction: column-reverse;
      gap: 8px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #TmsSolutionCarrier.TmsSolutionCarrier {
    .actions {
      width: 100%;
      #Button {
        width: 100%;
        a {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
