@import "./../../../../../styles/dashboard/variables.scss";

#BoardDetails {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .BoardDetailsContainer {
    margin-top: $pageWrapper;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #BoardDetails {
    .BoardDetailsContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #BoardDetails {
    padding: $pageWrapperResponsive;
    margin-top: $pageWrapperResponsive;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #BoardDetails {
    .BoardDetailsContainer {
      grid-template-columns: 1fr;
    }
  }
}
