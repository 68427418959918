@import "./../../../../styles/dashboard/variables.scss";

#DateField {
  padding: 12px 10px;
  vertical-align: middle;

  .active {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: nowrap;

    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px;
      color: $secondary300;
      white-space: nowrap;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $secondary100;
        font-size: 16px;
      }
    }
  }
  .inActive {
    display: flex;
    align-items: center;
    p {
      white-space: nowrap;
      color: $warning;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
