@import "../../../styles/dashboard/variables.scss";

#DCheckBox {
  display: flex;
  align-items: center;
  width: 100%;
  user-select: none;
  .checkbox {
    display: flex;
    gap: 6px;
    cursor: pointer;
    .icon {
      min-width: 18px;
      height: 18px;
      border: 1px solid $primary500;
      border-radius: 2px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      &.checked {
        background-color: $primary500;

        svg {
          font-size: 13px;
          color: $white;
        }
      }
    }

    p {
      color: $secondary500;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
