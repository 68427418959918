@import "./../../../../../../../styles/dashboard/variables.scss";

#PartnersGroupModal {
  width: 100%;
  border-radius: $borderRadius;
  border: 1px solid $secondary100;
  min-height: 50px;
  overflow: hidden;

  .PartnersGroupModalWrapper {
    padding: 12px 24px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 12px;
    width: auto;
    overflow-x: auto;
    .to {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }
    .Allpartners {
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }
    .item {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 24px;
      background: #dadee3;
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #09101d;
        white-space: nowrap;
      }
      .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
    }
  }
}
