@import "../../../../styles/landing/variables.scss";

#ForWorkflow.ForWorkflow {
  margin: 0 auto;
  background-color: $grayNormal;
  padding-top: 48px;
  padding-bottom: 48px;
  .container {
    gap: 80px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    .items {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      justify-content: center;
    }
    .img {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: $borderRadiusLarge;
        overflow: hidden;
        width: 100%;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ForWorkflow.ForWorkflow {
    .container {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
      gap: 40px;
      .img {
        grid-row: 1;
        img {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ForWorkflow.ForWorkflow {
    .container {
      .img {
        img {
          width: 100%;
        }
      }
    }
  }
}
