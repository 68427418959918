@import "./../../../styles/dashboard/variables.scss";

#DocumentsDetails {
  width: 100%;
  padding: 12px 24px;
  min-height: 194px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  .DocumentsDetailsHeader {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    padding-bottom: 12px;
    gap: 8px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
    }
    .titleContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      h1 {
        color: $secondary500;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
      #DButton button {
        background-color: transparent;
        border: 2px solid $secondary500;
        color: $secondary500;
        // font-size: 14px;
        font-weight: 600;
        padding: 0px 10px;
        border-radius: 6px;
        min-height: 36px;
        min-width: auto;
      }
    }
  }

  p {
    color: $secondary75;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .documentList {
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    max-height: 110px;
    overflow-y: auto;
    padding-right: 4px;
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #DocumentsDetails {
    gap: 12px;
    padding: 12px;
    min-height: 160px;
    min-width: auto;
    .DocumentsDetailsHeader {
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .titleContainer {
        #DButton {
          width: auto;
        }
      }
    }
    .documentList {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
