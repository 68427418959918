@import "../../../../../../styles/dashboard/variables.scss";

#PartnerDetails {
  padding: $pageWrapper;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: $pageWrapper;

    .actions {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
  .participantInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #PartnerDetails {
    padding: $pageWrapperResponsive;
    .header {
      margin-bottom: $pageWrapperResponsive;
    }
  }
}
