@import "./../../../styles/dashboard/variables.scss";

#TrackingMapView {
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow: hidden;
  .gm-style div {
    border: none !important;
  }
}
