@import "../../../../styles/landing/variables.scss";

#JoinUs.JoinUs {
  background-size: cover;
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-attachment: scroll;
  .blar {
    background: rgba(79, 87, 91, 0.3);
    width: 100%;
    height: 100%;
    .wrapper {
      height: 100%;
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      h1,
      p {
        color: $primarySurfance;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #JoinUs.JoinUs {
    background-attachment: scroll;
  }
}
