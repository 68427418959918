@import "../../../styles/dashboard/variables.scss";

#Charts {
  width: 100%;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: $secondary0;
  padding: 16px;
  min-width: 188px;
  .chartsHeader {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    #DDropdown {
      width: 200px;
      .dropdown-content {
        border: none;
        padding: 0;
        border-radius: 8px;
      }
      .calendar {
        width: 250px;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 5px;
        background-color: #f9f9f9;
      }
      .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
        background-color: $secondary100;
        color: $secondary0;
        border-radius: 4px;
      }
      .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
        background-color: $primary500;
        color: $secondary0;
        border-radius: 4px;
      }
    }
    .chartsSwitcher {
      border: 1px solid $secondary50;
      padding: 4px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      .chartsSwitcherItem {
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        white-space: nowrap;
        &.active {
          color: $secondary0;
          background: $secondary200;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .skeleton {
    width: 100%;
    height: 350px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 700px) {
  #Charts {
    padding: 8px;
    .chartsHeader {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      #DDropdown {
        width: 100%;
        .calendar {
          width: 100%;
        }
      }
      .chartsSwitcher {
        .chartsSwitcherItem {
          padding: 4px 8px;
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: $mediaMedium) and (max-width: 1000px) {
  #Charts {
    .chartsHeader {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      #DDropdown {
        max-width: 100%;
        width: 100%;
        .calendar {
          width: 100%;
        }
      }
    }
  }
}
