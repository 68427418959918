@import "../../../../../styles/dashboard/variables.scss";

#AirPackageDetails {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .titleContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    background-color: $primary100;
    padding: 12px 24px;
    gap: 10px;
    border-radius: $borderRadius;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 20px;
        color: $secondary200;
      }
    }
    .title {
      color: $secondary500;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .AirPackageDetailsShipper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 408px;
    overflow: auto;
    .AirPackageDetailsShipperContainer {
      display: grid;
      grid-template-columns: 180px 1fr;
      gap: 12px;
      .AirPackageDetailsShipperCover {
        position: relative;
        width: 100%;
        height: 120px;
        border-radius: $borderRadiusLarge;
        background-color: $secondary25;
        border: 1px solid $secondary50;
        display: flex;
        align-items: center;
        justify-content: center;
        .AirPackageDetailsShipperImg {
          width: 130px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
            color: $secondary100;
          }
        }
        .AirPackageDetailsShipperIndex {
          position: absolute;
          top: 5px;
          left: 5px;

          color: $primary500;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
      .AirPackageDetailsShippercontent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        p {
          color: $secondary100;
          font-size: 16px;
          font-weight: 400;
          span {
            color: $secondary300;
            font-weight: 600;
            margin-left: 4px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #AirPackageDetails {
    .titleContainer {
      padding: 12px;
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .title {
        font-size: 20px;
      }
    }
    .AirPackageDetailsShipperContainer {
      grid-template-columns: 1fr;
      .AirPackageDetailsShipperCover {
        width: 100%;
      }
    }
  }
}
