@import "../../../../styles/dashboard/variables.scss";

#ShipperOrderDetailsOffer {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $primary200;
  gap: 12px;
  max-width: 900px;
  margin: 24px 0 0 0;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 60px;
      height: 60px;
    }
  }
  .offerTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .offerTextTitle {
      color: $secondary200;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .offerText {
      max-width: 300px;
      overflow: hidden;
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .paymentDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .paymentDetailsTitle {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .paymentDetailsItems {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      .paymentDetailsItem {
        display: flex;
        flex-direction: column;
        .title {
          color: $secondary400;
          font-size: 13px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px;
          white-space: nowrap;
        }
        .value {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          white-space: nowrap;
        }
      }
    }
  }
  .line {
    width: 1px;
    height: 80px;
    background-color: $secondary50;
  }
  .offerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .offerPrice {
      color: $primary500;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
        font-size: 24px;
      }
    }
    .buttonsContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      .reject {
        button {
          background-color: #ff5c5c;
          border: 1px solid #ff5c5c;
          &:hover {
            background-color: #ec3535;
            border: 1px solid #ec3535;
          }
        }
        &.disabled {
          button {
            background-color: #f4988e;
            border: 1px solid #f4988e;
            &:hover {
              background-color: #f4988e;
              border: 1px solid #f4988e;
            }
          }
        }
      }

      .confirm {
        &.disabled {
          button {
            background-color: $primary100;
            border: 1px solid $primary100;
            &:hover {
              background-color: $primary100;
              border: 1px solid $primary100;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #ShipperOrderDetailsOffer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .line {
      display: none;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperOrderDetailsOffer {
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr;
    .offerTextContainer {
      align-items: center;
    }
    .paymentDetailsContainer {
      align-items: center;
    }
  }
}
