@import "./../../../styles/dashboard/variables.scss";

#PartnerRateSection {
  padding: 16px 24px;
  width: 100%;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    color: $secondary300;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  p {
    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .rateContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ratePartner {
      font-size: 42px;
    }
  }

  .alreadyRate {
    width: 100%;
    display: flex;
    gap: 12px;
    p {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #PartnerRateSection {
    padding: 12px;
    .alreadyRate {
      flex-direction: column;
    }
  }
}
