@import "./../../../../styles/dashboard/variables.scss";

#NoTrackingTruckList {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  #DSearch {
    width: 100%;
  }
  .NoTrackingTruckListContainer {
    overflow-x: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .NoTrucksList {
      color: $secondary100;
      font-size: 14px;
      text-align: center;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
