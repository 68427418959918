@import "../../../../styles/landing/variables.scss";

#Sticky {
  height: $headerHeight;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  &.open {
    height: 122px;
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #Sticky {
    &.open {
      height: $headerHeight;
      transition: all 0.3s ease-in-out;
    }
  }
}
