@import "../../../styles/dashboard/variables.scss";

#PreferencesFilter {
  display: flex;
  align-items: center;
  gap: 8px;

  .filter {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    cursor: pointer;
    user-select: none;

    border-radius: 32px;
    border: 1px solid $violet;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $violet;
      }
    }
    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .qty {
      color: $violet;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      font-size: 16px;
      color: $secondary100;
    }
  }
}
