@import "./../../../../../styles/dashboard/variables.scss";

#OrderDetailsTMC {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  .trakingContainer {
    width: 100%;
    display: flex;
    gap: 24px;
    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #OrderDetailsTMC {
    padding: $pageWrapperResponsive;
    .trakingContainer {
      flex-direction: column;
    }
  }
}
