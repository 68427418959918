@import "./../../../../styles/dashboard/variables.scss";

#InvoiceAmountField {
  vertical-align: middle;
  padding: 12px 10px;
  .container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    h4 {
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
      text-align: right;
      padding-right: 8px;
    }

    .paid {
      border-left: 1px solid $secondary50;
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      h5 {
        color: $primary500;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        white-space: nowrap;
      }
      h6 {
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        white-space: nowrap;
      }
    }
  }
}
