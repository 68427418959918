@import "./../../../../styles/landing/variables.scss";

#Password.Password {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  #FormTitle {
    h1 {
      font-size: 20px;
    }
  }

  #NotHaveAccount {
    a {
      color: $secondaryContent;
      .line {
        background-color: $secondaryContent;
      }
    }
  }
}
