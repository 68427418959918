@import "./../../../../styles/dashboard/variables.scss";

#SDashboard {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(244, 246, 249, 0.6) 0%, rgba(244, 246, 249, 0.6) 100%),
    #fff;

  .SDashboardStatistic {
    margin: 16px 0;
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 16px;
  }
  .SDashboardTables {
    display: flex;
    gap: 16px;
  }
  .suspensionNoticeContainer {
    max-width: 700px;
    display: flex;
    padding: 12px 16px;
    gap: 12px;
    align-items: center;

    border-radius: 8px;
    background: $errorBG;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: $error;
        font-size: 24px;
      }
    }
    // .textContainer {
    h1 {
      color: $error;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    h2 {
      color: $error;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    // }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SDashboard {
    padding: $pageWrapperResponsive;
    .SDashboardTables {
      flex-direction: column;
    }
  }
}
@media only screen and (max-width: $mediaMedium) {
  #SDashboard {
    .SDashboardStatistic {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
