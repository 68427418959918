@import "./../../../../styles/dashboard/variables.scss";

.TrucksListHeader {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;
  width: 100%;
  .TrucksListHeaderLeft {
    display: flex;
    gap: 12px;
  }
  .TrucksListHeaderRight {
    // white-space: nowrap;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    .TrucksListHeaderRightBlackButton {
      #DButton button {
        border: 1px solid $secondary500;
        background-color: $secondary500;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .TrucksListHeader {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: $mediaSmall) {
  .TrucksListHeader {
    .TrucksListHeaderLeft {
      flex-direction: column;
    }
    .TrucksListHeaderRight {
      flex-direction: column;
    }
  }
}
