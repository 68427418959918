@import "./../../../../../../styles/dashboard/variables.scss";

#ShipperAirPackageItem {
  width: 100%;
  padding: $pageWrapper;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  position: relative;

  .productAndPackageContainer {
    width: 100%;
    display: flex;
    gap: 12px;
    .productWrapper {
      width: 100%;
      max-width: 732px;
    }
    .packageTypeContainer {
      width: 100%;
      max-width: 250px;
    }
  }

  .productAdrWrapper {
    width: 100%;
    max-width: 732px;
  }
  .packageDimentionForm {
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    max-width: 1524px;
    .BlockA {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }
    .BlockB {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }
    .line {
      width: 2px;
      height: 60px;
      background-color: $secondary50;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShipperAirPackageItem {
    .packageDimentionForm {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
      .line {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperAirPackageItem {
    padding: $pageWrapperResponsive;

    .productAndPackageContainer {
      flex-direction: column;
      gap: 12px;
      .productWrapper {
        max-width: 100%;
      }
      .packageTypeContainer {
        max-width: 100%;
      }
    }
    .packageDimentionForm {
      .BlockA,
      .BlockB {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
}
