@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderRemoveItemBtn {
  position: absolute;
  right: $pageWrapper;
  top: 6px;
  #DTextButton {
    button {
      color: $error;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderRemoveItemBtn {
    right: $pageWrapperResponsive;
    top: 4px;
  }
}
