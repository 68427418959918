@import "./../../../../../styles/dashboard/variables.scss";

#TruckAdditionalDetails {
  padding: 12px 16px;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  min-height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .TruckAdditionalDetailsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .TruckAdditionalDetailsHeaderContainerItem {
      display: flex;
      gap: 12px;
      .cover {
        width: 35px;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      h1 {
        color: $secondary200;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
      p {
        color: $secondary100;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
    .TruckAdditionalDetailsStatus {
      border-radius: 34px;
      background: $successBG;
      padding: 8px 12px;
      color: $success;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }
    .TruckAdditionalDetailsStatusInactive {
      border-radius: 34px;
      background: $errorBG;
      padding: 8px 12px;
      color: $error;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }
    .AddDowntime {
      cursor: pointer;
      color: $primaryBrand;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }

  .LinkedTrailer {
    h1 {
      color: $secondary500;
      font-size: 13px;
      font-style: normal;
      margin-bottom: 4px;
      font-weight: 600;
    }
    .LinkedTrailerContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3px;
      .NoTrailer {
        color: #b95000;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
      .LinkedTrailerContainerItem {
        display: flex;
        align-items: center;
        gap: 8px;
        h3 {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        p {
          color: $secondary300;

          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
        }
        .cover {
          width: 40px;
          height: 20px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .LinkedDriver {
    h1 {
      color: $secondary500;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 4px;
    }
    .LinkedDriverContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .NoDriver {
        color: $warning;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
      .LinkedDriverContainerItem {
        display: flex;
        align-items: center;
        gap: 8px;
        h3 {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        p {
          color: $secondary300;

          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
        }
        .icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: $secondary200;
          }
        }
      }
    }
  }
  .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
    #DTextButton button {
      height: 100%;
      border: 2px solid $secondary500;
      padding: 2px 8px;
      border-radius: $borderRadius;
      font-size: 13px;
    }
    // .actionChange {
    //   cursor: pointer;
    //   color: $primaryBrand;
    //   font-size: 14px;
    //   font-style: normal;
    //   font-weight: 600;
    // }
  }
}
