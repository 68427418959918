@import "./../../../../../../styles/dashboard/variables.scss";

#TruckDetails {
  padding: $pageWrapper;
  min-height: 100vh;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: $pageWrapper;
    .actions {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .TruckDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .TruckDetailsContainerItems {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 24px;
      .TruckDetailsContainerItemLeft {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
@media only screen and (max-width: $mediaOrigin) {
  #TruckDetails {
    .TruckDetailsContainer {
      .TruckDetailsContainerItems {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #TruckDetails {
    padding: $pageWrapperResponsive;
    .header {
      margin-bottom: $pageWrapperResponsive;
    }
  }
}
