@import "../../../../styles/landing/variables.scss";

#NavItemMobile {
  border-bottom: 1px solid gray;
  a {
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px;

      p {
        color: $primarySurfance;
        font-size: 18px;
        font-weight: 600;
        transition: all 0.3s ease-in-out;
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          transition: all 0.3s ease-in-out;
          font-size: 24px;
          color: $primarySurfance;
        }
      }
    }

    &.active {
      .item p,
      .arrow svg {
        color: $primaryBrand;
      }
    }
  }
}
