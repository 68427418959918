@import "./../../../styles/dashboard/variables.scss";

#SimilarOrdersNavbar {
  overflow: hidden;
  margin-top: 24px;
  margin-bottom: 12px;
  width: calc(100vw - ($sidebarMax + 60px));
  transition: all 0.3s ease-in-out;
  user-select: none;
  &.min {
    width: calc(100vw - ($sidebarMin + 60px));
  }
  .swiper {
    height: 68px;
    .swiper-slide {
      width: 134px;
      height: 100%;

      .content {
        border-radius: $borderRadiusLarge;
        border: 1px solid $secondary50;
        background: $secondary25;
        cursor: pointer;
        position: relative;
        text-align: center;
        padding: 12px;
        height: 100%;
        display: flex;
        gap: 4px;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .dot {
          width: 4px;
          height: 4px;
          background-color: #b95000;
          border-radius: 50%;
          position: absolute;
          right: 5px;

          top: 5px;
        }
        .status {
          border-radius: 34px;
          border: 1px solid $secondary50;
          background: $secondary25;
          padding: 4px 8px;
          color: $warning;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
        }
        .message {
          color: $secondary500;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
        }
        &.assign {
          .dot {
            display: none;
          }
          .message {
            display: flex;
            gap: 4px;
            align-items: center;
            .cover {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 8px;
              height: 10px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
            p {
              color: $primaryBrand;
              font-size: 11px;
              line-height: 11px;
              font-style: normal;
              font-weight: 600;
              vertical-align: middle;
              line-height: 16px; /* 145.455% */
            }
          }
          .status {
            color: $secondary200;
          }
        }
      }
      #activeLink {
        border: 1px solid $secondary500;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #SimilarOrdersNavbar {
    margin-top: 12px;
    transition: none;
    width: calc(100vw - 34px) !important;
  }
}
