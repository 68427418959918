@import "../../../styles/dashboard/variables.scss";

#DErrorText {
  margin-top: 5px;
  padding-left: 5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  .DErrorTextContent {
    color: $error;
    font-size: 12px;
  }
}
