@import "./../../../../../styles/dashboard/variables.scss";

#TrackingShipperHeader {
  width: 100%;
  background-color: $white;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 0px 12px;

  .BlockA {
    display: flex;
    gap: 4px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 3px; /* Example width */
      height: 3px; /* Example height */
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; /* Example background color */
    }

    &::-webkit-scrollbar-thumb {
      background: #888; /* Example thumb color */
      height: 3px; /* Example height */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555; /* Example hover color */
    }
    .betweenIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 24px;
        color: $secondary75;
      }
    }

    .item {
      display: flex;
      align-items: center;
      gap: 2px;
      // margin-right: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
        }
      }
      h4 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
      }
      &.from {
        .icon {
          color: $error;
        }
      }
      &.to {
        .icon {
          color: $success;
        }
      }
      &.current {
        h4 {
          color: $info;
          font-weight: 500;
        }
        .icon {
          color: $info;
        }
      }
    }

    .betweenIcon:last-child {
      display: none;
    }
  }

  .BlockB {
    .icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
        color: $secondary100;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #TrackingShipperHeader {
  }
}
