@import "./../../../../styles/landing/variables.scss";

#DigitalFreightSea.DigitalFreightSea {
  padding: 48px 0;
  overflow: hidden;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #DigitalFreightSea.DigitalFreightSea {
    .container {
      grid-template-columns: 1fr;
    }
  }
}
