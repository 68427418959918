@import "./../../../../styles/dashboard/variables.scss";

#DHeader {
  position: relative;
  // height: 60px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  .closeIcon {
    position: absolute;
    right: 0;
    top: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary25;
    border-radius: $borderRadiusLarge;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    svg {
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      color: $secondary500;
    }
  }
  &.min {
    // height: 120px;
    .fixed {
      flex-direction: column;
    }
    .closeIcon {
      right: 6px;
      top: 60px;
      svg {
        transform: rotate(180deg);
      }
    }
    .NotificationsIcon {
      right: 6px;
      top: 100px;
    }
  }
  .NotificationsIcon {
    position: absolute;
    right: 40px;
    top: 6px;
  }
}
