@import "./../../../styles/dashboard/variables.scss";

#DLabel {
  margin-bottom: 2px;
  padding-left: 5px;
  overflow: hidden;
  width: 100%;
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: $primaryContent;
    cursor: pointer;
    white-space: nowrap;

    span {
      color: $error;
    }
  }
}
