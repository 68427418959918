@import "../../../../../styles/dashboard/variables.scss";

#TruckGpsTracking {
  padding: 12px 16px;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  min-height: 148px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .TruckGpsTrackingStatus {
    display: flex;
    justify-content: flex-end;
    .TruckGpsTrackingStatusOnline {
      display: flex;
      gap: 8px;
      border-radius: 34px;
      background: $successBG;
      padding: 8px 12px;
      color: $success;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }
    .TruckGpsTrackingStatusOffline {
      display: flex;
      gap: 8px;
      border-radius: 34px;
      background: $errorBG;
      padding: 8px 12px;
      color: $error;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .TruckGpsTrackingContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
