@import "../../../styles/dashboard/variables.scss";

#profileHeader {
  position: relative;
  display: flex;
  z-index: 5;
  .profilePicture {
    position: absolute;
    top: -60px;
    left: 24px;
    width: 120px;
    height: 120px;
    border-radius: $borderRadiusLarge;
    background-color: $secondary0;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #profileHeader {
    .profilePicture {
      left: calc(50% - 60px);
    }
  }
}
