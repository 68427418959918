@import "../../../styles/dashboard/variables.scss";

#FilterRouteSelectForm {
  width: 250px;
  .dropdownContentContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .saveButton {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 700px) {
  #FilterRouteSelectForm {
    width: 100%;
  }
}
