@import "./../../../styles/dashboard/variables.scss";

#DFile {
  .noDocument {
    text-align: center;
    border-radius: $borderRadiusLarge;
    position: relative;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%235555' stroke-width='3' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .cover {
      background-position: center;
      background-size: cover;
      width: 48px;
      height: 48px;
    }
    h3 {
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    input {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .item {
    padding: 16.5px;
    width: 100%;
    border-radius: $borderRadiusLarge;
    border: 1px solid $secondary50;
    background: $secondary0;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    .details {
      display: flex;
      gap: 8px;
      .type {
        background-position: center;
        background-size: cover;
        width: 37px;
        height: 42px;
      }
      .text {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: flex-start;
        h1 {
          color: $secondary300;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
        }
        p {
          color: $primaryBrand;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }

    .tag {
      border-radius: 4px;
      background: $secondary25;
      padding: 4px 8px;
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
