@import "./../../../styles/landing/variables.scss";

#TextArea {
  textarea {
    background-color: $primarySurfance;
    width: 100%;
    height: 110px;
    resize: none;
    padding: 10px 12px;
    border: 1px solid $secondaryBorder;
    border-radius: $borderRadius;
    outline: none;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    user-select: none;
    color: $primaryContent;

    &::placeholder {
      color: $tetriarySurface;
    }

    &:focus {
      border-color: $primaryBorder;
      &::placeholder {
        color: $gray100;
      }
    }
    &:hover {
      border-color: $primaryBorder;
    }
  }

  &.error {
    textarea {
      border: 1px solid red;
    }
  }
}
