@import "./../../../../styles/dashboard/variables.scss";

#ShipperOrderListCards {
  padding: 12px;
  height: 100%;
  width: 100%;

  .orderList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: $borderRadiusLarge;
    padding-right: 5px;
  }
}

@media only screen and (max-width: $mediaLarge) {
}
