@import "./../../../../styles/dashboard/variables.scss";

#TruckGPSStatusField {
  padding: 12px 10px;
  vertical-align: middle;
  text-align: center;

  .TruckGPSStatusField {
    display: inline-flex;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 34px;
    background-color: $successBG;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $success;
        font-size: 12px;
      }
    }
    p {
      color: $success;
      font-size: 11px;
      font-weight: 600;
      white-space: nowrap;
    }

    &.nocheck {
      background-color: #feefef;

      .icon {
        svg {
          color: $error;
        }
      }
      p {
        color: $error;
      }
    }
  }
}
