@import "../../../styles/dashboard/variables.scss";

#ShipperCalendarTable {
  position: relative;
  min-height: 60vh;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  border: 1px solid $secondary50;
  flex-direction: column;

  overflow: hidden;
  .day {
    position: absolute;
    width: calc(100% / 14);
    height: 100%;
    background: rgba(244, 246, 249, 0.3);
    border-right: 1px solid $secondary50;
    &:last-child {
      border-right: none;
    }
  }
  .orderContainer {
    position: relative;
    width: 100%;
    min-height: 64px;
    &:last-child {
      margin-bottom: 24px;
    }
  }
  .noOrders {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    color: $secondary200;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (max-width: $mediaLarge) {
  #ShipperCalendarTable {
    .day {
      width: 100px;
    }
  }
}
