@import "../../../../../styles/dashboard/variables.scss";

#profileInfoItem {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 12px;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $secondary100;
      width: 16px;
      height: 16px;
    }
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: $secondary100;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #profileInfoItem {
    margin-right: 0px;
  }
}
