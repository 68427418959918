@import "../../../styles/dashboard/variables.scss";

#DDropdown {
  max-width: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dropdown {
    display: inline-block;
    position: relative;
    font-family: inherit;
    .dropdown-container {
      display: relative;
      .dropdownPlaceholder {
        position: absolute;
        top: 50%;
        font-weight: 500;
        left: 12px;
        transform: translateY(-50%);

        color: $secondary200;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .dropdownTitle {
        width: 100%;
        border-radius: 25px;
        background: $secondary25;
        padding: 0px 0px 0px 12px;
        color: $secondary300;

        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 153.846% */

        transition: all 0.3s ease-in-out;
        height: 40px;
        cursor: pointer;
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        &:focus {
          outline: none;
        }
        // &::placeholder {
        //   all: unset;
        //   font-size: 16px !important;
        //   font-style: normal !important;
        //   font-family: inherit !important;
        //   font-weight: 400 !important;
        //   user-select: none;
        //   color: $secondary100;
        //   font-weight: 500;
        // }
      }
      .dropdownIcon {
        position: absolute;
        width: 37px;
        height: 38.4px;
        right: 0;
        top: calc(50% - 19px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 20px;

          color: #848a8d;
        }
      }
      .dropdownIndicatorSeparator {
        position: absolute;
        top: 9px;
        right: 36.5px;
        width: 1px;
        height: 22px;
        background-color: hsl(0, 0%, 80%);
      }
      .dropdownClear {
        position: absolute;
        width: 37px;
        height: 38.4px;
        right: 0;
        top: calc(50% - 19px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 20px;
          path {
            fill: #848a8d;
          }
        }
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      background-color: #ffffff;
      border: 1px solid #ccc;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px;
      min-width: 100%;
      z-index: 1;
      input {
        margin-bottom: 10px;
        padding: 8px;
        font-size: 14px;
      }

      &button {
        padding: 8px;
        background-color: #4caf50;
        color: #fff;
        border: none;
        cursor: pointer;
      }
    }

    &.open {
      .dropdown-content {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #DDropdown {
    max-width: none;
  }
}
