@import "./../../../../../../styles/dashboard/variables.scss";

#RoadLocationTimeShipper {
  width: 100%;
  max-width: 500px;

  display: flex;
  align-items: flex-start;
  gap: 14px;

  .timeSelectContainer {
    width: 100%;
    display: flex;
    gap: 12px;
    .removeButton {
      padding-top: 26px;
    }
  }
  .addTimeRangeButton {
    padding-top: 26px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #RoadLocationTimeShipper {
    flex-direction: column;
    max-width: 100%;

    .addTimeRangeButton {
      padding-top: 0px;
    }
  }
}
