@import "./../../../../styles/landing/variables.scss";

#FaqSectionShipper.FaqSectionShipper {
  padding: 48px 0px;
  .container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
    .questions {
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #FaqSectionShipper.FaqSectionShipper {
    .container {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
    }
  }
}
