@import "./../../../../styles/dashboard/variables.scss";

#MaintenanceListHeader {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;
  .MaintenanceListHeaderLeft {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #MaintenanceListHeader {
    flex-direction: column-reverse;
    .MaintenanceListHeaderLeft {
      flex-direction: column;
    }
  }
}
