@import "./../../../../../styles/dashboard/variables.scss";

#MarkerAInfoWindowF {
  padding: 16px;
  background-color: $white;
  min-width: 200px;
  min-height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: $secondary300;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
