@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderAdrSection {
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  .adrGroupContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderAdrSection {
    .adrGroupContainer {
      grid-template-columns: 1fr;
    }
  }
}
