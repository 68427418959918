@import "./../../../../styles/dashboard/variables.scss";

#CardSummary {
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: $secondary0;
  padding: 16px;
  min-width: 188px;
  .header,
  .desc {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 12px;
    h1 {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      &:lang(ka) {
        font-size: 14px;
      }
    }
    p {
      color: $secondary300;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .desc {
    padding: 12px 0px 0px;
    border-top: 1px solid $secondary50;
    .inc,
    .dec {
      display: flex;
      align-items: flex-end;
      gap: 2px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        svg {
          font-size: 19px;
        }
      }
      p {
        color: inherit;
      }
    }
    .dec {
      color: $error;
      .icon {
        transform: rotateY(180deg);
      }
    }
    .inc {
      color: $primary500;
    }
  }
}
