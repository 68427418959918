@import "./../../../../styles/dashboard/variables.scss";
#ShipperChartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  gap: 12px;
  .calendarPickerContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #ShipperChartHeader {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperChartHeader {
    .calendarPickerContainer {
      justify-content: center;
    }
  }
}
