@import "./../../../../styles/dashboard/variables.scss";

#PartnerField {
  vertical-align: middle;
  .PartnerFieldContainer {
    display: flex;
    gap: 4px;
    padding: 12px 10px;
    align-items: center;
    h3 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      white-space: nowrap;
    }
    .PartnerFieldContainerIcon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary200;
      }
    }
  }
  &.link {
    &:hover {
      cursor: pointer;
      background-color: $secondary50;
    }
  }
}
