@import "./../../../../styles/dashboard/variables.scss";

#DLinkToButton {
  #DButton button {
    border: 2px solid $primaryBrand;
    background-color: transparent;
    color: $primaryBrand;
    min-height: 24px;
    font-size: 13px;
    white-space: nowrap;
  }
}
