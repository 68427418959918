@import "./../../../../../styles/dashboard/variables.scss";

#TrackingTruckListItem {
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid $secondary50;
  background: #fff;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .TrackingTruckListItemLeft {
    display: grid;
    grid-template-columns: 25px 1fr;
    align-items: center;
    gap: 6px;
    .cover {
      width: 25px;
      height: 20px;
      background-position: center;
      background-size: contain;
      position: relative;
      background-repeat: no-repeat;
    }
    h3 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
    }
    p {
      color: $secondary200;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      margin-top: 4px;
    }
  }
  .TrackingTruckListItemApp {
    border-radius: 34px;
    background: #eef2fa;
    color: #2e5aac;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 12px;
    text-align: center;
    white-space: nowrap;
  }
  .TrackingTruckListItemGPS {
    border-radius: 34px;
    background: $successBG;
    color: $success;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 12px;
    text-align: center;
    white-space: nowrap;
  }
  &.active {
    border: 2px solid $primaryBrand;
  }
}
