@import "./../../../../styles/dashboard/variables.scss";

#DTextButton {
  button {
    min-height: auto;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border-radius: $borderRadiusLarge;
    border: 1px solid transparent;
    background-color: transparent;
    color: $secondary500;
    font-size: 16px;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    text-align: center;
    white-space: nowrap;

    .buttonIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 15px;
      }
      &.leftIconButton {
        margin-right: 4px;
      }
      &.rightIconButton {
        margin-left: 4px;
      }
    }
  }

  &.danger {
    button {
      color: $error;
    }
  }
  &.primary {
    button {
      color: $primary700;
    }
  }
  &.info {
    button {
      color: $info;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DTextButton {
    // width: 100%;
    button {
      // min-width: 100%;
      font-size: 14px;
      .buttonIcon {
        svg {
          font-size: 14px;
        }
      }
    }
  }
}
