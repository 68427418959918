@import "./../../../../../../styles/dashboard/variables.scss";

#AddTrailerExpensesModal {
  width: 100%;
  display: flex;
  //   justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  gap: 12px;
  .AddExpensesModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .groupItem {
      display: flex;
      gap: 12px;
      flex-direction: column;
      .downtimeContainer {
        display: flex;
        align-items: flex-end;
        gap: 12px;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddTrailerExpensesModal {
    .AddExpensesModalGroup {
      width: 100%;
    }
  }
}
