@import "../../../styles/dashboard/variables.scss";

#PaginationComponent {
  margin-top: 16px;
  user-select: none;
  .PaginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;
    align-items: center;
    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    .page {
      width: 35px;
      height: 35px;
      border-radius: 4px;
      border: 1px solid $white;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;
      margin-left: 2px;
      margin-right: 2px;
      color: $secondary100;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      user-select: none;
      transition: all 0.3s ease-in-out;
    }
    .page.active {
      border: 1px solid $primary500;
      background-color: $primary500;
      color: $white;
    }
    .page:hover {
      border: 1px solid $primary500;
    }
    .break {
      svg {
        font-size: 20px;
        color: $secondary100;
      }
    }
    .arrow {
      border-radius: 4px;
      border: 1px solid $secondary50;
      width: 35px;
      height: 35px;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;
      svg {
        font-size: 20px;
        color: $secondary100;
      }
    }
    .arrow.previous {
      margin-right: 16px;
    }
    .arrow.next {
      margin-left: 16px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #PaginationComponent {
    margin-top: 8px;
    .PaginationContainer {
      .page {
        width: 26px;
        height: 26px;
        font-size: 12px;
        line-height: 13px;
      }
      .break {
        svg {
          font-size: 13px;
        }
      }
      .arrow {
        width: 26px;
        height: 26px;
        svg {
          font-size: 16px;
        }
      }
      .arrow.previous {
        margin-right: 4px;
      }
      .arrow.next {
        margin-left: 4px;
      }
    }
  }
}
