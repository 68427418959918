$borderRadiusXLarge: 12px;
$borderRadiusLarge: 8px;
$borderRadius: 4px;
$borderRadiusSmall: 2px;

$pageWrapper: 24px;
$pageWrapperResponsive: 12px;
$fullWidthResponsive: calc(100vw - 36px);
$shipperFullWidthResponsive: calc(100vw - 56px);

$sidebarMax: 288px;
$sidebarMin: 84px;
$mobileHeaderHeight: 80px;
$shipperHeaderHeight: 80px;

$pageWidthMax: calc(100vw - 60px - $sidebarMax);
$pageWidthMin: calc(100vw - 60px - $sidebarMin);

$mediaOrigin: 1500px;
$mediaLarge: 1200px; // main responsive
$mediaMedium: 800px;
$mediaSmall: 650px;

// colors primary
$primary900: #004c2d;
$primary800: #00643b;
$primary700: #00814d;
$primary600: #00a562;
$primary500: #00b56c;
$primary400: #33c489;
$primary300: #54cd9d;
$primary200: #8addbb;
$primary100: #b0e8d1;
$primary50: #f0fcf7;

$secondary500: #1a2023;
$secondary400: #1e2529;
$secondary300: #2b353a;
$secondary200: #4f575b;
$secondary100: #848a8d;
$secondary75: #a8acae;
$secondary50: #eaebeb;
$secondary25: #f4f6f9;
$secondary0: #ffffff;

$success: #287d3c;
$warning: #b95000;
$error: #da1414;
$info: #2e5aac;
$violet: #5e2bff;
$successBG: #edf9f0;
$warningBG: #fff4ec;
$errorBG: #feefef;
$infoBG: #eef2fa;
$violetBG: #f1ecff;
$imageBG: #a8acae;
$white: #fff;
$ranke: #fdc921;
$violetGradient: linear-gradient(300deg, #5e2bff 18.14%, #5e2bff 69.4%);

$primaryBrand: $primary500;
$primaryHover: $primary400;
$primaryActive: $primary700;
$primaryContent: $secondary500;
$secondaryContent: $secondary300;

// @media only screen and (max-width: $mediaLarge) {
// }

// @media only screen and (max-width: $mediaMedium) {
// }

// @media only screen and (max-width: $mediaSmall) {
// }
