@import "../../../../styles/landing/variables.scss";

#SignUpShipperAuth {
  display: grid;
  grid-template-columns: 1fr 600px;
  min-height: $pageMinHeight;
}

@media only screen and (max-width: $mediaLarge) {
  #SignUpShipperAuth {
    grid-template-columns: 1fr;
  }
}
