@import "./../../../../styles/dashboard/variables.scss";
#AdrClassDetails {
  margin-top: 16px;
  .AdrClassDetailsHeader {
    display: flex;
    gap: 8px;
    align-content: center;
    margin-bottom: 4px;
    .icon {
      svg {
        color: $warning;
        font-size: 16px;
      }
    }
    h3 {
      color: $secondary200;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px; /* 153.846% */
    }
  }
  h4 {
    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    span {
      font-weight: 600;
      color: $secondary200;
    }
  }
}
