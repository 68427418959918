@import "../../../../styles/dashboard/variables.scss";

#AddVatModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .cover {
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
    width: 100%;
  }
  .titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .title {
      color: $secondary200;
      text-align: center;

      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .text {
      display: flex;
      padding: 8px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      background: $secondary25;
      color: $secondary200;
      text-align: center;

      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
