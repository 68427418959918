@import "./../../../styles/dashboard/variables.scss";

#SentUpdatesDetails {
  width: 100%;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  padding: 12px 24px;
  .titleContainer {
    width: 100%;
    border-bottom: 1px solid $secondary50;
    padding-bottom: 12px;
    gap: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .titleContainerLeft {
      display: flex;
      gap: 8px;
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 22px;
          color: $secondary200;
          transform: rotate(90deg);
        }
      }
      .title {
        color: $secondary500;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .NoSentUpdates {
    margin-top: 12px;
    margin-bottom: 12px;
    p {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .moreSentUpdates {
    display: none;
    &.show {
      display: block;
    }
  }
  #DButton {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      color: $info;
      background: transparent;
      font-size: 13px;
      min-height: auto;
      border: none;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #SentUpdatesDetails {
    gap: 12px;
    padding: 12px;
    .titleContainer {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .icon {
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
