@import "./../../../../../../../styles/dashboard/variables.scss";

#CreateOceanOrderContainerGroup {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  max-width: 1524px;

  .BlockA {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .RoadPackageTrailer {
      width: 100%;
    }

    .RoadPackageTemperature {
      width: 100%;
    }
  }

  .BlockB {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    .RoadPackageItemVolume {
      width: 100%;
      max-width: 250px;
    }
    .RoadPackageQuantity {
      width: 100%;
      max-width: 250px;
    }
    .RoadPackageItemWeight {
      width: 100%;
      max-width: 250px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CreateOceanOrderContainerGroup {
    display: grid;
    grid-template-columns: 1fr;

    .BlockB {
      .RoadPackageItemVolume,
      .RoadPackageQuantity,
      .RoadPackageItemWeight {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOceanOrderContainerGroup {
    .BlockA,
    .BlockB {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
