@import "../../../../styles/dashboard/variables.scss";

#profilePicture {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .profilePictureAvatar {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    border: 1px solid $secondary50;
    .editPictureAvatar {
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: $borderRadius;
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      top: 2px;
      right: 2px;
      cursor: pointer;
      .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
        }
      }
      input {
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
      }
    }
  }
  &:hover {
    opacity: 0.7;
    .editPictureAvatar {
      display: flex;
    }
  }
  .uploadError {
    position: absolute;
    bottom: -9px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #profilePicture {
    .profilePictureAvatar {
      .editPictureAvatar {
        display: flex;
      }
    }
  }
}
