@import "./../../styles/dashboard/variables.scss";

#ShipperHeader {
  position: fixed;
  width: 100%;
  height: $shipperHeaderHeight;
  background-color: $secondary300;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.12);
  align-items: center;
  display: flex;
  z-index: 9999;
  .ShipperHeaderContainer {
    width: 100%;
    padding: 0px $pageWrapper;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShipperHeader {
    .ShipperHeaderContainer {
      padding: 0px $pageWrapperResponsive;
    }
  }
}
