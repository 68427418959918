@import "./../../../../styles/dashboard/variables.scss";

#DQuantitySelectNumber {
  width: 100%;
  display: flex;
  flex-direction: column;

  .GroupForm {
    width: 100%;
    height: 40px;
    border: 1px solid $secondary100;
    border-radius: $borderRadius;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    background-color: $white;

    .button {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex-wrap: nowrap;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      svg {
        color: $secondary100;
        font-size: 18px;
      }
      &:hover {
        background-color: $secondary50;
      }

      &.disable {
        svg {
          color: $secondary50;
        }
        &:hover {
          background-color: $secondary25;
        }
      }
    }
    .number {
      p {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    .GroupForm {
      border: 1px solid $secondary50;
      .button {
        cursor: not-allowed;
        svg {
          color: $secondary50;
        }
        &:hover {
          background-color: $secondary25;
        }
      }
      .number {
        p {
          color: $secondary75;
        }
      }
    }
  }
}

#DQuantitySelectNumber.error {
  .GroupForm {
    border: 1px solid $error;
  }
}
