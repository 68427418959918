@import "./../../../../../styles/dashboard/variables.scss";

#InfoWindowTime {
  width: 100%;
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 4px;

  .route {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary200;
      }
    }
    h3 {
      color: $secondary300;
      font-size: 13px;
      font-weight: 400;
    }
  }
  .ago {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary200;
      }
    }
    h3 {
      font-size: 13px;
      font-weight: 400;
    }
  }
}
