@import "../../../styles/dashboard/variables.scss";

#FilterShippingType {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 12px;
  padding: 12px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px; /* Example width */
    height: 3px; /* Example height */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Example background color */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* Example thumb color */
    height: 3px; /* Example height */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Example hover color */
  }
}
