@import "./../../../../styles/dashboard/variables.scss";

#ShipperTrackingSectionDistance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    h3 {
      display: flex;
      align-items: center;
      gap: 4px;

      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    h4 {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      span {
        color: $info;
      }
    }
    h5 {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      span {
        color: $secondary200;
      }
    }
  }

  .progressContainer {
    overflow: hidden;
    height: 10px;
    width: 100%;
    border-radius: 8px;
    background: $secondary50;

    .color {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background-color: $primary700;
      position: relative;

      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $secondary50;
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
