@import "./../../../../styles/dashboard/variables.scss";

#D-Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 12px 0 0 0;
  // position: fixed;
  // bottom: 0;
}

@media only screen and (max-width: $mediaLarge) {
  #D-Footer {
    margin: 12px 0 48px 0;
  }
}
