@import "../../../styles/dashboard/variables.scss";

#ShipperOrdersDetailsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    .goShipment {
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $secondary100;
          font-size: 20px;
        }
      }
      h6 {
        color: $secondary200;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .line {
      height: 30px;
      width: 2px;
      background-color: $secondary50;
    }
    .tracking {
      display: flex;
      align-items: center;
      gap: 12px;
      h6 {
        color: $secondary100;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ShipperOrdersDetailsHeader {
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    .left {
      gap: 6px;
      .tracking {
        gap: 6px;
        h6 {
          font-size: 18px;
          line-height: 18px;
        }
      }
      .line {
        height: 24px;
      }
    }
    .right {
      width: 100%;
      justify-content: flex-end;
    }
  }
}
