@import "../../../../../../styles/dashboard/variables.scss";

#ShipperOrderList {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .warningNoticeContainer {
    max-width: 700px;
    display: flex;
    padding: 12px 16px;
    gap: 12px;
    align-items: center;

    border-radius: 8px;
    background: $warningBG;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: $warning;
        font-size: 24px;
      }
    }
    h2 {
      color: $warning;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShipperOrderList {
    padding: $pageWrapperResponsive;
  }
}
