@import "./../../../../styles/dashboard/variables.scss";

.ShipperLogoContainer {
  cursor: pointer;
  .fullLogo {
    display: block;
  }
  .circularLogo {
    display: none;
  }
}

@media only screen and (max-width: $mediaLarge) {
  .ShipperLogoContainer {
    .fullLogo {
      display: none;
    }
    .circularLogo {
      display: block;
    }
  }
}
