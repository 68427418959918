@import "./../../../../styles/dashboard/variables.scss";

#DIconButton {
  background-color: inherit;
  cursor: pointer;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primaryContent;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  svg {
    font-size: 18px;
  }

  &.danger {
    svg {
      color: $error;
    }
  }

  &:hover {
    background-color: $secondary50;
  }
}
