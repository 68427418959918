@import "./../../../styles/dashboard/variables.scss";

#DAttachemntModal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .DAttachemntModalgroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    #DFileFormModal {
      width: 100%;
      background-color: $white;
      .noDocument {
        text-align: center;
        border-radius: $borderRadius;
        position: relative;
        cursor: pointer;
        border: 1px dashed $secondary75;
        height: 40px;
        width: 100%;
        padding: 4px 12px;
        display: inline-flex;
        gap: 8px;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }

        .noDocumentcontent {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h3 {
            color: $secondary300;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            white-space: nowrap;
          }
          h4 {
            color: $secondary100;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            white-space: nowrap;
          }
        }
        input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
      }

      &.error {
        .noDocument {
          border: 1px dashed $error;
        }
      }
    }
    #DFileFormItemModal {
      width: 100%;
      background-color: $white;

      .Document {
        border-radius: $borderRadius;
        border: 1px solid $secondary75;
        padding: 4px 8px;
        height: 40px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .title {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          h6 {
            color: $secondary200;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;

            white-space: nowrap;
          }
        }

        .fileIcon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: $secondary100;
            font-size: 18px;
          }
        }
        .removeIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          cursor: pointer;
          svg {
            color: $error;
            font-size: 16px;
          }
        }
      }
      &.error {
        .Document {
          border: 1px dashed $error;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #DAttachemntModal {
    .DAttachemntModalgroup {
      width: 100%;
      gap: 6px;
    }
  }
}
