@import "./../../../../styles/dashboard/variables.scss";
#ShippingTypeIconFIeld {
  vertical-align: middle;
  padding: 12px 10px;
  .ShippingTypeIconFIeldContainer {
    padding: 4px 12px;
    border-radius: 24px;
    background-color: $secondary25;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $secondary100;
    }
  }
}
