@import "./../../../../../styles/landing/variables.scss";

#DigitalContent {
  display: flex;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .desc {
      display: flex;
      margin-bottom: 4px;
      h6 {
        background-color: $secondarySurface;
        border-radius: 36px;
        color: $primaryBrand;
        font-size: 12px;
        padding: 4px 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.12px;
      }
    }
    #HomeTitle.HomeTitle {
      justify-content: flex-start;
      margin-bottom: 16px;
      .title {
        align-items: flex-start;
        width: 100%;
        h1 {
          text-align: start;
        }
        p {
          font-weight: 400;
          text-align: start;
        }
      }
    }
  }
  #LinkTransparent {
    justify-content: flex-start;
  }
}
