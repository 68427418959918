@import "../../../styles/dashboard/variables.scss";

#ShipmentUpdates {
  width: 100%;
  padding: 12px 24px;
  min-height: 194px;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  .ShipmentUpdatesHeader {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    padding-bottom: 12px;
    gap: 8px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
    }
    .titleContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      h1 {
        color: $secondary500;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
      #DButton button {
        background-color: transparent;
        border: 2px solid $secondary500;
        color: $secondary500;
        // font-size: 14px;
        font-weight: 600;
        padding: 0px 10px;
        border-radius: 6px;
        min-height: 36px;
        min-width: auto;
        svg {
          transform: scaleX(-1);
        }
      }
    }
  }
  .container {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.22);
    .content {
      width: 100%;
      display: flex;
      align-items: flex-end;
      // justify-content: space-between;
      gap: 12px;
      .formContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .selectContainer {
          width: 100%;
        }
        .inputContainer {
          width: 100%;
          display: flex;
          gap: 8px;
        }
        .commentContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          .commentButton {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            white-space: nowrap;
            user-select: none;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                font-size: 20px;
                color: $primary500;
              }
            }
            .text {
              color: $secondary500;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
          .commentInput {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            #DTextarea {
              width: 100%;
            }
          }
        }
      }
      .buttonContainer {
        display: flex;
        padding: 8px;
        align-items: flex-end;
        gap: 8px;
        border-radius: 6px;
        background: $info;
        cursor: pointer;
        svg {
          font-size: 20px;
          color: $secondary0;
        }
        &.disabled {
          cursor: not-allowed;
          background: $infoBG;
        }
      }
      .noProperStatusMessage {
        width: 100%;
        text-align: center;

        color: $secondary100;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .updatesHeader {
    margin-top: 12px;
    margin-bottom: 4px;

    color: $secondary200;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .updatesContainer {
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid $secondary50;
    background: #fff;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      .topContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .titleDetailsContainer {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          .title {
            color: $secondary300;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
          }
          .status {
            display: flex;
            padding: 4px 12px;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            border-radius: 34px;
            background: var(--Status--token-Info-BG, #eef2fa);
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 24px;
            color: $secondary300;
          }
        }
      }
      .bottomContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        .leftContainer {
          display: flex;
          align-items: center;
          gap: 16px;
          .itemsContainer {
            display: flex;
            align-items: center;
            gap: 4px;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                font-size: 16px;
                color: $secondary300;
              }
            }
            .text {
              color: $secondary300;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
        .date {
          color: $secondary100;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .noUpdates {
      text-align: center;

      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipmentUpdates {
    gap: 12px;
    padding: 12px;
    min-height: 160px;
    min-width: auto;
    .content {
      .inputContainer {
        flex-direction: column;
      }
    }
  }
}
