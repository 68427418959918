@import "./../../../../styles/dashboard/variables.scss";

#ActiveShipmentSideFilter {
  // overflow: hidden;
  height: auto;
  background-color: $white;
  border-radius: 12px;
  width: 564px;

  .ActiveShipmentSideFilterHeader {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid $secondary300;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $secondary300;
      }
      .titleIcon {
        display: flex;
        svg {
          font-size: 16px;
          color: $secondary300;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .ActiveShipmentSideFilterContent {
    width: 100%;
    // min-height: 390px;
    padding: 16px 24px;
    background-color: $white;
    border-bottom: 1px solid $secondary50;

    .DSearchGroup {
      margin-bottom: 16px;
    }
    .selectGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 4px;
    }
    .FilterShippingTypeGroup {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 4px;
      &::-webkit-scrollbar {
        width: 3px; /* Example width */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Example background color */
      }

      &::-webkit-scrollbar-thumb {
        background: #888; /* Example thumb color */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Example hover color */
      }
    }
    .FilterStatusesClipsGroup {
      width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 3px; /* Example width */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Example background color */
      }

      &::-webkit-scrollbar-thumb {
        background: #888; /* Example thumb color */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Example hover color */
      }
    }
  }
  .ActiveShipmentSideFilterFooter {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;

    .cancelTbn {
      cursor: pointer;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      color: $secondary200;
    }
    .clearBtn {
      cursor: pointer;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 600;
      // line-height: 20px;
      letter-spacing: 0em;
      color: $info;
      border-radius: 20px;
      border: 1px solid $info;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ActiveShipmentSideFilter {
    width: 90vw;
    .ActiveShipmentSideFilterHeader {
      padding: 12px 16px;
      gap: 4px;
      .title {
        gap: 4px;
      }
    }
    .ActiveShipmentSideFilterContent {
      padding: 12px 16px;
      .DSearchGroup {
        margin-bottom: 12px;
      }
      .selectGroup {
        grid-template-columns: 1fr;
        gap: 12px;
      }
      .FilterShippingTypeGroup {
        margin-bottom: 0px;
      }
    }
    .ActiveShipmentSideFilterFooter {
      padding: 8px 16px;
    }
  }
}
