@import "../../../../styles/dashboard/variables.scss";

#profileSettingsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .infoTitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .infoText {
      color: $secondary100;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
