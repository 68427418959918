@import "./../../../../../../styles/dashboard/variables.scss";

#SendBidTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 12px;
  .SendBidTableHeader {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .SendBidTableHeaderLeft {
      width: 100%;
      display: flex;
      align-items: center;
      overflow-x: auto;
      gap: 12px;

      &::-webkit-scrollbar {
        width: 3px; /* Example width */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Example background color */
      }

      &::-webkit-scrollbar-thumb {
        background: #888; /* Example thumb color */
        height: 3px; /* Example height */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Example hover color */
      }

      .searchInput {
        min-width: 210px;
      }
      .filterContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        .filterTitle {
          color: $secondary100;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
        }
        &::-webkit-scrollbar {
          width: 3px; /* Example width */
          height: 3px; /* Example height */
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* Example background color */
        }

        &::-webkit-scrollbar-thumb {
          background: #888; /* Example thumb color */
          height: 3px; /* Example height */
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555; /* Example hover color */
        }
      }
    }
  }
  .ActionButtons {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    .all {
      #DButton {
        button {
          white-space: nowrap;
          background: $secondary500;
          border-color: $secondary500;
        }
      }
    }
    .createAddPartner {
      #DButton button {
        border: 1px solid $secondary500;
        background-color: $white;
        color: $secondary500;
      }
    }
  }
  .tableWrapper {
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 6px;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SendBidTable {
    .SendBidTableHeader {
      .SendBidTableHeaderLeft {
        .filterContainer {
          .filterTitle {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #SendBidTable {
    .SendBidTableHeader {
      flex-direction: column-reverse;
      width: 100%;
      .SendBidTableHeaderLeft {
        flex-direction: column;
        overflow-x: hidden;
        .searchInput {
          width: 100%;
        }
        .filterContainer {
          overflow-x: auto;
          width: 100%;
        }
      }
    }
    .ActionButtons {
      width: 100%;
      flex-direction: column;
      .all,
      .createAddPartner,
      .single {
        width: 100%;
      }
    }
  }
}
