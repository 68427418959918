@import "./../../../../../../styles/dashboard/variables.scss";

#ExtraCustomerChargesItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: $secondary100;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
  }
  .action {
    display: flex;
    align-items: center;
    gap: 4px;

    h5 {
      color: $secondary100;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      white-space: nowrap;
    }

    .Moreicon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      svg {
        font-size: 18px;
        color: $secondary100;
      }
    }
  }
}
