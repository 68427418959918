@import "./../../../../../styles/landing/variables.scss";

#LinkTransparent {
  width: 100%;
  display: flex;
  justify-content: center;
  user-select: none;
  a {
    display: flex;
    align-items: center;
    padding: 9px 24px;
    color: $primaryBrand;
    background-color: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border: 1px solid $primaryBrand;
    border-radius: $borderRadius;
    transition: all 0.3s ease-in-out;
    text-align: center;
    svg {
      font-size: 16px;
      margin-left: 8px;
    }
    &:hover {
      background-color: $secondarySurface;
    }
  }
}
