@import "./../../../../../styles/dashboard/variables.scss";

#AddNewDocumentModal {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 220px;
  .formGroup {
    display: flex;
    flex-direction: column;
    width: 350px;
    gap: 12px;
    .right {
      display: flex;
      align-items: center;
      gap: 8px;
      .action {
        display: flex;
        gap: 12px;
        user-select: none;
        .icon {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            font-size: 18px;
            color: $secondary500;
          }

          &.delete {
            svg {
              color: $error;
            }
          }
        }
      }
    }
    .isGroupCheckbox {
      #DCheckBox .checkbox {
        align-items: center;
        gap: 12px;
        p {
          color: $secondary100;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddNewDocumentModal {
    .formGroup {
      width: 100%;
    }
  }
}
