@import "./../../../../../styles/dashboard/variables.scss";

#FinancialDetailsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .FinancialDetailsHeaderLeft {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
      &.done {
        cursor: pointer;
        svg {
          color: $primaryBrand;
        }
      }
    }
    .title {
      color: $secondary500;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
  }
  #DButton button {
    background: $secondary300;
    border-color: $secondary300;
    color: $secondary0;
    min-height: 35px;
  }
}
.FinancialDetailsHeaderTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  .StatusFieldContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      p {
        color: $secondary100;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #FinancialDetailsHeader {
    flex-direction: column;
    align-items: flex-start;
    .FinancialDetailsTitleContainerLeft {
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .title {
        font-size: 20px;
      }
    }
  }
}
