@import "./../../../../styles/dashboard/variables.scss";

#DGPSConnectionsSelectField {
  padding: 8px 10px;
  vertical-align: middle;
  min-width: 200px;
}

#DGPSConnectionsDevicesField {
  padding: 8px 10px;
  vertical-align: middle;
  min-width: 200px;
}

#DGPSConnectionsActionsFieldSave {
  #DButton {
    button {
      min-height: 30px;
      font-size: 13px;
      min-width: auto;
    }
  }
}

#DGPSConnectionsActionsFieldEdit {
  #DButton {
    button {
      min-height: 30px;
      font-size: 13px;
      min-width: auto;
      border: 1px solid $secondary500;
      color: $secondary500;
      background-color: white;
    }
  }
}
