@import "../../../styles/dashboard/variables.scss";

#SortSelect {
  min-width: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .selectComponent {
    .select__control {
      border-radius: $borderRadiusLarge;
      border: 1px solid $secondary100;
      background-color: $white;
      padding: 4px 0 4px 12px;
      transition: all 0.3s ease-in-out;
      height: 28px;
      cursor: pointer;
      .select__value-container {
        padding: 0;
        .select__single-value {
          margin: 0;
          overflow: hidden;
          line-height: 20px;
        }
      }
    }
    .select__control--is-focused {
      border-color: $secondary100;
      box-shadow: none;
    }
    .select__placeholder {
      font-size: 16px;
      font-style: normal;
      font-family: inherit;
      font-weight: 500;
      color: $secondary100;
      margin: 0;
    }
    .select__indicators {
      user-select: none;
      .select__indicator-separator {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .select__indicator {
        padding: 0;
        margin: 0;
        margin-right: 8px;
        margin-left: 8px;
        user-select: none;
        cursor: pointer;

        svg {
          color: $secondary300;
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }
    }
    .select__input-container {
      margin: 0;
      padding: 0;
    }
    .css-1dimb5e-singleValue {
      color: black;
      font-size: 16px;
    }
    .select__menu {
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 0;
      margin-top: 5px;
      box-shadow: 0px 10px 35px 0px #0000001f;
      background-color: $secondary0;

      .select__menu-list {
        max-height: 145px;
        padding: 0;
        .selectLabel {
          width: 100%;
          background-color: red;
          height: 30px;
        }
        .select__option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          color: black;
          font-size: 16px;
          padding: 12px 16px;
          border-bottom: 1px solid $secondary25;
          &:hover {
            transition: all 0.2s ease-in-out;
            background-color: $secondary25;
          }
        }

        .select__option--is-focused {
          transition: all 0.2s ease-in-out;
          background-color: $secondary25;
        }
        .select__option--is-selected {
          transition: all 0.2s ease-in-out;
          background-color: $secondary0;
        }
      }
    }
  }
  .selectComponent.separator {
    .select__indicators {
      .select__indicator-separator {
        display: block;
      }
    }
  }

  .bold {
    font-weight: 600;
  }

  // .buttton {
  //   width: 100%;
  //   height: 40px;
  //   background-color: orange;
  // }

  &.error {
    .selectComponent {
      .select__control {
        border: 1px solid $error;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
}
