@import "../../../../../styles/landing/variables.scss";

#PrivacyPolicy {
  background-color: $primarySurfance;
  min-height: $pageMinHeight;
  width: 100%;
  overflow: hidden;
  .content {
    margin-top: 48px;
    margin-bottom: 48px;
    .title {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      h1 {
        color: $primaryContent;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        text-align: center;
      }
    }
    .body {
      p {
        color: $secondaryContent;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.14px;
        margin-bottom: 12px;
      }
      strong {
        color: $primaryContent;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 24px;
        display: inline-block;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #PrivacyPolicy {
    .content {
      margin-top: 24px;
      margin-bottom: 24px;
      .title {
        margin-bottom: 12px;
        h1 {
          font-size: 24px;
        }
      }
      .body {
        strong {
          font-size: 14px;
        }
      }
    }
  }
}
