@import "./../../../../../styles/dashboard/variables.scss";

#ProductNewOrder {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background-color: $white;
  .selectsection {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    max-width: 1500px;

    .groupItem {
      width: 100%;
      max-width: 350px;

      .actionButton {
        display: flex;
        justify-content: flex-end;

        #DTextButton {
          button {
            min-height: 30px;
          }
        }
      }
    }
  }
  .checkboxsection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .adrWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .adrContainer {
        display: flex;
        flex-direction: row;
        gap: 24px;
        max-width: 400px;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #ProductNewOrder {
    padding: 12px;
    gap: 12px;
    .selectsection {
      gap: 12px;
    }
    .checkboxsection {
      .adrWrapper {
        .adrContainer {
          gap: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ProductNewOrder {
    .selectsection {
      flex-direction: column;
    }
    .checkboxsection {
      .adrWrapper {
        flex-direction: column;
        .adrContainer {
          max-width: 100%;
        }
      }
    }
  }
}
