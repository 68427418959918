@import "./../../../../styles/dashboard/variables.scss";

#StatisticCard {
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid $secondary50;
  position: relative;
  background-color: $white;
  overflow: hidden;
  width: max-content;
  // min-width: 250px;
  max-height: 200px;
  svg {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 18px;
    color: $primary700;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 44px;
      color: $secondary500;
      white-space: nowrap;
    }
    h3 {
      text-align: center;
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      white-space: nowrap;
      line-height: 24px;
    }
  }
  h4 {
    text-align: center;
    color: $secondary300;
    font-size: 11px;
    font-style: normal;
    white-space: nowrap;
    font-weight: 600;

    span {
      color: $primary700;
      font-size: 14px;
      letter-spacing: 0.7px;
    }
  }

  p {
    line-height: 20px;
    text-align: center;
    color: $primary700;
    font-size: 13px;
    font-style: normal;
    // white-space: nowrap;
    font-weight: 400;
    span {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.7px;
    }
  }
  #noStatistic {
    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &.active {
    border: 1px solid $primary700;
    background-color: $primary50;
  }
}

.bold {
  font-weight: 700;
}

.green {
  color: $primary700;
}

@media only screen and (max-width: $mediaSmall) {
  #StatisticCard {
    width: 100%;
    min-width: 100%;
  }
}
