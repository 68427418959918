$borderRadius: 4px;
$borderRadiusSmall: 2px;
$borderRadiusLarge: 8px;

$maxWidth: 1440px;

// colors
$primaryBrand: #00b56c;
$primaryContent: #2b353a;
$primaryBorder: #00b56c;
$primaryHover: #33c489;
$primarySurfance: #ffffff;
$secondaryContent: #4f575b;
$secondaryBorder: #bfbfcd;
$secondaryHover: #e9ebf8;
$secondarySurface: #f0fcf7;
$tetriaryContent: #e1e1e1;
$tetriarySurface: #8e98a8;
$disable: #e1e1e1;
$redNormal: #ff0000;
$redDark: #bf0000;
$green700: #00814d;
$green600: #00a562;
$green100: #b0e8d1;
$green50: #f0fcf7;
$yellowDark: #ad7a2d;
$yellowLight: #fdf6ec;
$grayNormal: #f2f2f2;
$gray300: #2b353a;
$gray200: #4f575b;
$gray100: #e1e1e1;
$gray75: #a8acae;
$gray50: #eaebeb;
$imageBG: #a8acae;

$headerHeight: 80px;
$pageMinHeight: calc(100vh - $headerHeight);
$coverHeight: calc(100vh - 220px);

// media query
$mediaOrigin: 1440px;
$mediaLarge: 1080px;
$mediaMedium: 800px;
$mediaSmall: 550px;

// background-color: $gray200;
// background-repeat: no-repeat;
// background-size: cover;
// background-position: center;

// @media only screen and (max-width: $mediaLarge) {
// }

// @media only screen and (max-width: $mediaMedium) {
// }

// @media only screen and (max-width: $mediaSmall) {
// }
