@import "./../../../../../../../styles/dashboard/variables.scss";

.TrailerManagementItem {
  width: 100%;
  border: 1px solid $secondary50;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 8px;
  align-items: flex-start;
  border-radius: 8px;
  // height: 100%;
  max-width: 1500px;

  &.edit {
    border: none;
  }
  .id {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
      width: 36px;
      color: $primary700;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }
  }
  .trailerType,
  .trailerVolume,
  .weight,
  .priceForCustomerInput,
  .temperature {
    width: 100%;
  }
  .action {
    margin-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.single {
      margin-top: 27px;
    }
    #DTextButton {
      button {
        min-height: auto;
        color: #0097d8;
      }
    }
    #DTextButton.danger {
      button {
        color: $error;
      }
    }
  }
  .priceForCustomerInput {
    #DCurrencyInput {
      #DLabel {
        padding-left: 17px;
      }
      .container {
        border-left: 1px solid $secondary75;
        padding-left: 12px;
        #AddOrderTotalpricefororder,
        .select__control {
          background-color: $secondary25;
        }
      }
    }
  }
}

#dublicateCountquantityContainer {
  display: flex;
  justify-content: center;
  .dublicateCountquantityContainerForm {
    width: 350px;
  }
}

@media only screen and (max-width: $mediaOrigin) {
  .TrailerManagementItem {
    padding: 50px 12px 12px 12px;
    &.edit {
      padding: 0px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;

    position: relative;
    .id {
      margin-top: 0px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }
    .action {
      &.single {
        margin-top: 10px;
      }
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      height: auto;
      flex-direction: row;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #dublicateCountquantityContainer {
    .dublicateCountquantityContainerForm {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  .TrailerManagementItem {
    grid-template-columns: 1fr;
    .priceForCustomerInput {
      #DCurrencyInput {
        #DLabel {
          padding-left: 5px;
        }
        .container {
          border: 0;
          padding-left: 0;
        }
      }
    }
  }
}
