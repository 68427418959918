@import "./../../../../../../styles/dashboard/variables.scss";

#AccountantEmailCreateButton {
  #DButton {
    button {
      background-color: $info;
      border: 1px solid $info;
      border-radius: $borderRadiusLarge;
    }
  }
}
