@import "./../../../../styles/landing/variables.scss";

#PhoneInput {
  .phoneInputContainer {
    display: flex;
    flex-direction: row;

    #SelectForm {
      .selectComponent {
        width: 110px;
        .select__control {
          border-right-width: 0px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .select__indicators {
          .select__indicator {
            margin-right: 4px;
            margin-left: 4px;
          }
        }
      }
    }

    #input {
      .group {
        input {
          border-radius: $borderRadius;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
