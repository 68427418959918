@import "../../../styles/dashboard/variables.scss";

#PlanningAssistantTableHeader {
  height: 32px;
  display: flex;
  align-items: center;
  .title {
    min-width: 200px;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    color: $secondary300;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .days {
    display: flex;
    .day {
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
