@import "../../../../styles/landing/variables.scss";

#CarrierSubscribe {
  .bottomContent {
    position: relative;
    max-width: 1440px;
    min-height: 500px;
    padding-right: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    .CarrierSubscribeForm {
      position: absolute;
      right: 0;
      top: -360px;
      z-index: 1;
    }
  }
  .subscribeModalButton {
    display: none;
  }
}

#WebPlatformCarrierSubscribeTitle {
  margin-top: 48px;
  background-color: $secondaryContent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .titleContainer {
    display: flex;
    gap: 12px;
    .text {
      color: $primarySurfance;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 32px;
      // height: 32px;
      svg {
        color: $primarySurfance;
        font-size: 32px;
      }
    }
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #CarrierSubscribe {
    .bottomContent {
      max-width: 1200px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  #CarrierSubscribe {
    .bottomContent {
      min-height: auto;
      padding-right: 0;
      .CarrierSubscribeForm {
        display: none;
      }
    }
    .subscribeModalButton {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 16px;
      #Button {
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CarrierSubscribe {
    .subscribeModalButton {
      #Button {
        width: 100%;
      }
    }
  }
}
