@import "./variables.scss";

@font-face {
  font-family: Arial;
  src: url("./../../../public/assets/fonts/Arial/bpgArial.eot");
  src: url("./../../../public/assets/fonts/Arial/BPGArial.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("./../../../public/assets/fonts/Montserrat/Montserrat.woff");
  src: url("./../../../public/assets/fonts/Montserrat/Montserrat.woff2");
  src: url("./../../../public/assets/fonts/Montserrat/Montserrat.ttf");
}

#L-Layout,
#TalkExpert,
textarea,
input {
  letter-spacing: 0.5px;
  &:lang(en) {
    font-family: "Montserrat";
    text-shadow: #000000 0 0 0px;
    -webkit-font-smoothing: antialiased;
  }
  &:lang(ka) {
    font-family: "Arial";
    text-shadow: #000000 0 0 0px;
    -webkit-font-smoothing: antialiased;
  }
  &:lang(ru) {
    font-family: "Montserrat";
    text-shadow: #000000 0 0 0px;
    -webkit-font-smoothing: antialiased;
  }
  &:lang(hy) {
    font-family: "Montserrat";
    text-shadow: #000000 0 0 0px;
    -webkit-font-smoothing: antialiased;
  }
}

#L-Layout {
  p {
    line-height: 24px;
  }
}

body {
  line-height: 1;
  background-color: white;
}

#Landing {
  display: flex;
  flex-direction: column;
}

.wrapper {
  max-width: $mediaOrigin;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media only screen and (max-width: $mediaLarge) {
  .wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  .wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: $primarySurfance;
}

::-webkit-scrollbar-thumb {
  background: $primaryBrand;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primaryBrand;
}
