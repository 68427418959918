@import "../../../../styles/landing/variables.scss";

#RegisterButton {
  a {
    width: 100%;
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primaryBrand;
    color: $primarySurfance;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: $borderRadius;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }

  a.active {
    background-color: $green700;
  }
  a:hover {
    background-color: $green600;
  }
}
