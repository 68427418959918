@import "../../styles/dashboard/variables.scss";

#BidsPageNotFound {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 52px 84px;
  gap: 48px;
  .bidsPageNotFoundLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    .bidsPageNotFoundLeftIcon {
      display: flex;
      justify-content: flex-start;
      svg {
        min-width: 308px;
        height: 90px;
      }
    }
    .bidsPageNotFoundLeftContent {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .bidsPageNotFoundLeftTitle {
        display: flex;
        align-items: center;
        gap: 8px;
        .bidsPageNotFoundLeftTitleBold {
          font-size: 48px;
          font-weight: 600;
          line-height: 52px;
          letter-spacing: 0em;
          color: $secondary500;
        }
        .bidsPageNotFoundLeftTitleLight {
          font-size: 40px;
          font-weight: 300;
          line-height: 44px;
          letter-spacing: 0em;
          color: $secondary100;
        }
      }
      .bidsPageNotFoundLeftText {
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        color: $secondary100;
      }
    }
  }
  .bidsPageNotFoundRight {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #BidsPageNotFound {
    flex-direction: column-reverse;
    gap: 0;
    padding: 0;
    .bidsPageNotFoundLeft {
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 24px;
      .bidsPageNotFoundLeftContent {
        align-items: center;
        .bidsPageNotFoundLeftTitle {
          justify-content: center;
        }
        .bidsPageNotFoundLeftText {
          text-align: center;
        }
      }
    }
    .bidsPageNotFoundRight {
      width: 100%;
      svg {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #BidsPageNotFound {
    .bidsPageNotFoundLeft {
      .bidsPageNotFoundLeftContent {
        .bidsPageNotFoundLeftTitle {
          flex-direction: column;
          gap: 0;
          .bidsPageNotFoundLeftTitleBold {
            font-size: 38px;
            line-height: 40px;
          }
          .bidsPageNotFoundLeftTitleLight {
            font-size: 30px;
            line-height: 32px;
          }
        }
        .bidsPageNotFoundLeftText {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}
