@import "../../../../styles/landing/variables.scss";

#Products {
  overflow: hidden;
  .list {
    margin-top: 14px;
    height: auto;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #Products {
    .list {
      margin-top: 0px;
      height: 0;
    }
  }
  #Products.open {
    .list {
      height: auto;
    }
  }
}
