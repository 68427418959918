@import "../../styles/dashboard/variables.scss";

#BidsMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 24px 16px;
  .info {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    p {
      span {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
  }
}
