@import "./../../../../styles/dashboard/variables.scss";

#FilledAssignCarrier {
  width: 100%;

  .warningContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 12px;
    h1 {
      color: $warning;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
    }
    .icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $warning;
        font-size: 20px;
      }
    }
  }
  .FilledAssignCarrierContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 18px 32px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadiusLarge;
    border: 1px solid $warning;
    background: $secondary0;
    box-shadow: 0px 2px 6px 0px rgba(168, 172, 174, 0.2);
    .detachIcon {
      position: absolute;
      right: 12px;
      top: 6px;
      cursor: pointer;
      svg {
        font-size: 24px;
        color: $secondary100;
      }
    }
    .FilledAssignCarrierContent {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      h1 {
        color: $secondary300;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
      .FilledAssignCarrierPhone {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        //   align-items: center;
        gap: 4px;
        .icon {
          width: 20px;
          height: 20px;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }
      }
      .FillInformation {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        .date {
          display: flex;
          align-items: center;
          gap: 4px;

          h4 {
            color: $secondary100;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            span {
              color: $secondary500;
            }
          }

          .icon {
            svg {
              color: $warning;
              font-size: 16px;
            }
          }
        }
        #DButton button {
          white-space: nowrap;
          border: 1px solid $warning;
          background-color: $warning;
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #FilledAssignCarrier {
    .FilledAssignCarrierContainer {
      padding: 24px 12px 12px 12px;
      .FilledAssignCarrierContent {
        .detachIcon {
          svg {
            font-size: 20px;
          }
        }
        gap: 8px;
        .FillInformation {
          .date {
            .icon {
              display: none;
            }
          }
        }
      }
    }
  }
}
