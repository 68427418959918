@import "../../../styles/dashboard/variables.scss";

#AddressSearchInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  .group {
    position: relative;
    width: 100%;
    input {
      background-color: $white;
      width: 100%;
      height: 40px;
      padding: 8px 16px 8px 36px;
      border: 1px solid $secondary75;
      border-radius: $borderRadius;
      outline: none;
      transition: all 0.3s ease-in-out;
      font-size: 16px;
      font-style: normal;
      font-family: inherit;
      font-weight: 400;
      user-select: none;
      color: $secondary300;

      &::placeholder {
        color: $secondary100;
        font-weight: 400;
      }

      &:focus {
        &::placeholder {
          color: $secondary50;
        }
      }
    }

    .AddressSearchInputIcon {
      position: absolute;
      top: 10px;
      left: 14px;
      color: $secondary100;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .AddressSearchInputRightIcon {
      position: absolute;
      top: 10px;
      right: 14px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        font-size: 16px;
        fill: $secondary100;
      }
    }
  }
}

#AddressSearchInput.error {
  input {
    border: 1px solid $error;
  }
  .AddressSearchInput.icon {
    color: $error;
  }
}
