@import "../../styles/dashboard/variables.scss";

#ShipperTitleSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  .managerContainer {
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 12px;
    border: 1px solid transparent;

    border-radius: 8px;
    background: $secondary0;
    .initials {
      width: 48px;
      height: 48px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      p {
        color: $secondary0;
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .managerInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      h1 {
        color: $secondary300;
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      h2 {
        color: $secondary200;
        text-align: center;

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .topContainer {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .bottomContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        .managerContact {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 16px;
              color: $secondary100;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mediaSmall) {
  #ShipperTitleSection {
    flex-direction: column;
    align-items: flex-start;
    .managerContainer {
      width: 100%;
      flex-direction: column;
      align-items: center;
      .managerInfo {
        align-items: center;
      }
    }
  }
}
