@import "./../../../../styles/dashboard/variables.scss";

#PriceField {
  vertical-align: middle;
  padding: 12px 10px;
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    svg {
      font-size: 16px;
    }
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
  }
}
