@import "../../../../styles/dashboard/variables.scss";

#NameField {
  vertical-align: middle;
  a {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 10px;
    white-space: nowrap;
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &.link {
    cursor: pointer;
    &:hover {
      background-color: $secondary50;
    }
  }
  .badge {
    display: flex;
    padding: 2px 8px;
    align-items: center;

    border-radius: 4px;
    background: $infoBG;

    color: $info;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
}

#NameFieldOnClick {
  vertical-align: middle;
  cursor: pointer;
  p {
    display: flex;
    padding: 12px 10px;
    white-space: nowrap;
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &:hover {
    &:hover {
      background-color: $secondary50;
    }
  }
}
