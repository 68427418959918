@import "./../../../../../styles/dashboard/variables.scss";

#TruckLocationBadge {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  opacity: 1;
  position: absolute;
  right: 2px;
  top: -2px;
  z-index: 0;
  .SmallStatusBadge {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: blink 500ms infinite alternate;
    // box-shadow: 2px 2px 50px 5px #00b56c;
  }
}
@keyframes blink {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.7);
  }
}
