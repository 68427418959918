@import "./../../../../../styles/landing/variables.scss";

#LinkUnderline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    user-select: none;
    color: $primaryBrand;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    transition: all 0.3s ease-in-out;
    position: relative;
    text-align: center;
    .line {
      width: 0;
      height: 0.5px;
      background-color: $primaryHover;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
    }
    &:hover .line {
      width: 100%;
      transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
    }
  }
}
