@import "./../../../../styles/landing/variables.scss";

#JoinUsCarrier.JoinUsCarrier {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .backgroundImage {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left center;
  }
  .backgroundImage.two {
    background-position: right center;
  }
  .backgroundColor {
    width: 100%;
    height: 100%;
    background-color: $primaryContent;
    .container {
      padding: 48px 0;
    }
    .title {
      margin-bottom: 16px;
      h1 {
        color: $primarySurfance;
      }
      p {
        color: $secondarySurface;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #JoinUsCarrier.JoinUsCarrier {
    .backgroundImage {
      background-image: none !important;
      background: none;
    }
  }
}
