@import "./../../../../../../styles/dashboard/variables.scss";

#RoadPackageItem {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  max-width: 1524px;

  .BlockA {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .RoadPackageTrailer {
      width: 100%;
    }

    .RoadPackageTemperature {
      width: 100%;
    }
  }

  .BlockB {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    .RoadPackageItemVolume {
      width: 100%;
      max-width: 250px;
    }

    .RoadPackageQuantity {
      width: 100%;

      max-width: 250px;

      display: flex;
      align-items: center;
      gap: 12px;

      .removeBtn {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          font-size: 24px;
          color: $error;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #RoadPackageItem {
    display: grid;
    grid-template-columns: 1fr;

    .BlockB {
      .RoadPackageItemVolume,
      .RoadPackageQuantity {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #RoadPackageItem {
    .BlockA,
    .BlockB {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
