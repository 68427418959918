@import "./../../../../styles/dashboard/variables.scss";

#C-Layout {
  #TableWrapperComponent {
    // min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #TableWrapperContainer {
    overflow-x: auto;
    transition: all 0.3s ease-in-out;
    &::-webkit-scrollbar {
      height: 6px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }
  }
  &.max {
    #TableWrapperContainer {
      width: $pageWidthMax;
    }
  }

  &.min {
    #TableWrapperContainer {
      width: $pageWidthMin;
    }
  }
}

#S-Layout {
  #TableWrapperComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #TableWrapperContainer {
    width: 100%;
    overflow-x: auto;
    transition: all 0.3s ease-in-out;
    &::-webkit-scrollbar {
      height: 6px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }
  }
  &.max {
    #TableWrapperContainer {
      width: 100%;
    }
  }

  &.min {
    #TableWrapperContainer {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #C-Layout {
    &.max,
    &.min {
      #TableWrapperContainer {
        width: $fullWidthResponsive;
      }
    }
  }
}
