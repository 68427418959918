@import "./../../../../../styles/dashboard/variables.scss";

#OceanTypeSwitcher {
  user-select: none;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  .typeSwitcherItemLeft,
  .typeSwitcherItemRight {
    cursor: pointer;
    border-top: 1px solid $secondary75;
    border-bottom: 1px solid $secondary75;
    background: $secondary0;
    padding: 8px 48px;
    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    &.active {
      background: $infoBG;
      color: $info;
      border: 1px solid $info;
    }
  }
  .typeSwitcherItemLeft {
    border-radius: 8px 0px 0px 8px;
    border-left: 1px solid $secondary75;
  }
  .typeSwitcherItemRight {
    border-radius: 0px 8px 8px 0px;
    border-right: 1px solid $secondary75;
  }
}
