@import "./../../../../../styles/landing/variables.scss";

.FAQCover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $imageBG;
  height: 430px;
  width: 100%;
  position: relative;
  border-radius: 14px;
  .corner {
    width: 170px;
    height: 170px;
    position: absolute;
    bottom: -25px;
    left: -25px;
    z-index: 2;
    border-radius: 100%;
    background-color: $primarySurfance;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      background-color: $primaryBrand;
      width: 95px;
      height: 95px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  .FAQCover {
    height: 300px;
    grid-row: 1;

    .corner {
      display: none;
    }
  }
}
