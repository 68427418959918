@import "../../../styles/dashboard/variables.scss";

#PlanningAssistantTableOrder {
  position: absolute;
  height: "36px";
  border-radius: 8px;
  border: 2px solid #287d3c;
  background: #edf9f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .leftSide {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    .orderIcon {
      font-size: 16px;
      color: #da1414;
    }
    .orderRoute {
      color: #2b353a;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
    }
  }
  .rightSide {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .driverIcon {
      font-size: 16px;
      color: #848a8d;
    }
    .driverName {
      color: #2b353a;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
    }
  }
}
