@import "./../../../styles/dashboard/variables.scss";

#shipperOrderListView {
  padding: $pageWrapper;

  .shipperOrderListViewheader {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #shipperOrderListView {
    padding: $pageWrapperResponsive;
  }
}
