@import "./../../../../../../../../styles/dashboard/variables.scss";

#RoadWeightGroupItem {
  padding: 8px 12px;
  background-color: $white;
  border-radius: 8px;
  border: 2px solid $secondary50;

  h4 {
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    span {
      color: $secondary100;
    }
  }

  .formGroup {
    .formGrouptem {
      width: 100px;
      display: flex;
      align-items: center;
      gap: 4px;
      h6 {
        color: $secondary300;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      #DInputs {
        .group {
          input {
            font-size: 14px;
            padding: 10px;
            height: 28px;
            border: 1px solid $secondary50;
          }
        }
      }
      &.error {
        #DInputs {
          input {
            border: 1px solid $error;
          }
          .DInputsIcon {
            color: $error;
          }
        }
      }
    }
  }
}
