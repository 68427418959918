@import "../../../../../styles/dashboard/variables.scss";

#NoMoreDataInfiniteScroll {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: $borderRadiusLarge;
  overflow: hidden;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 18px;
      color: #b0e8d1;
    }
  }
}
