@import "./../../../../styles/dashboard/variables.scss";

#DFileFormLargeItem {
  width: 100%;

  .Document {
    border-radius: $borderRadius;
    border: 1px solid $secondary75;
    padding: 4px 8px;
    height: 70px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      h6 {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        white-space: nowrap;
      }
    }

    .fileIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $secondary100;
        font-size: 24px;
      }
    }
    .removeIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: $error;
        font-size: 24px;
      }
    }
  }
}
