@import "../../../../styles/dashboard/variables.scss";

#CDashboardCompany {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding: $pageWrapper;
  .DashboardContainer {
    margin: 16px 0 24px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CDashboardCompany {
    padding: $pageWrapperResponsive;
  }
}
@media only screen and (max-width: $mediaMedium) {
  #CDashboardCompany {
    .DashboardContainer {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
