@import "./../../../styles/dashboard/variables.scss";

#NoNotifications {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  .icon {
    padding: 16px;
    background: $secondary25;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      font-size: 18px;
      color: $secondary100;
    }
  }
  h1 {
    color: $secondary300;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    padding-left: 24px;
    padding-right: 24px;
    width: 50%;
    color: $secondary100;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #NoNotifications {
    p {
      width: 100%;
    }
  }
}
