@import "./../../../styles/dashboard/variables.scss";

#NotificationList {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .NotificationsContent {
    margin-top: 12px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #NotificationList {
    padding: $pageWrapperResponsive;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #NotificationList {
    .NotificationsContent {
      margin-top: 8px;
    }
  }
}
