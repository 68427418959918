@import "./../../../../styles/dashboard/variables.scss";

#CDashboard {
  width: 100%;
  min-height: 100vh;
  background-color: $white;

  .CDashboardHeader {
    height: 80px;
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 30px;
    border-bottom: 1px solid $secondary300;

    #DButton {
      button {
        background-color: $white;
        border: 1px solid $secondary300;
        color: $secondary300;
      }
    }
  }
}
