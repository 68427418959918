@import "../../../../styles/landing/variables.scss";

#MainFooter.MainFooter {
  padding: 24px 0px;
  .container {
    display: flex;
    gap: 100px;
    justify-content: space-between;
    .logo {
      display: inline-block;
      .logoWrapper {
        cursor: pointer;
      }
    }
    .footerMenu {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #MainFooter.MainFooter {
    .container {
      gap: 16px;
      flex-direction: column;
      .logo {
        display: inline-flex;
        .logoWrapper {
          cursor: pointer;
        }
      }
      .footerMenu {
        gap: 24px;
        margin-left: 0px;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #MainFooter.MainFooter {
    .container {
      .footerMenu {
        gap: 16px;
        grid-template-columns: 1fr;
      }
    }
  }
}
