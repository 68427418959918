@import "./../../../../../../styles/dashboard/variables.scss";

#AccountantEmailEditModal {
  display: flex;
  justify-content: center;
  .AccountantEmailEditModalWrapper {
    width: 430px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #AccountantEmailEditModal {
    .AccountantEmailEditModalWrapper {
      width: 100%;
    }
  }
}
