@import "../../../../../.././styles/dashboard/variables.scss";

#ChargeFormInvoiceModal {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid $secondary50;
  border-top: 1px solid $secondary50;
  padding-top: 12px;
  padding-bottom: 12px;

  .chargeHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 10fr 3fr 3fr 3fr 1fr;
    gap: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: $secondary25;

    p {
      padding: 4px 8px;
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .chargeForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .chargeFooter {
    margin-top: 12px;
    #DButton {
      button {
        font-size: 14px;
        background-color: white;
        color: $primary500;
        min-height: 30px;
      }
    }
  }
}
