@import "../../../styles/dashboard/variables.scss";

#PreferencesAction {
  display: inline-flex;
  padding: 14px 24px;
  align-items: center;
  gap: 75px;
  border-radius: 8px;
  background: $violetBG;
  .leftContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 24px;
        color: $violet;
      }
    }
    .text {
      color: $secondary500;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  #DGradientButton {
    button {
      min-height: 20px;
      padding: 4px 12px;
    }
  }
}
