@import "../../../../../styles/dashboard/variables.scss";

#AddBankRequisitesModal {
  display: flex;
  justify-content: center;
  .fromGorup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
