@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderAditionalServices {
  width: 100%;
  padding: $pageWrapper;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: $borderRadiusLarge;
  background: $white;

  .listContainer {
    max-width: 1524px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderAditionalServices {
    padding: $pageWrapperResponsive;
    gap: 12px;
  }
}
