@import "./../../../../styles/dashboard/variables.scss";
#ShipperProfile {
  cursor: pointer;
  .ShipperProfileAvatar {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    background-color: #d3d3d3;
    border: 2px solid $secondary0;
  }
}
