@import "./../../../../styles/landing/variables.scss";

#ForWorkflowCarrier.ForWorkflowCarrier {
  padding: 48px 0;
  overflow: hidden;

  .container {
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    gap: 80px;
    margin-top: 32px;

    .items {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      justify-content: center;
      .infoContainer {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 22px 16px;
        gap: 10px;
        margin-top: 24px;

        background: #eef2fa;
        border-left: 4px solid #0052ea;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 24px;
            color: #0052ea;
          }
        }
        .text {
          color: $primaryContent;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
    .img {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 400px;
    }
  }
  #LinkTransparent a {
    color: $primarySurfance;
    background-color: $primaryBrand;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ForWorkflowCarrier.ForWorkflowCarrier {
    .container {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
      gap: 40px;
      .items {
        gap: 12px;
      }
      .img {
        grid-row: 1;
        height: 300px;
      }
    }
  }
}
