@import "./../../../../../styles/landing/variables.scss";

#WorkflowItem.WorkflowItem {
  display: grid;
  grid-template-columns: 64px 1fr;
  width: 100%;
  gap: 24px;

  .left {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $primaryBrand;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 24px;
        color: $primarySurfance;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 12px;
    h3 {
      color: $primaryContent;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: $secondaryContent;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
      text-align: start;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #WorkflowItem.WorkflowItem {
    grid-template-columns: 45px 1fr;
    gap: 6px;
    .left {
      width: 45px;
      height: 45px;
      .icon {
        svg {
          font-size: 24px;
        }
      }
    }
    .right {
      gap: 4px;
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
