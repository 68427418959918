@import "../../../../../styles/dashboard/variables.scss";
.ShipperCardSelectDate {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  position: relative;
  padding: 4px 12px;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary100;
  background: $secondary0;
  .nav-button {
    width: 16px;
    height: 16px;
    cursor: pointer;
    svg {
      font-size: 16px;
      color: $secondary300;
    }
    &.disabled {
      cursor: not-allowed;
      svg {
        color: $secondary100;
      }
    }
  }

  .month-label {
    color: $secondary300;
    user-select: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  .calendar-popup {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background-color: $secondary0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 100%;
    z-index: 1;
    .calendar {
      width: 250px;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 5px;
      background-color: #f9f9f9;
    }
    .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
      background-color: $secondary100;
      color: $secondary0;
      border-radius: 4px;
    }
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
      background-color: $primary500;
      color: $secondary0;
      border-radius: 4px;
    }
  }
}
