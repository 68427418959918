@import "./../../../../../styles/dashboard/variables.scss";
#ByRoute {
  overflow: hidden;
  margin-top: 12px;
  display: flex;
  gap: 4px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .ByRouteContainer {
    min-height: 96px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    width: 100%;
    .ByRouteItem {
      padding: 8px 12px;
      border-radius: $borderRadiusLarge;
      background-color: $secondary25;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;
      h1 {
        color: $secondary200;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
      }
      .desc {
        display: flex;
        gap: 8px;
        h5 {
          color: $success;
          font-size: 23px;
          font-style: normal;
          font-weight: 400;
        }
        .line {
          height: 100%;
          width: 1px;
          background: $secondary75;
        }
        h4 {
          color: $success;
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
        }
      }
      &:first-child {
        background-color: $successBG;
        .desc {
          color: $success;
        }
      }
    }
  }
  .SeeAllButton {
    a {
      color: $info;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #ByRoute {
    .ByRouteContainer {
      gap: 6px;
      .ByRouteItem {
        padding: 4px 6px;
        .desc {
          h5 {
            font-size: 16px;
          }
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ByRoute {
    .ByRouteContainer {
      display: grid;
      min-height: 324px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
