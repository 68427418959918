@import "./../../styles/dashboard/variables.scss";

#SideBar {
  background-color: $secondary0;
  height: 100vh;
  position: fixed;
  z-index: 10;
  box-shadow: 0px 1.25px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;

  &.max {
    width: $sidebarMax;
  }
  &.min {
    width: $sidebarMin;
  }

  .SidebarWrapper {
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    // gap: 24px;
    justify-content: space-between;
    overflow: hidden;
    // overflow-y: auto;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SideBar {
    display: none;
  }
}
