@import "../../../styles/landing/variables.scss";

#Label {
  user-select: none;
  margin-bottom: 4px;
  display: flex;
  label {
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: $secondaryContent;
    letter-spacing: -0.32px;
  }
}
