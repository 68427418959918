@import "./../../../../../styles/dashboard/variables.scss";

#OrderSidebarNoData {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  p {
    font-size: 14px;
    color: $warning;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
