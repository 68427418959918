@import "./../../../../styles/dashboard/variables.scss";

#DNavItem {
  width: 100%;
  overflow: hidden;
  a {
    #DNavItemWrapper {
      padding: 12px;
      border-radius: $borderRadius;
      display: flex;
      align-items: center;
      gap: 12px;
      &.min {
        .text {
          opacity: 0;
        }
      }
      .DNavItemWrapperMainIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 22px;
          color: $secondary100;
          transition: all 0.3s ease-in-out;
        }
      }
      .text {
        min-width: 160px;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        h3 {
          // text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: $secondary300;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          transition: all 0.3s ease-in-out;
          &:lang(ka) {
            font-size: 15px;
          }
        }
        &.menu {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .arrowIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              transition: all 0.3s ease-in-out;
              color: $secondary100;
              font-size: 16px;
            }
          }
        }
      }
      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;

        background-color: $primary500;
        color: $secondary0;
        font-size: 14px;
        font-weight: 600;
      }
    }
    &.active,
    &:hover {
      #DNavItemWrapper {
        background-color: $primary50;
        .DNavItemWrapperMainIcon {
          svg {
            color: $primaryBrand;
          }
        }
        p {
          color: $primaryBrand;
        }

        .text {
          h3 {
            color: $primary500;
          }
        }
      }
    }

    &.active {
      #DNavItemWrapper {
        .text {
          .arrowIcon {
            svg {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
  #DNavItemSubMenu {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 4px;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    &.show {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
    .text {
      padding: 4px;
      padding-left: 14px;
      display: flex;
      align-items: center;
      gap: 12px;
      .DNavItemSubMenuIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          opacity: 0;
          transition: all 0.3s ease-in-out;
          color: $secondary100;
          font-size: 14px;
        }
        &.show {
          svg {
            opacity: 1;
          }
        }
      }
      &.active {
        color: $primaryBrand;
        background: $primary50;
        .DNavItemSubMenuIcon {
          svg {
            color: $primaryBrand;
          }
        }
      }
      h3 {
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 153.846% */
        min-width: 160px;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        &.min {
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $mediaLarge) {
  #DNavItem {
    a {
      #DNavItemWrapper {
        padding: 8px 12px;
      }
    }
  }
}
