@import "../../../../styles/dashboard/variables.scss";

#QuotationField {
  vertical-align: middle;
  padding: 4px 10px;
  // display: flex;
  // justify-content: center;
  .QuotationFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      display: flex;
      padding: 4px 18px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 21px;
      border: 1px solid $success;
      background-color: $successBG;
      cursor: pointer;
      .text {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 16px;
          color: $success;
        }
      }
    }
    .containerAcceptOffer {
      .text {
        color: $success;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }
    }
    .waitingOffer {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
}
