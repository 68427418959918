@import "./../../../../../styles/dashboard/variables.scss";

#CustomersOrdersHeader {
  display: flex;
  flex-direction: column;

  .CustomersOrdersHeaderTitle {
    h2 {
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      color: $secondary300;
    }
  }

  #FiltersContainer {
    border: none;
  }
}
