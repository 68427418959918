@import "./../../../../../styles/dashboard/variables.scss";

#ShipperTrackingSectionMessage {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid $primary700;
  background: rgba(255, 255, 255, 0.3);

  .title {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    h2 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px;
    }
    .status {
      display: flex;
      padding: 4px 12px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border-radius: 34px;

      h6 {
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }
  h4 {
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
  }
  h5 {
    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
  }
}
