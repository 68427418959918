@import "./../../../../styles/dashboard/variables.scss";
#OrderProductWeightField {
  vertical-align: middle;
  padding: 12px 10px;
  .OrderProductWeightFieldContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    h1 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      white-space: nowrap;
      font-weight: 600;
      &.tooltip {
        cursor: pointer;
      }
    }
    p {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
.OrderProductWeightFieldTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;

  .OrderProductWeightFieldTooltipContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 8px;
    p {
      color: $secondary300;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
