@import "./../../../../../../styles/dashboard/variables.scss";

#CostItemShipper {
  width: 100%;
  gap: 32px;
  padding-right: 4px;
  display: grid;
  grid-template-columns: 2fr 4fr 3fr;
  .costName {
    color: $secondary300;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    // white-space: nowrap;
  }
  .costComment {
    color: $secondary200;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    .noComment {
      font-weight: 400;
    }
  }
  .contentRight {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    h2 {
      color: $primary700;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      white-space: nowrap;
      line-height: 20px;
      &.ex {
        color: $error;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #CostItemShipper {
    grid-template-columns: 1fr;
    gap: 2px;
    .contentRight {
      width: 100%;
      justify-content: space-between;
    }
  }
}
