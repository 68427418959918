@import "./../../styles/dashboard/variables.scss";

#BidsHeader {
  width: 100%;
  .nav {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
