@import "./../../../../../../styles/dashboard/variables.scss";

#orderAddNewCost {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 200px;

  .formGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  #OrderAdditionalCostComment {
    height: 70px;
  }
}
