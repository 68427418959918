@import "./../../../../../../styles/dashboard/variables.scss";

#AccountantEmailCreateModal {
  display: flex;
  justify-content: center;
  .AccountantEmailCreateModalWrapper {
    width: 430px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .description {
      color: $secondary500;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #AccountantEmailCreateModal {
    .AccountantEmailCreateModalWrapper {
      width: 100%;
    }
  }
}
