@import "./../../../../../../styles/dashboard/variables.scss";

#SelectTrailerModalTableItem {
  .action {
    cursor: pointer;
    color: $secondary500;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    border: 2px solid $secondary500;
    padding: 8px 12px;
    .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
      }
    }
    &.LinkTo {
      color: $primaryBrand;

      border-radius: 6px;
      border: 2px solid $primaryBrand;
    }
  }
}
