@import "./../../../../../../styles/dashboard/variables.scss";

#TrailerList {
  padding: $pageWrapper;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}
@media only screen and (max-width: $mediaLarge) {
  #TrailerList {
    padding: $pageWrapperResponsive;
  }
}
