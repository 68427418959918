@import "./../../../styles/dashboard/variables.scss";

#FilterShipperFinancialStatusesClips {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  .status {
    user-select: none;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 24px;
    border: 1px solid $secondary100;
    background: $white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    h4 {
      color: $secondary100;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
    }
    &.active {
      background: $secondary300;
      border: 1px solid $secondary300;

      h4 {
        color: $white;
      }
    }
  }
}
