@import "./../../../../../styles/dashboard/variables.scss";

#SelectTrailerModalTable {
  width: 100%;
  overflow-x: auto;

  .tableWrapper {
    width: 100%;
    table {
      border-collapse: collapse;
      width: 100%;
    }
  }
  &::-webkit-scrollbar {
    height: 6px;
  }
}
