@import "../../../styles/dashboard/variables.scss";

#LoadBoardFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 12px;
    border-bottom: 1px solid $secondary50;

    #LoadBoardAddressSearchForm {
      width: 100%;
    }
    .filtersButtonContainer {
      .showFiltersButton {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;

        border-radius: 32px;
        background: $secondary25;

        margin-top: 16px;
        cursor: pointer;
        user-select: none;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }
        .text {
          color: $secondary200;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
  #FiltersContainer {
    padding: 12px 8px;
    border-top: none;
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #LoadBoardFilters {
    .topContainer {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      .filtersButtonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .showFiltersButton {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #LoadBoardFilters {
    #FiltersContainer {
      padding: 12px 8px;
    }
  }
}
