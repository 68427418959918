@import "./../../../../styles/dashboard/variables.scss";

#DirectionShipperMapModal {
  background-color: $white;
  height: 80vh;
  width: 80vw;
  border-radius: 12px;
  overflow: hidden;

  .TrackingShipperMap {
    width: 100%;
    height: calc(100% - 40px);
  }
}

@media only screen and (max-width: $mediaLarge) {
  #DirectionShipperMapModal {
    width: 90vw;
  }
}
