@import "./../../../../styles/dashboard/variables.scss";

#PartnersListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

@media only screen and (max-width: $mediaSmall) {
  #PartnersListHeader {
    flex-direction: column-reverse;
  }
}
