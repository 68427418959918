@import "./../../../../styles/dashboard/variables.scss";

#ShowCustomerModal {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    color: $secondary200;
  }
  h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $secondary100;
    display: flex;
    align-items: center;
    gap: 3px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      svg {
        font-size: 16px;
      }
    }
  }
}
