@import "../../../../styles/landing/variables.scss";

#SignUpCompanyForm.SignUpCompanyForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
