@import "./../../../../../styles/dashboard/variables.scss";

#PartnerFleet {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  .PartnerFleetDescription {
    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-self: flex-start;
  }

  .PartnerFleetSwitcher {
    display: flex;
    padding: 4px;
    border-radius: $borderRadius;
    background-color: $secondary50;
    .item {
      padding: 12px 16px;
      border-radius: $borderRadius;
      display: flex;
      gap: 8px;
      align-items: center;
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      &.active {
        background: $secondary0;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #PartnerFleet {
    .PartnerFleetDescription {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
