@import "./../../../../styles/dashboard/variables.scss";
#TrackingWBorderField {
  vertical-align: middle;
  padding: 12px 10px;
  display: flex;
  .TrackingWBorderFieldContainer {
    display: inline-flex;
    padding: 4px 8px;
    border-radius: $borderRadiusLarge;
    border: 1px solid $secondary50;
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    &#link {
      &:hover {
        background-color: $secondary50;
      }
      cursor: pointer;
    }
  }
}
