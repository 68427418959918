@import "./../../../styles/dashboard/variables.scss";

#TrackingListing {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}
