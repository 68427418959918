@import "./../../../styles/dashboard/variables.scss";

#DSearch {
  width: 211px;
  height: 40px;
  padding: 0px 0px 0px 8px;
  border-radius: 8px;
  background-color: $secondary25;
  display: flex;

  .DInputsIcon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    svg {
      color: $secondary100;
      font-size: 16px;
    }
  }

  input {
    background-color: $secondary25;
    width: 100%;
    padding: 0px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    user-select: none;
    color: $primaryContent;
    &::placeholder {
      color: $secondary100;
    }
    &:focus {
      &::placeholder {
        color: $secondary50;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DSearch {
    width: 100%;
  }
}
