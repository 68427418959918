@import "./../../../../../styles/dashboard/variables.scss";

#FinancialDetailsShipperHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  .BlockA {
    display: flex;
    gap: 8px;
    align-items: center;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
      &.done {
        cursor: pointer;
        svg {
          color: $primaryBrand;
        }
      }
    }
    .title {
      color: $secondary500;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #FinancialDetailsShipperHeader {
    .BlockA {
      .icon {
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
