@import "./../../../styles/dashboard/variables.scss";

#DWarning.DWarning {
  .icon {
    cursor: pointer;
    position: relative;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
      color: $secondary200;
    }
    &:hover .DWarningContent {
      visibility: visible;
      opacity: 1;
    }
  }
  .DWarningContent {
    cursor: default;
    visibility: hidden;
    opacity: 0;
    max-width: 250px;
    min-width: 200px;
    top: 20px;
    right: 50%;
    transform: translateX(50%);
    position: absolute;
    z-index: 200;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    color: $secondary0;
    background-color: $secondary500;
    border-radius: $borderRadiusLarge;
    transition: all 0.2s ease-in-out;
  }
}
