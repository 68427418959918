@import "./../../../../styles/dashboard/variables.scss";

#SmallDescription {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  width: max-content;
  min-width: 250px;

  .SmallDescriptionRating {
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
    align-items: center;
    p {
      white-space: nowrap;
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: $secondary300;
  }
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    color: $secondary200;
  }
  h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $secondary100;
    display: flex;
    align-items: center;
    gap: 3px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      svg {
        font-size: 16px;
      }
    }
  }

  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    max-width: 300px;
    .trailerTypeTag {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 4px;
      background: $infoBG;
      color: $info;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .routeTypesTag {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 4px;
      background: #f2ebfb;
      border-radius: 4px;
      color: $info;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #SmallDescription {
    width: 100%;
    min-width: 100%;

    .tagsContainer {
      max-width: 100%;
    }
  }
}
