@import "./../../../styles/dashboard/variables.scss";
#QuotesTable {
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: $secondary0;
  padding: 12px;
  min-height: 310px;
  overflow: hidden;
  width: 40%;
}
@media only screen and (max-width: $mediaLarge) {
  #QuotesTable {
    width: 100%;
  }
}
