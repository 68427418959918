@import "./../../../../../styles/landing/variables.scss";

#FaqItem {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;

  .header {
    padding: 24px 16px;
    display: flex;
    gap: 3px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .title {
      display: flex;
      align-items: center;
      gap: 16px;
      .icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          transition: all 0.3s ease-in-out;
          font-size: 16px;
          color: #8addbb;
        }
      }
      h3 {
        color: $primaryContent;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: start;
        margin: 0;
        text-transform: none;
        font-weight: 700;
      }
    }
    .arrow {
      width: 20px;
      height: 20px;
      transition: all 0.3s ease-in-out;

      svg {
        color: #8addbb;
        font-size: 20px;
      }
    }
  }
  .bodydescription {
    width: 100%;
    height: 0;
    overflow: hidden;
    .content {
      width: 100%;
      padding: 0px 16px 24px 16px;
      p {
        color: $secondaryContent;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        strong {
          font-weight: 700;
          display: inline-block;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &:hover {
    background-color: $green50;
  }
  &.active {
    background-color: $green50;
    .header {
      .title {
        .icon {
          svg {
            color: $primaryBrand;
          }
        }
      }
      .arrow {
        transform: rotate(90deg);
        svg {
          color: $primaryBrand;
        }
      }
    }
    .bodydescription {
      height: auto;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #FaqItem {
    .header {
      padding: 12px 8px;
      .title {
        gap: 8px;
        h3 {
          font-size: 14px;
        }
      }
    }

    .bodydescription {
      .content {
        padding: 0px 8px 12px 8px;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
