@import "./../../../../../../../styles/dashboard/variables.scss";

#AssignPartnerTableModal {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 150px;

  .curr {
    max-width: 300px;
  }
}
