@import "./../../../../../styles/landing/variables.scss";
#CarrierAppTitle {
  display: flex;
  flex-direction: column;
  gap: 32px;
  h1 {
    color: $primaryBrand;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  p {
    color: $secondaryContent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.16px;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #CarrierAppTitle {
    gap: 24px;
    h1 {
      font-size: 32px;
    }
  }
}
