@import "./../../../styles/dashboard/variables.scss";

#Cards {
  .cardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    .skeleton {
      width: 188px;
      height: 150px;
    }
  }
  .cardsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    .cardsHeaderTitle {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }
    #DDropdown {
      width: 200px;
      .dropdown-content {
        border: none;
        padding: 0;
        border-radius: 8px;
      }
      .calendar {
        width: 250px;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 5px;
        background-color: #f9f9f9;
      }
      .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
        background-color: $secondary100;
        color: $secondary0;
        border-radius: 4px;
      }
      .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
        background-color: $primary500;
        color: $secondary0;
        border-radius: 4px;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  #Cards {
    .cardsContainer {
      grid-template-columns: 1fr;
      gap: 12px;
      .skeleton {
        width: 100%;
      }
    }
    .cardsHeader {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      margin: 0 0 16px 0;
      #DDropdown {
        width: 100%;
        .calendar {
          width: 100%;
        }
      }
    }
  }
}
