@import "./../../../../styles/dashboard/variables.scss";

#GoogleMapDeviceTraking {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 300px 1fr;
}

@media only screen and (max-width: $mediaLarge) {
  #GoogleMapDeviceTraking {
    height: calc(100vh - $mobileHeaderHeight);
  }
}

@media only screen and (max-width: $mediaMedium) {
  #GoogleMapDeviceTraking {
    height: auto;
    overflow: scroll;
    grid-template-columns: 1fr;
  }
}
