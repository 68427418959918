@import "./../../../../../styles/dashboard/variables.scss";

#PartnerContactTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: $white;
  padding: 6px;
  margin-bottom: 32px;

  .headerContact {
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    h2 {
      color: $secondary500;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .tableWrapper {
    overflow: hidden;
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #PartnerContactTable {
    .tableWrapper {
      overflow-x: auto;
      width: calc($fullWidthResponsive - 12px);
      &::-webkit-scrollbar {
        height: 6px;
      }
    }
  }
}
