@import "./../../../../styles/dashboard/variables.scss";

#DButton {
  button {
    min-height: 40px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    padding: 0px 16px;
    border-radius: $borderRadius;
    border: 1px solid $primaryBrand;
    background-color: $primaryBrand;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    text-align: center;
    white-space: nowrap;
    &:hover {
      background-color: $primaryHover;
      border-color: $primaryHover;
    }
    .buttonIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      svg {
        font-size: 16px;
      }

      &.leftIconButton {
        margin-right: 8px;
      }
      &.rightIconButton {
        margin-left: 8px;
      }
    }
  }

  &.disabled {
    button {
      border: 1px solid $secondary75;
      background-color: $secondary75;
      cursor: not-allowed;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DButton {
    width: 100%;
    button {
      min-width: 100%;
      font-size: 14px;

      .buttonIcon {
        height: 14px;
        width: 14px;
        svg {
          font-size: 14px;
        }

        &.leftIconButton {
          margin-right: 4px;
        }
        &.rightIconButton {
          margin-left: 4px;
        }
      }
    }
  }
}
