@import "./../../../../styles/dashboard/variables.scss";

#SentUpdateSentUpdatesDetailsItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  border-radius: 8px;
  padding: 12px 18px;
  border: 1px solid $primary100;
  background: $secondary0;
  .SentUpdatesDetailsContainerHeader {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    .SentUpdatesDetailsContainerHeaderLeft {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $secondary300;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      h3 {
        color: $secondary200;
        border-radius: $borderRadius;
        background: $secondary25;
        padding: 4px 8px;
      }
    }
    .SentUpdatesDetailsContainerHeaderRight {
      background-color: $secondary25;
      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 34px;
      // background: $successBG;
      padding: 3px 8px;
      h3 {
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 11px;
        white-space: nowrap;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: inherit;
        }
      }
    }
  }
  p {
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    span {
      color: $secondary75;
    }
  }
  .SentUpdatesDetailsDate {
    display: flex;
    gap: 4px;
    align-items: center;
    align-self: flex-end;
    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 12px;
        color: $secondary100;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #SentUpdateSentUpdatesDetailsItem {
    .SentUpdatesDetailsContainerHeader {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
