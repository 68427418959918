@import "./../../../../../styles/dashboard/variables.scss";

#ShipperOrderListCardsLoading {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: $borderRadiusLarge;
  overflow: hidden;
}
