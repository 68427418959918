@import "./../../../../../styles/dashboard/variables.scss";

#ShipperCreateOrderLocation {
  width: 100%;
  padding: $pageWrapper;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: $borderRadiusLarge;
  background: $white;

  .addressConatiner {
    width: 100%;
    max-width: 750px;
    display: flex;
    gap: 48px;
    align-items: flex-start;

    .addressGroup {
      width: 100%;
      min-height: 100px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      h4.title {
        padding: 0px 16px;
        color: $secondary500;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
      }

      .radioItem {
        display: inline-flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        color: $secondary100;
        user-select: none;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 16px;
            color: inherit;
          }
        }

        p {
          color: $secondary100;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        &.active {
          color: $primary500;
        }
      }
      .suggestionsContainer {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 24px;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 320px;
        &::-webkit-scrollbar {
          height: 6px;
        }
        .suggestionItem {
          display: flex;
          padding: 2px 8px;
          align-items: center;

          border-radius: 4px;
          background: $infoBG;
          color: $info;

          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;

          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperCreateOrderLocation {
    padding: $pageWrapperResponsive;
    gap: 12px;
    .addressConatiner {
      gap: 24px;
      flex-direction: column;
    }
  }
}
