@import "./../../../../../styles/landing/variables.scss";

#DetailsFreightItem.DetailsFreightItem {
  .DetailsFreightItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .cover {
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    h1 {
      color: $primarySurfance;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
    }
    h3 {
      color: #fcfcfc;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.32px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DetailsFreightItem.DetailsFreightItem {
    .DetailsFreightItemContainer {
      gap: 8px;
      h1 {
        font-size: 16px;
      }
      h3 {
        font-size: 14px;
      }
    }
  }
}
