@import "../../../styles/dashboard/variables.scss";

#InvoicesMenu {
  user-select: none;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  .viewInvoicesButton {
    height: 100%;
    width: 100%;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border-radius: $borderRadius;
    border: 2px solid #1a2023;
    background-color: transparent;
    color: $secondary500;
    font-size: 16px;
    font-style: normal;
    font-family: inherit;
    transition: all 0.3s ease-in-out;
    text-align: center;
    white-space: nowrap;
    gap: 8px;
    font-weight: 600;
    .viewInvoicesButtonIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 15px;
      }
    }
  }
}

#GenerateInvoiceButton {
  #DButton {
    button {
      min-height: 36px;
      padding: 0px 10px;
      min-width: 180px;
      border: 1px solid $secondary500;
      background-color: $secondary500;
    }
  }
}
