@import "./../../../../styles/dashboard/variables.scss";

#TrailerField {
  // text-align: center;
  vertical-align: middle;
  .TrailerFieldContainer {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 8px;
    align-items: center;
    padding: 4px 10px;
    .cover {
      width: 50px;
      height: 25px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .content {
      width: 100%;
      color: $secondary300;
      font-style: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
        line-height: 13px;
      }
      p {
        font-size: 11px;
        line-height: 11px;
        font-weight: 400;
        white-space: nowrap;
        margin-top: 2px;
      }
    }
  }
  &.link {
    cursor: pointer;
    &:hover {
      background-color: $secondary50;
    }
  }
}
