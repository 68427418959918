@import "./../../../../../../styles/dashboard/variables.scss";

#ShipperEditOrderRoad {
  width: 100%;
  min-height: calc(100vh - 130px);
  background-color: #f9f9f9;
  padding: $pageWrapper;

  .ShipperCreateOrderRoadWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperEditOrderRoad {
    padding: $pageWrapperResponsive;
    .ShipperCreateOrderRoadWrapper {
      gap: 12px;
    }
  }
}
