@import "../../../styles/dashboard/variables.scss";

#LoadBoardAddressSearchForm {
  max-width: 1000px;
  display: flex;
  // align-items: flex-end;
  gap: 12px;
  padding: 8px 12px;
  .favourite {
    margin-top: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .favouriteContainer {
      width: 100%;
      height: 100%;
      border-radius: 32px;
      background-color: $errorBG;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 20px;
        color: $error;
        cursor: pointer;
        svg {
          path:nth-child(1) {
            fill: transparent;
            transition-duration: 300ms;
          }
        }
        &.active {
          svg {
            path:nth-child(1) {
              fill: $error;
              opacity: 1;
              transition-duration: 300ms;
            }
          }
        }
      }
    }
  }
  .searchButton {
    margin-top: 21px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #LoadBoardAddressSearchForm {
    padding: 8px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #LoadBoardAddressSearchForm {
    align-items: center;
    flex-direction: column;
    .favourite {
      margin-top: 0px;
    }
    .searchButton {
      margin-top: 0px;
    }
  }
}
