@import "./../../../styles/landing/variables.scss";
#SubscribeCarrier.SubscribeCarrier {
  padding: 48px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .SubscribeCarrierContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .SubscribeCarrierContent {
      max-width: 650px;
      min-height: 330px;
      padding: 24px 48px;
      border-radius: 12px;
      background: rgba(43, 53, 58, 0.8);
      backdrop-filter: blur(8px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        color: $primarySurfance;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
      }
      p {
        color: $grayNormal;
        font-size: 16px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.16px;
        line-height: 20px;
        padding: 16px 0px 32px 0px;
      }
      #Button {
        width: auto;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #SubscribeCarrier.SubscribeCarrier {
    .SubscribeCarrierContainer {
      justify-content: center;
      .SubscribeCarrierContent {
        p {
          padding: 8px 0px 16px 0px;
        }
      }
    }
  }
}
