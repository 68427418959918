@import "./../../../styles/dashboard/variables.scss";

#ShipperTrackingSection {
  width: 100%;
  min-height: 300px;
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: $white;
  .noTracking {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: 100%;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 250px;
      height: 200px;
    }
    h1 {
      &.title {
        color: #212529;

        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    p {
      &.desc {
        color: #878a99;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ShipperTrackingSection {
    padding: 12px;
  }
}
