@import "./../../../../../../styles/dashboard/variables.scss";

#SendBidDetails {
  background-color: $secondary0;
  height: 80vh;
  width: 80vw;
  border-radius: 12px;
  padding: 24px;
  overflow: hidden;
  .SendBidDetailsContent {
    height: 100%;
    padding: 5px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    .SendBidDetailsContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .SendBidDetailsHeader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: flex-end;
        .SendBidDetailsHeaderBackButton {
          display: flex;
          align-items: center;
          gap: 8px;
          color: $secondary100;
          font-weight: 600;
          font-size: 18px;
          cursor: pointer;
        }
        svg {
          cursor: pointer;
          font-size: 18px;
          color: $secondary100;
        }
      }
      #DTextarea textarea {
        height: 100px;
      }

      .sendBidActions {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 16px;
        .all {
          #DButton button {
            white-space: nowrap;
            background: $secondary500;
            border-color: $secondary500;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #SendBidDetails {
    width: 90vw;
    padding: 12px;
    .SendBidDetailsContent {
      .SendBidDetailsContainer {
        .sendBidActions {
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }
}
