@import "./../../../../styles/landing/variables.scss";

#ContactLandFreight {
  padding: 48px 0;
  overflow: hidden;
  .main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ContactLandFreight {
    .main {
      gap: 16px;
      grid-template-columns: 1fr;
    }
  }
}
