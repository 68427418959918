@import "./../../../styles/dashboard/variables.scss";

#FinancialManagementAccountant {
  .NoEmail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0px;

    #DButton {
      button {
        min-height: 30px;
        font-size: 14px;
      }
    }

    p {
      color: $info;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
