@import "./../../../styles/dashboard/variables.scss";

#DashboardOrdersTable {
  display: flex;
  flex-direction: column;
  #DButton {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    button {
      background-color: transparent;
      color: $primaryBrand;
      border-width: 2px;
    }
  }
}
