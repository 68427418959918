@import "./../../../../../styles/landing/variables.scss";

#ContactFreightForm {
  .ContactFreightFormContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      display: flex;
      flex-direction: column;
      .desc {
        display: flex;
        gap: 12px;
        margin-bottom: 8px;
        align-items: center;
        h6 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.32px;
        }
        .icon {
          width: 12px;
          height: 12px;
          background-color: $primaryBrand;
        }
      }
      #HomeTitle.HomeTitle {
        justify-content: flex-start;
        .title {
          align-items: flex-start;
          gap: 10px;
          width: 100%;
          h1 {
            font-size: 32px;
            text-align: start;
          }
          p {
            text-align: start;
            font-size: 14px;
          }
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;

      #SelectForm {
        width: 50%;
      }
      h2 {
        color: $primaryContent;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        align-items: flex-start;
        .left,
        .right {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .item {
          display: grid;
          grid-template-columns: 48px 1fr;
          gap: 19px;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            background-color: $green50;
            border-radius: 50%;
            svg {
              font-size: 20px;
              color: $primaryBrand;
            }
          }

          p {
            font-style: normal;
            font-weight: 500;
            color: $primaryContent;
            font-size: 12px;
            font-style: normal;
            letter-spacing: -0.12px;
          }
          .list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            a {
              color: $secondaryContent;
              font-style: normal;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #ContactFreightForm {
    .ContactFreightFormContainer {
      gap: 12px;
      .container {
        .content {
          gap: 4px;
          grid-template-columns: 1fr;
        }
        #SelectForm {
          width: 100%;
        }
      }
    }
  }
}
