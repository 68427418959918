@import "./../../../../styles/landing/variables.scss";

#FormTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    color: $primaryContent;
  }
  p {
    color: $secondaryContent;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.14px;

    span {
      color: $primaryBrand;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #FormTitle {
    h1 {
      font-size: 20px;
    }
  }
}
