@import "./../../../../styles/landing/variables.scss";

#RememberMe {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
  align-items: center;
  p {
    color: $secondaryContent;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-left: 4px;
    text-align: center;
    white-space: nowrap;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid $secondaryBorder;
    border-radius: $borderRadiusSmall;
  }
}

#RememberMe.checked {
  .icon {
    border: 1px solid $primaryBorder;
    background-color: $primaryBorder;
    svg {
      font-size: 12px;
      color: $primarySurfance;
    }
  }
}
