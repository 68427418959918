@import "../../../../styles/dashboard/variables.scss";

#DBackButton {
  min-height: auto;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  user-select: none;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $secondary200;
      font-size: 16px;
    }
  }
  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $secondary100;
  }
}
