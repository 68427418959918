@import "./../../../../../styles/dashboard/variables.scss";

#TruckMaintenance {
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  padding: 12px 16px;
  h1 {
    color: $secondary300;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 24px;
  }
  .TruckMaintenanceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    gap: 10px;
    #DButton button {
      background-color: $secondary500;
      border-color: $secondary500;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #TruckMaintenance {
    h1 {
      margin-bottom: 12px;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #TruckMaintenance {
    .TruckMaintenanceHeader {
      flex-direction: column-reverse;
    }
  }
}
