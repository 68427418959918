@import "./../../../../styles/landing/variables.scss";

#TermsAgree {
  display: flex;
  flex-direction: row;
  align-items: center;
  .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    margin-right: 4px;
    .icon {
      background-color: $primarySurfance;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border: 1px solid $secondaryBorder;
      border-radius: $borderRadiusSmall;
      margin-right: 8px;
    }
  }

  p,
  a {
    color: $secondaryContent;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  a {
    color: $primaryBrand;
    position: relative;
    .line {
      width: 0;
      height: 0.5px;
      background-color: $primaryHover;
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
    }
    &:hover .line {
      width: 100%;
      transition: all 0.4s ease-in-out;
    }
  }
}

#TermsAgree.agree {
  .button {
    .icon {
      background-color: $primaryBrand;
      border: 1px solid $primaryBrand;
      svg {
        font-size: 12px;
        color: $primarySurfance;
      }
    }
  }
}
