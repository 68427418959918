@import "../../../../../../styles/landing/variables.scss";

#CarrierSubscribeFormFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  .orContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    .or {
      text-align: center;
      color: $gray75;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
    .requestCall {
      text-align: center;
      border: none;
      background: none;
      cursor: pointer;

      color: $primaryBrand;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}
