@import "./../../../../../styles/dashboard/variables.scss";

#PartnerOrdersTableHeader {
  display: flex;
  flex-direction: column;
  .PartnerOrdersTableHeaderTitle {
    h2 {
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      color: $secondary300;
    }
  }

  #FiltersContainer {
    border: none;
  }
}
