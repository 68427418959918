@import "../../../../../../styles/dashboard/variables.scss";

#ContainerTrackingItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .header {
    width: 100%;
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;

    border-top: 1px solid $secondary50;
    border-bottom: 1px solid $secondary50;
    background: $secondary25;
    .titleContainer {
      display: flex;
      align-items: center;
      gap: 6px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary200;
        }
      }
      .title {
        color: $secondary200;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        user-select: none;
      }
    }
    .statusIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary200;
      }
      &.done {
        svg {
          color: $success;
        }
      }
    }
  }
  .trackingInfoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    min-width: 400px;

    .trackingInfoItem {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 32px;
      .leftSide {
        width: 45%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .rightSide {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 12px;
        .rightSideContainer {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 32px;
          height: 80px;
          .center {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            color: $secondary200;

            gap: 12px;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                font-size: 16px;
              }
              &.current {
                display: flex;
                padding: 5px;
                align-items: center;

                border-radius: 50%;
                border: 1px solid #d7e4fc;
              }
            }
            .line {
              width: 2px;
              height: 100px;
              background: repeating-linear-gradient(
                to bottom,
                white 0,
                /* Starting point of dash */ white 5px,
                /* Dash length */ transparent 5px,
                /* Starting point of gap */ transparent 10px
              );
              margin: 0 20px;
              &.actual {
                background: #0ab39c;
              }
            }
          }
        }
      }
    }
  }
}
