@import "./../../../../../styles/dashboard/variables.scss";

#AddAlreadyPaidMoney {
  display: flex;
  align-items: center;
  gap: 4px;
  border-top: 1px solid $secondary50;
  padding-top: 16px;
  #DButton button {
    background-color: transparent;
    color: $primaryBrand;
    border-width: 2px;
    font-weight: 600;
  }

  .AddAlreadyPaidMoneyContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    border-radius: $borderRadius;
    background: $successBG;
    padding: 4.2px 12px;
    border: 1px solid $primaryBrand;
    .AddAlreadyPaidMoneyLeft {
      display: flex;
      align-items: center;
      gap: 4px;
      h1 {
        color: $success;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
      }
      p {
        color: $success;
        font-size: 16px;
        font-style: normal;
        white-space: nowrap;
        font-weight: 600;
      }
    }
    .AddAlreadyPaidMoneyRight {
      display: flex;
      align-items: center;
      gap: 4px;
      .showIcon {
        visibility: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      #DIconButton {
        border-radius: $borderRadius;
        border: 2px solid $primaryBrand;
        width: 25px;
        height: 25px;
        svg {
          color: $primaryBrand;
          font-size: 14px;
        }
      }
    }
    &:hover {
      .showIcon {
        visibility: 1;
        opacity: 1;
      }
    }
  }
  .markAsPaidButton {
    #DButton button {
      background-color: $primaryBrand;
      border-color: $primaryBrand;
      color: $secondary0;
    }
  }
  .orderprice {
    #DButton button {
      background-color: $primaryBrand;
      border-color: $primaryBrand;
      color: $secondary0;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #AddAlreadyPaidMoney {
    .AddAlreadyPaidMoneyContainer {
      .AddAlreadyPaidMoneyRight {
        .showIcon {
          visibility: 1;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #AddAlreadyPaidMoney {
    flex-direction: column;
    .markAsPaidButton {
      width: 100%;
    }
  }
}
