@import "./../../../../styles/dashboard/variables.scss";

#EditCustomerModal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .EditCustomerModalgroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #EditCustomerModal {
    .EditCustomerModalgroup {
      width: 100%;
      gap: 6px;
    }
  }
}
