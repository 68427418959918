@import "./../../../../../styles/dashboard/variables.scss";

#InfoWindowAddress {
  width: 100%;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  .titleContainer {
    width: 100%;
    padding: 0px 4px;
    display: flex;
    justify-content: space-between;
    h3 {
      color: $secondary300;
      font-size: 13px;
      font-weight: 600;
    }
    .routeDistance {
      color: $secondary200;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }
  .InfoWindowAddressdesc {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }

      h4 {
        color: $secondary200;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
