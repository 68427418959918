@import "./../../../../styles/dashboard/variables.scss";

#ShowInvoiceField {
  vertical-align: middle;
  padding: 4px 10px;

  .ShowInvoiceFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .viewInvoicesButton {
      height: 100%;
      max-width: 250px;
      width: 100%;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      cursor: pointer;
      border-radius: $borderRadius;
      border: 2px solid $secondary300;
      background-color: transparent;
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      transition: all 0.3s ease-in-out;
      text-align: center;
      white-space: nowrap;
      gap: 8px;
      font-weight: 600;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary300;
        }
      }
      .payd {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $primary700;
        }
      }
    }
  }

  .NoInvoiceFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      white-space: nowrap;
      color: $warning;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
