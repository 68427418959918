@import "../../../../styles/landing/variables.scss";

#CarrierSubscribeNowCover {
  width: 100%;
  background-color: $grayNormal;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 420px;
  position: relative;
  .blar {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(179deg, rgba(30, 37, 41, 0) 0.97%, rgba(30, 37, 41, 0.6) 97.16%);
    .container {
      position: absolute;
      width: 100%;
      height: 100%;

      .text {
        position: absolute;
        width: 80%;
        top: 30px;
        left: 0;
        z-index: 1;
      }
    }
    .partnersMainContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background-color: $primaryContent;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        padding: 0 24px;
        display: flex;
        align-items: center;
        .itemTitle {
          display: flex;
          width: 100%;
          height: 100%;
          img {
            height: 100px;
            width: 140px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CarrierSubscribeNowCover {
    min-height: 340px;
    background-position: 15%;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #CarrierSubscribeNowCover {
    .blar {
      .partnersMainContainer {
        .item {
          padding: 12px;
          .itemTitle {
            img {
              height: 80px;
              width: 100px;
            }
          }
        }
      }
    }
  }
}
