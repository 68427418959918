@import "./../../../../../../styles/dashboard/variables.scss";

#TruckOrdersTableHeader {
  h1 {
    color: $secondary400;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
  }
  #FiltersContainer {
    border: none;
  }
}
