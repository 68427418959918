@import "./../../../../../styles/dashboard/variables.scss";

#PaymentNewOrder {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background-color: $white;
  .PaymentNewOrderWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    .groupItem {
      width: 250px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  #PaymentNewOrder {
    padding: 12px;
    gap: 12px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  @media only screen and (max-width: $mediaMedium) {
    #PaymentNewOrder {
      .PaymentNewOrderWrapper {
        .groupItem {
          width: 100%;
        }
      }
    }
  }
}
