@import "./../../../../../styles/dashboard/variables.scss";

#UploadDocumentItem {
  width: 100%;
  .UploadDocumentItemContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    .item {
      padding: 8px 15px;
      width: 100%;
      border-radius: $borderRadiusLarge;
      border: 1px solid $secondary50;
      background: $secondary0;
      display: flex;
      gap: 8px;
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: $secondary200;
        }
      }

      h1 {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
      }

      .tag {
        border-radius: 4px;
        background: $secondary25;
        padding: 4px 8px;
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }
    }
    .action {
      display: flex;
      gap: 12px;
      user-select: none;
      .icon {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 18px;
          color: $secondary500;
        }

        &.delete {
          svg {
            color: $error;
          }
        }
      }
    }
  }
}
