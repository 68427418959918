@import "../../../../../styles/dashboard/variables.scss";

#profileUserChangePassword {
  display: flex;
  align-items: center;
  justify-content: center;
  .formGroup {
    min-height: 150px;
    display: flex;
    width: 350px;
    flex-direction: column;
    gap: 12px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #profileUserChangePassword {
    .formGroup {
      width: 100%;
    }
  }
}
