@import "../../../../styles/dashboard/variables.scss";

#tableTextField {
  vertical-align: middle;
  padding: 12px 10px;
  p {
    white-space: nowrap;
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
