@import "./../../../../styles/dashboard/variables.scss";
#MobileTitle {
  margin-bottom: 24px;
  #MainTitle h1 {
    font-size: 19px;
  }
  p {
    color: $secondary300;
    font-size: 13px;
    width: 70%;
  }
}
@media only screen and (max-width: $mediaMedium) {
  #MobileTitle {
    p {
      width: 100%;
    }
  }
}
