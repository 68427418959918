@import "./../../../styles/dashboard/variables.scss";

#FinancialFilter {
  width: 100%;
  margin-top: 24px;
  .filtersContainer {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .statusWrapper {
    margin-bottom: 16px;
  }
}
