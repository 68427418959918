@import "../../../../../styles/dashboard/variables.scss";

#OrderStatus {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .OrderStatusCurrentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid $primary500;
  }
  .OrderStatusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 34px;
    padding: 4px 12px;
    background-color: $secondary25;
    gap: 10px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary500;
      svg {
        color: inherit;
        font-size: 16px;
      }
    }

    .OrderStatusName {
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      color: $secondary200;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
