@import "../../../styles/dashboard/variables.scss";

#ShipperOrderListHeader {
  width: 100%;
  .searchContainer {
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperOrderListHeader {
    .searchContainer {
      flex-direction: column-reverse;
    }
  }
}
