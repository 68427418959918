@import "./../../../../styles/dashboard/variables.scss";
#ShowCustomerDetails {
  border-radius: $borderRadiusLarge;
  background: $secondary25;
  display: flex;
  justify-content: space-between;
  padding: 8px 4px 8px 12px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  .ShowCustomerDetailsLeft {
    color: $secondary300;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    span {
      font-size: 13px;
      color: $secondary500;
      font-weight: 600;
    }
    svg {
      color: $secondary100;
      font-size: 20px;
    }
  }
  .ShowCustomerDetailsRight {
    // visibility: hidden;
    // opacity: 0;
    // transition: all 0.3s ease-in-out;

    #DShowButton {
      svg {
        color: $secondary100;
        font-size: 18px;
      }
    }
  }

  // &:hover {
  //   .ShowCustomerDetailsRight {
  //     opacity: 1;
  //     visibility: visible;
  //   }
  // }
}
