@import "../../../../styles/dashboard/variables.scss";

#tableMoreFieldMore {
  user-select: none;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  .icon {
    background-color: $secondary50;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px;
    svg {
      font-size: 18px;
      color: $secondary300;
    }
  }
}
