@import "./../../../../styles/dashboard/variables.scss";
#ShipperCardsContainer {
  .ShipperCardsContainerRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 12px 0px;
    border-bottom: 1px solid $secondary25;
    align-items: center;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ShipperCardsContainer {
    .ShipperCardsContainerRow {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
