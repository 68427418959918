@import "./../../../styles/dashboard/variables.scss";

#DModalComponent {
  width: 600px;
  background-color: $white;
  border-radius: $borderRadiusLarge;
  overflow: hidden;

  .header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .titleIcon {
        display: flex;
        svg {
          font-size: 16px;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .content {
    width: 100%;
    min-height: 120px;
    max-height: 80vh;
    padding: 16px 24px;
    background-color: $white;
    border-bottom: 1px solid $secondary50;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
  }
  .footer {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;

    .submit,
    .cancel {
      cursor: pointer;
      min-height: 40px;

      padding: 0px 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: $white;
    }

    .cancel {
      color: $secondary200;
      background-color: $white;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DModalComponent {
    width: 90vw;
    .header {
      padding: 12px 16px;
      gap: 4px;
      .title {
        gap: 4px;
      }
    }
    .content {
      padding: 12px 16px;
      gap: 4px;
      max-height: calc(80vh - 110px);
      overflow: auto;
    }
    .footer {
      padding: 8px 16px;
      gap: 8px;
    }
  }
}
