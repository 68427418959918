@import "./../../../../../../styles/dashboard/variables.scss";

#ShipperCreateOrderAir {
  width: 100%;
  min-height: calc(100vh - 130px);
  background-color: #f9f9f9;
  padding: $pageWrapper;

  .ShipperCreateOrderAirWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperCreateOrderAir {
    padding: $pageWrapperResponsive;
    .ShipperCreateOrderAirWrapper {
      gap: 12px;
    }
  }
}
