@import "../../../../../styles/landing/variables.scss";

#VectorMapMarker.VectorMapMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  .markerContent {
    min-width: 250px;
    box-shadow: 0px 0px 20px 0px rgba(8, 15, 52, 0.2);
    border-radius: $borderRadiusLarge;
    background-color: $primarySurfance;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.16px;
        color: $primaryContent;
      }
      .close {
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: $primaryContent;
          font-size: 24px;
        }
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .customMarkerlist {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;

        .customMarkeritem {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          .icon {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: $primaryContent;
              font-size: 20px;
            }
          }
          p {
            color: $primaryContent;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #VectorMapMarker.VectorMapMarker {
    .markerContent {
      padding: 12px 16px;
      gap: 12px;
      .header {
        h2 {
          font-size: 18px;
        }
      }
      .body {
        .customMarkerlist {
          .customMarkeritem {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
