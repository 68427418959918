@import "../../../../styles/landing/variables.scss";
#RoleRadio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .item {
    display: flex;
    gap: 12px;
    align-items: center;
    user-select: none;
    p {
      cursor: pointer;
      display: inline-block;
      color: $secondaryContent;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.32px;
    }
    .icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid $secondaryBorder;
      background-color: $primarySurfance;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: none;
        color: $primarySurfance;
        font-size: 10px;
      }
    }

    &.active {
      .icon {
        background-color: $primaryBrand;
        border: 1px solid $primaryBrand;
        svg {
          display: block;
        }
      }
    }
  }
}
