@import "./../../../../styles/dashboard/variables.scss";

#SelectDriverField {
  padding: 8px 10px;
  min-width: 250px;
  vertical-align: middle;
  .SelectDriverFieldContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $secondary100;
    background: $secondary0;
    padding: 2px 12px;
    min-height: 30px;
    .userIcon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $secondary100;
        font-size: 16px;
      }
    }
    .content {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $secondary100;
          font-size: 16px;
        }
      }
      .text {
        color: $secondary300;
        font-style: normal;
        display: flex;
        white-space: nowrap;
        flex-direction: column;
        gap: 2px;
        h3 {
          font-size: 13px;
          font-weight: 600;
        }
        p {
          font-size: 11px;
          font-weight: 400;
        }
      }
    }
  }
  .noDriverContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $secondary75;
    background: $secondary0;
    padding: 4px 12px;
    width: 100%;
    .noDriverContainerContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $secondary75;

          font-size: 20px;
        }
      }
    }
  }
}
