@import "./../../../../../styles/dashboard/variables.scss";

#NoTrackingTruckListItem {
  border-radius: 4px;
  border: 2px solid $secondary50;
  background: #fff;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  cursor: pointer;

  .line {
    height: 1px;
    width: 100%;
    background-color: $secondary50;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;

    .truck {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .cover {
        width: 35px;
        height: 30px;
        background-position: center;
        background-size: contain;
        position: relative;
        background-repeat: no-repeat;
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h2 {
          color: $secondary300;
          font-size: 16px;
          font-weight: 600;
        }
        h3 {
          color: $secondary200;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
  .actions {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    .connectToGps {
      width: 100%;
      #DButton button {
        min-height: 28px;
        width: 100%;
        font-size: 13px;
        padding: 4px 0px;
        border-width: 2px;
        white-space: nowrap;
      }
    }
    .trackWithMobile {
      width: 100%;
      #DButton button {
        min-height: 28px;
        width: 100%;
        font-size: 13px;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid $secondary500;
        color: $secondary500;
        white-space: nowrap;
        padding: 4px 0px;
      }
    }
  }

  .link {
    width: 100%;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: $info;
      font-size: 13px;
      font-weight: 500;
    }
  }
  &.active {
    border: 2px solid $primaryBrand;
  }
}
