@import "../../../styles/landing/variables.scss";

#NeedMoreInfo {
  display: flex;
  padding: 32px 60px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  background: $gray300;
  backdrop-filter: blur(6.5px);
  .textContainer {
    h1 {
      color: $primarySurfance;
      text-align: center;

      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    p {
      color: #ebeaef;
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.32px;
    }
  }
  .submit {
    display: flex;
    align-items: center;
    background: $primaryBrand;
    border-radius: $borderRadius;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 16px 32px;
    color: $primarySurfance;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    user-select: none;
    // white-space: nowrap;

    &:hover {
      background-color: $green600;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #NeedMoreInfo {
    .textContainer {
      h1 {
        font-size: 28px;
      }
    }
  }
}
