@import "./../../../../styles/dashboard/variables.scss";

#AlreadyPaidMoneyField {
  vertical-align: middle;
  padding: 4px 10px;
  #DButton button {
    background-color: transparent;
    color: $secondary300;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid $secondary300;
    padding: 4px 8px;
    min-height: auto;
    width: 100%;
    .rightIconButton {
      margin-left: 6px;
      svg {
        font-size: 14px;
      }
    }
  }
  .AlreadyPaidMoneyFieldContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: $primaryBrand;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      white-space: nowrap;
      margin-bottom: 4px;
    }
    p {
      white-space: nowrap;
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
    #DIconButton {
      border-radius: $borderRadius;
      border: 2px solid $secondary300;
      svg {
        font-size: 14px;
      }
    }
  }
}
