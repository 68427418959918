@import "./../../../../styles/landing/variables.scss";

#ForWorkflowShipper.ForWorkflowShipper {
  padding: 48px 0;
  overflow: hidden;

  .container {
    display: grid;
    grid-template-columns: 1fr 540px;
    gap: 80px;
    margin: 32px 0;
    .items {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      justify-content: center;
    }
    .coverResponsive {
      display: none;

      align-items: center;
      justify-content: center;
      width: 100%;
      height: 300px;
      background-color: $imageBG;
      border-radius: $borderRadiusLarge;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .cover {
      width: 100%;
      height: 540px;
      position: relative;
      .background {
        position: absolute;
        padding: 8px;
        background-color: $primarySurfance;
        border-radius: $borderRadiusLarge;
        .img {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 360px;
          width: 360px;
          border-radius: $borderRadiusLarge;
          background-color: $imageBG;
        }
        &.left {
          bottom: 0;
          left: 0;
          z-index: 2;
        }
        &.right {
          z-index: 1;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ForWorkflowShipper.ForWorkflowShipper {
    .container {
      grid-template-columns: 1fr;
      gap: 40px;
      .items {
        gap: 12px;
      }
      .cover {
        display: none;
        grid-row: 1;
        height: 300px;
        position: relative;
        height: 300px;

        .background {
          width: 100%;
          .img {
            height: 300px;
            width: 100%;
          }
          &.right {
            display: none;
          }
        }
      }
      .coverResponsive {
        grid-row: 1;
        display: flex;
      }
    }
  }
}
