@import "./../../../../styles/landing/variables.scss";

#ContactCarrier.ContactCarrier {
  background-color: $secondarySurface;
  padding: 48px 0;

  .container {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
