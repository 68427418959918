@import "./../../../../styles/dashboard/variables.scss";

#ShipperTrackingSectionDrivers {
  width: 100%;
  display: flex;
  padding: 4px 0px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: $white;

  .item {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-right: 1px solid $secondary50;

    &:last-child {
      border-right: none;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
    .cover {
      width: 24px;
      height: 20px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .content {
      display: flex;
      flex-direction: column;
      h5 {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
      }
      h6 {
        color: $secondary300;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
      }
      ul {
        display: flex;
        align-items: center;
        gap: 4px;
        li {
          border: 1px solid $primary100;
          padding: 4px;
          border-radius: $borderRadius;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperTrackingSectionDrivers {
    flex-direction: column;
    padding: 0 4px;

    .item {
      padding: 8px;
      border-right: none;
      border-bottom: 1px solid $secondary50;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
