@import "../../../../styles/landing/variables.scss";

#FooterTitle.FooterTitle {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    color: $primarySurfance;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 4px;
    border-bottom: 1px solid $gray200;
  }
  .icon {
    display: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    svg {
      color: $primarySurfance;
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #FooterTitle.FooterTitle {
    cursor: pointer;
    h3 {
      font-size: 20px;
      padding-bottom: 0px;
      border-bottom: none;
    }
    .icon {
      display: flex;
    }
  }
}
