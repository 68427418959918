@import "../../../../styles/dashboard/variables.scss";

#PreferencesModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .cover {
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    overflow: hidden;
    width: 100%;
  }
  .formItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    .label {
      display: flex;
      align-items: center;
      gap: 12px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: $secondary100;
        }
      }
      .title {
        color: $secondary500;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .select {
      #DSelect {
        min-width: 200px;
      }
    }
    .trailerTypesList {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
    }
  }
}
