@import "../dashboard//variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("./../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("./../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
  src: url("./../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
}

@font-face {
  font-family: Arial;
  src: url("./../../../public/assets/fonts/Arial/bpgArial.eot");
  src: url("./../../../public/assets/fonts/Arial/BPGArial.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("./../../../public/assets/fonts/Montserrat/Montserrat.woff");
  src: url("./../../../public/assets/fonts/Montserrat/Montserrat.woff2");
  src: url("./../../../public/assets/fonts/Montserrat/Montserrat.ttf");
}

@font-face {
  font-family: Fira Sans;
  src: url("./../../../public/assets/fonts/FiraSans/FiraSans.ttf");
  src: url("./../../../public/assets/fonts/FiraSans/FiraSans.woff");
  src: url("./../../../public/assets/fonts/FiraSans/FiraSans.woff2");
}
#C-Layout,
#S-Layout,
textarea,
.ReactModal__Content,
.StatusFieldContent,
input {
  &:lang(en) {
    font-family: "SourceSansPro";
    -webkit-font-smoothing: antialiased;
  }
  &:lang(ka) {
    font-family: "Arial";
    font-family: "Fira Sans";
    // font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  &:lang(ru) {
    font-family: "SourceSansPro";
    -webkit-font-smoothing: antialiased;
  }
}

#C-Layout {
  display: grid;
  transition: all 0.3s ease-in-out;
  &.max {
    grid-template-columns: $sidebarMax 1fr;
  }
  &.min {
    grid-template-columns: $sidebarMin 1fr;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #C-Layout {
    &.max {
      grid-template-columns: 1fr;
    }
    &.min {
      grid-template-columns: 1fr;
    }
  }
}
