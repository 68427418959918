@import "./../../../styles/dashboard/variables.scss";
#ShipperCards {
  padding: 16px;
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: $secondary0;
  overflow: hidden;
  .skeleton {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #ShipperCards {
    padding: 12px;
    .skeleton {
      height: 350px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperCards {
    .skeleton {
      height: 750px;
    }
  }
}
