@import "./../../../../../styles/dashboard/variables.scss";
#ByMode {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-top: 12px;
  min-height: 96px;
  .ByModeItem {
    padding: 8px 12px;
    border-radius: $borderRadiusLarge;
    background-color: $secondary25;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      svg {
        font-size: 24px;
        color: $secondary200;
      }
    }
    h3 {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
    }
    .desc {
      display: flex;
      gap: 8px;
      h5 {
        color: $success;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }
      .line {
        height: 100%;
        width: 1px;
        background: $secondary75;
      }
      h4 {
        color: $success;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
    }
    &:first-child {
      background-color: $successBG;
      .icon {
        svg {
          color: $success;
        }
      }
      .desc {
        color: $success;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #ByMode {
    gap: 6px;
    .ByModeItem {
      padding: 4px 6px;
      .desc {
        h5 {
          font-size: 16px;
        }
        h4 {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ByMode {
    min-height: 324px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
