@import "./../../../styles/dashboard/variables.scss";

#ConnectCompanyModal {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 210px;
  .ConnectCompanyModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    #DInputs {
      .DInputsIcon {
        background-color: transparent;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ConnectCompanyModal {
    .ConnectCompanyModalGroup {
      width: 100%;
    }
  }
}
