@import "../../../../../styles/dashboard/variables.scss";

#CreateOrderOversizedSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  #CreateOrderSectionSecondaryTitle {
    margin: 0;
  }

  .oversizedGroupContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    .oversizedGroupContainerFile {
      width: 100%;
      max-width: 750px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .orContainer {
      position: relative;
      height: 85px;
      width: 1px;
      background-color: $secondary50;
      .or {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 15px;
        color: $secondary100;
        height: 24px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .requestCallContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      .title {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .requestedContainer {
        display: inline-flex;
        align-items: center;
        align-self: center;
        gap: 12px;
        .requestedContainerLeft {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: $primary500;
              font-size: 24px;
            }
          }
          .text {
            color: $primary500;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
          }
        }
        .requestedContainerRight {
          .undoButton {
            color: $info;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CreateOrderOversizedSection {
    max-width: 750px;

    .oversizedGroupContainer {
      height: auto;
      width: 100%;
      flex-direction: column;
      .orContainer {
        height: 1px;
        width: 100%;
        .or {
          height: auto;
          width: 24px;
        }
      }
    }
  }
}
