@import "../../../../../styles/dashboard/variables.scss";

#LoadingStatusOrderField {
  vertical-align: middle;
  padding: 12px 10px;
  .LoadingStatusOrderFieldcontent {
    min-width: 80px;
    height: 24px;
    background-color: $secondary50;
    border-radius: 40px;
  }
}
