@import "./../../../../styles/dashboard/variables.scss";

#DShowButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  transition: all 0.3s ease-in-out;
  svg {
    color: $info;
    font-size: 18px;
  }
}
