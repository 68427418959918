@import "./../../../../styles/dashboard/variables.scss";

#TableHeader {
  width: 100%;
  background-color: $primary50;
  border-bottom: 1px solid $secondary50;
  border-left: 4px solid $primary50;

  th {
    padding: 12px 10px;
    color: $secondary500;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: start;
    white-space: nowrap;
    vertical-align: middle;
  }
}
