@import "./../../../../styles/landing/variables.scss";

#Service {
  overflow: hidden;

  .list {
    margin-top: 14px;
    height: auto;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #Service {
    .list {
      transition: all 0.5s ease-in-out;

      margin-top: 0px;
      height: 0;
    }
  }
  #Service.open {
    .list {
      height: auto;
    }
  }
}
