@import "./../../../../../styles/dashboard/variables.scss";

#RoadLocationShipper {
  width: 100%;
  padding: $pageWrapper;
  border-radius: $borderRadiusLarge;
  background: $white;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .RoadLocationShipperWrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #RoadLocationShipper {
    .RoadLocationShipperWrapper {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #RoadLocationShipper {
    padding: $pageWrapperResponsive;
    gap: 12px;
  }
}
