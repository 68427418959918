@import "../../../../styles/dashboard/variables.scss";

#MobileHeader {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  background-color: $secondary0;
  z-index: 10;
  height: $mobileHeaderHeight;
  transition: all 0.3s ease-in-out;
  padding: $pageWrapperResponsive;
  box-shadow: 0px 2px 4px 0px rgba(50, 49, 94, 0.1);
  .MobileHeaderButtons {
    display: flex;
    align-items: center;
    gap: 8px;
    .NotificationsIconBadge {
      right: 60px;
      top: 31px;
    }
  }
  .icon {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: $secondary400;

    background-color: #f4f6f9;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.open {
      background-color: #2b353a;
      svg {
        font-size: 20px;
        color: #ffffff;
      }
    }
    svg {
      font-size: 20px;
      color: #1a2023;
    }
  }
}

#MobileMenu {
  padding: $pageWrapperResponsive;
  display: none;
  width: 100%;
  height: calc(100vh - $mobileHeaderHeight);
  z-index: 1000;
  position: fixed;
  left: -100%;
  top: $mobileHeaderHeight;
  transition: all 0.3s ease-in-out;

  background-color: $secondary0;
  flex-direction: column;
  justify-content: flex-start;
  #Nav {
    height: auto;
  }
  &.open {
    left: 0;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #MobileHeader {
    display: flex;
  }

  #MobileMenu {
    display: flex;
  }
}
