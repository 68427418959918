@import "./../../../../../styles/dashboard/variables.scss";

#BoardsList {
  padding: $pageWrapper;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  .sortContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    .leftContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      .result {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: $secondary200;
      }
    }
    .rightContainer {
      display: flex;
      gap: 12px;
      .sortButton {
        width: 230px;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  #BoardsList {
    .sortContainer {
      .rightContainer {
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #BoardsList {
    .sortContainer {
      flex-direction: column-reverse;
      align-items: flex-end;
      .leftContainer {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        .result {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
      .rightContainer {
        width: 100%;
        align-items: center;
        margin-bottom: 8px;
        .favRoutesButton {
          width: 100%;
        }
        .sortButton {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #BoardsList {
    padding: $pageWrapperResponsive;
  }
}
