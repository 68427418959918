@import "../../../styles/landing/variables.scss";

#Warning.Warning {
  cursor: pointer;
  position: relative;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $yellowLight;
  color: $yellowDark;
  border-radius: 50%;
  font-size: 12px;

  .WarningContent {
    display: none;
    width: 190px;
    top: 120%;
    right: 50%;
    transform: translateX(50%);
    position: absolute;
    z-index: 200;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    color: $yellowDark;
    background-color: $yellowLight;
    border-radius: $borderRadius;
    transition: all 0.3s ease-in-out;
  }

  &:hover .WarningContent {
    display: block;
  }
}
