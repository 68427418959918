@import "../../../../../../styles/dashboard/variables.scss";

#BankRequisitesFormItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .orContainer {
    width: 100%;
    height: 1px;
    background-color: $secondary50;
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;

    .or {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // z-index: 1;
      font-size: 15px;
      color: $secondary100;
      width: 50px;
      height: 15px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .bankRequisites {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .remove {
      .icon {
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 18px;
          color: $error;
        }
      }
    }
  }
}
