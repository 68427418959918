@import "./../../../../../styles/dashboard/variables.scss";

#InfoWindowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;

  .truck {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .cover {
      width: 35px;
      height: 30px;
      background-position: center;
      background-size: contain;
      position: relative;
      background-repeat: no-repeat;
    }
    .name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h2 {
        color: $secondary300;
        font-size: 14px;
        font-weight: 600;
      }
      h3 {
        color: $secondary200;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
