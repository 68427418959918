@import "./../../../../styles/landing/variables.scss";

#SignUpShipperSuccessForm.SignUpShipperSuccessForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  #FormTitle {
    h1,
    p {
      text-align: center;
    }
  }
}
