@import "./../../../styles/landing/variables.scss";

#input {
  width: 100%;
  display: flex;
  flex-direction: column;

  .group {
    position: relative;
    height: 44px;
    width: 100%;

    input {
      background-color: $primarySurfance;
      width: 100%;
      height: 44px;
      padding: 10px 12px;
      border: 1px solid $secondaryBorder;
      border-radius: $borderRadius;
      outline: none;
      transition: all 0.3s ease-in-out;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      user-select: none;
      color: $primaryContent;

      &::placeholder {
        color: $tetriarySurface;
      }

      &:focus {
        border-color: $primaryBorder;
        &::placeholder {
          color: $gray100;
        }
      }
      &:hover {
        border-color: $primaryBorder;
      }
    }

    .showPassIcon {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;
      color: $secondaryContent;
      font-size: 22px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

#input.error {
  input {
    border: 1px solid red;
  }
}
