@import "./../../../styles/landing/variables.scss";

#Button {
  width: 100%;
  button {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    outline: none;
    cursor: pointer;
    padding: 0px 24px;
    border-radius: $borderRadius;
    border: 1px solid $primaryBrand;
    background-color: $primaryBrand;
    color: $primarySurfance;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    text-align: center;
    &:hover {
      background-color: $green600;
      border-color: $green600;
    }
    .buttonIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      svg {
        font-size: 16px;
      }

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }
    }
  }
}
