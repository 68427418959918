@import "./../../../../styles/dashboard/variables.scss";

#LoadBoardMakeBidModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .MakeBidModalGroup {
    min-height: 200px;
    padding-bottom: 40px;
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #LoadBoardMakeBidModal {
    .MakeBidModalGroup {
      width: 100%;
    }
  }
}
