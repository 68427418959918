@import "./../../../styles/landing/variables.scss";
#Form.Form {
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid $gray50;
    // box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    //   0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;
    border-radius: $borderRadiusLarge;
    align-items: flex-start;
    h1 {
      color: $primaryContent;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
    }
    .inputContainer,
    .convertContainer {
      width: 60%;
    }
    .textAreaContainer {
      width: 100%;
    }
    .checkBoxContainer {
      display: flex;
      flex-direction: column;

      .checkBoxContainerItem {
        display: flex;
        gap: 12px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondaryBorder;
        }
        .icon.checked {
          color: $primaryBrand;
        }
      }
    }

    .buttonContainer {
      display: flex;
      #Button {
        display: inline-flex;
        width: auto;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #Form.Form {
    .formContainer {
      // gap: 16px;
      h1 {
        font-size: 28px;
      }
      .inputContainer,
      .convertContainer {
        width: 100%;
      }
    }
  }
}
