@import "./../../../../styles/dashboard/variables.scss";

#FilledPartnerCarrier {
  padding: 4px 8px;
  box-shadow: 0px 17.5px 70px 0px rgba(0, 0, 0, 0.12);
  background: $secondary0;
  border: 1px solid $secondary50;
  border-radius: $borderRadiusLarge;
  display: grid;
  grid-template-columns: 1fr 1fr 0.6fr;
  overflow: hidden;

  .FilledPartnerCarrierItem {
    overflow: hidden;
    margin: 4px;
    border-right: 1px solid $secondary50;
    display: flex;
    gap: 5px;
    align-items: center;
    &:last-child {
      border-right: none;
    }

    .leftSide {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .editable {
      #DIconButton {
        svg {
          font-size: 14px;
        }
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 16px;
        color: $secondary200;
      }
    }
    .cover {
      width: 24px;
      height: 20px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      white-space: nowrap;
      h3 {
        color: $secondary300;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
      p {
        color: $secondary300;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  #DButton button {
    border: 2px solid $secondary500;
    background-color: transparent;
    color: $secondary500;
    min-height: 30px;
    font-size: 14px;
  }
}

@media screen and (max-width: $mediaOrigin) {
  #FilledPartnerCarrier {
    width: 60%;
    grid-template-columns: 1fr;
    .FilledPartnerCarrierItem {
      padding: 8px;
      border-right: none;
      border-bottom: 1px solid $secondary50;
    }
  }
}

@media screen and (max-width: $mediaSmall) {
  #FilledPartnerCarrier {
    width: 100%;
  }
}
