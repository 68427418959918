@import "./../../../../../../styles/dashboard/variables.scss";

#TrailerMaintenanceTable {
  width: 100%;
  overflow: hidden;
  .tableWrapper {
    overflow: auto;
    height: 200px;
    width: 100%;
    table {
      border-collapse: collapse;
      width: 100%;
    }
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #TrailerMaintenanceTable {
    .tableWrapper {
      width: calc($fullWidthResponsive - 32px);
    }
  }
}
