@import "../../../../styles/landing/variables.scss";

#AuthFormWrapper {
  background-color: $primarySurfance;
  width: 100%;

  padding: 12px 90px;
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .animation {
    width: 100%;
    .AuthFormWrapperContent {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #AuthFormWrapper {
    .animation {
      max-width: 500px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #AuthFormWrapper {
    padding: 30px;
    .animation {
      max-width: 400px;
    }
  }
}
