@import "../../../../styles/landing/variables.scss";

#NavItem {
  align-items: center;
  user-select: none;
  a {
    width: 100%;
    position: relative;
    p {
      color: $primaryContent;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    .line {
      position: absolute;
      bottom: -8px;
      height: 3px;
      width: 0%;
      background-color: $primaryBorder;
      border-radius: 2px;
      transition: all 0.3s ease-in-out;
    }
    .arrow {
      display: none;
    }
    &:hover,
    &.active {
      .line {
        width: 100%;
      }
    }
  }
}
