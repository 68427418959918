@import "./../../../../../../styles/dashboard/variables.scss";

#BidResponseField {
  vertical-align: middle;
  padding: 12px 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  .BidResponseFieldItem {
    text-align: center;
    padding: 4px 8px;
    width: auto;
    color: $success;
    border-radius: $borderRadius;
    background: $successBG;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    &.refused {
      background: $errorBG;
      color: $error;
    }
  }
  .noBidResponse {
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: $secondary100;
    font-size: 13px;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
  }
  .notemail {
    display: flex;
    align-items: center;
    gap: 4px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $warning;
      }
    }
    p {
      color: $warning;
      font-size: 14px;
    }
  }
  .OfferSend {
    display: flex;
    align-items: center;
    gap: 4px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $primary700;
      }
    }
    p {
      color: $primary700;
      font-size: 14px;
    }
  }
}
