@import "./../../../../styles/dashboard/variables.scss";

#ProgressFieldBar {
  vertical-align: middle;
  padding: 4px 10px;

  .ProgressFieldBarWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    p {
      color: $secondary200;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .progressContainer {
      overflow: hidden;
      width: 120px;
      height: 8px;
      border-radius: 8px;
      background-color: $secondary50;

      .color {
        width: 100%;
        height: 100%;
        background-color: $primary700;
      }
    }
  }
}
