@import "./../../../../styles/landing/variables.scss";

#DropdownHeader {
  position: relative;
  .button {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 12px 0px;

    p {
      color: $primaryContent;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      text-align: center;
    }
    .arrowDown {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $primaryContent;
        font-size: 24px;
      }
    }
  }
  .list {
    display: none;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    background-color: $primarySurfance;
    border-radius: $borderRadius;
    overflow: hidden;
    min-width: 100%;

    .item {
      a {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        width: 100%;
        height: 100%;
        background-color: $primarySurfance;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;
        p {
          color: $secondaryContent;
          font-size: 18px;
          font-weight: 500;
          transition: all 0.3s ease-in-out;
          text-align: center;
        }

        &:hover,
        &.active {
          background-color: $green100;
        }
      }
    }
  }

  &:hover {
    .list {
      display: block;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #DropdownHeader {
    display: none;
  }
}
