@import "../../../../../styles/dashboard/variables.scss";

#ContainerTracking {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  border: 1px solid $secondary50;

  &::-webkit-scrollbar {
    height: 8px;
  }
  .containerInfoContainer {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $secondary50;
    background: $secondary25;
    .containerInfo {
      display: flex;
      align-items: center;
      gap: 24px;
      .containerType {
        color: $secondary200;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .separator {
        width: 1px;
        height: 22px;
        background: $secondary75;
      }
      .destinationsContainer {
        display: flex;
        align-items: center;
        gap: 16px;
        .destination {
          color: $secondary200;

          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary200;
          }
        }
      }
    }
    .lastUpdateContainer {
      display: flex;
      align-items: center;
      gap: 4px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary200;
        }
      }
      p {
        color: #4a5056;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .trackingInfoContainer {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 48px;
    .trackingInfoItem {
      display: flex;
      align-items: center;
      gap: 12px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary200;
        }
      }
      .rightContainer {
        .title {
          color: $secondary200;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
        .value {
          color: $secondary200;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
  .noData {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

@media screen and (max-width: $mediaSmall) {
  #ContainerTracking {
    .containerInfoContainer {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .containerInfo {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
      }
      .separator {
        display: none;
      }
    }
    .trackingInfoContainer {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
    }
  }
}
