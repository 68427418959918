@import "../../../../styles/landing/variables.scss";

#HomeTitle.HomeTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  .title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 70%;
    h1 {
      color: $primaryContent;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;
      span {
        color: $primaryBrand;
      }

      .module_blinkingCursor__yugAC,
      .styles-module_blinking__9VXRT {
        color: $primaryBrand !important;
        font-size: 24px;
        font-weight: 200;
      }
    }
    p {
      color: $secondaryContent;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.32px;
    }

    .animatedTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .static-text {
        display: inline-block;
        margin-right: 10px;
      }
      .dynamic-text {
        display: inline-block;

        overflow: hidden;
        height: 40px;
        h1 {
          color: $primaryBrand;
          text-align: start;
        }
        .item {
          position: relative;
          top: 0;
          animation: moveTextanimation 5s infinite 1s;
        }
      }
    }

    // For a more dynamic approach is recommended to do this part with Js
    .typewritter {
      display: none;
    }
  }
}
@keyframes moveTextanimation {
  0% {
    top: 0;
  }
  40% {
    top: -100%;
  }
  80% {
    top: -200%;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #HomeTitle.HomeTitle {
    .title {
      width: 100%;
      h1 {
        font-size: 28px;
        font-weight: 800;
      }
      .animatedTitle {
        display: none;
      }
      .typewritter {
        display: block;
        height: 65px;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #HomeTitle.HomeTitle {
    .title {
      h1 {
        line-height: 30px;
      }
    }
  }
}
