@import "./../../../../../styles/landing/variables.scss";

#LinkBackground {
  display: flex;
  justify-content: center;
  user-select: none;
  a {
    text-align: center;
    background: $primaryBrand;
    border-radius: $borderRadius;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
    color: $primarySurfance;
    padding: 12px 30px;
    &:hover {
      background-color: $green600;
    }
  }
}
