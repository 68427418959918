@import "../../../../styles/dashboard/variables.scss";

#CustomersListsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

@media only screen and (max-width: $mediaSmall) {
  #CustomersListsHeader {
    flex-direction: column-reverse;
  }
}
