@import "../../../../../styles/dashboard/variables.scss";

#PaymentModal {
  width: 600px;
  background-color: $white;
  border-radius: $borderRadiusLarge;
  overflow: hidden;

  .header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .titleIcon {
        display: flex;
        svg {
          font-size: 24px;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .content {
    width: 100%;
    min-height: 120px;
    padding: 16px 24px;
    background-color: $white;
    border-bottom: 1px solid $secondary50;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .contentOrder {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid $secondary50;
      padding: 16px;
      border-radius: 8px;
      gap: 16px;
      .contentOrderPrices {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid $secondary50;
        padding-bottom: 16px;
        .contentOrderPricesLeft {
          display: flex;
          gap: 48px;
          .contentOrderPricesLeftItem {
            display: flex;
            flex-direction: column;
            gap: 4px;
            h3 {
              color: $secondary100;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
            }
            h2 {
              color: $secondary300;
              font-size: 23px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0em;
              white-space: nowrap;
            }
          }
        }
        .contentOrderPricesRight {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 4px;
          h3 {
            color: $secondary100;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
          }
          h2 {
            color: $primary500;
            font-size: 23px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            white-space: nowrap;
          }
        }
      }
      .contentOrderAdditionalCostsContainer {
        display: flex;
        justify-content: flex-start;
        .contentOrderAdditionalCosts {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 8px;
          cursor: pointer;
          .contentOrderAdditionalCostsText {
            color: $secondary100;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
          }
          svg {
            font-size: 16px;
            color: $secondary100;
          }
          &.open {
            background-color: $secondary300;
            border-radius: 4px;
            svg {
              color: $secondary0;
            }
            .contentOrderAdditionalCostsText {
              color: $secondary0;
            }
          }
        }
      }
      .contentOrderAdditionalCostsContent {
        width: 100%;
        max-height: 120px;
        min-height: 30px;
        overflow-y: auto;
        .contentOrderAdditionalCostsContentItem {
          display: grid;
          grid-template-columns: 2fr 2fr 1fr;
          align-items: center;
          gap: 8px;
          .contentOrderAdditionalCostsContentItemName {
            color: $secondary200;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            white-space: nowrap;
          }
          .contentOrderAdditionalCostsContentItemComment {
            color: $secondary100;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
          }
          .contentOrderAdditionalCostsContentItemPrice {
            text-align: end;
            color: $primary500;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            white-space: nowrap;
          }
        }
      }
      .noData {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $secondary100;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
      }
    }
    .contentPayment {
      width: 100%;
      padding: 12px 16px;
      border: 1px solid $secondary50;
      border-radius: 8px;
      .contentPaymentContainer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        h3 {
          color: $secondary300;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          display: flex;
          align-items: center;
          gap: 4px;

          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #PaymentModal {
    width: 90vw;
    .header {
      padding: 12px 16px;
      gap: 4px;
      .title {
        gap: 4px;
      }
    }
    .content {
      padding: 12px 16px;
      gap: 4px;
      .contentOrder {
        .contentOrderAdditionalCostsContent {
          .contentOrderAdditionalCostsContentItem {
            grid-template-columns: 1fr;
            gap: 0px;
            margin-bottom: 10px;
            .contentOrderAdditionalCostsContentItemPrice {
              text-align: left;
            }
          }
        }
        .contentOrderPrices {
          flex-direction: column;
          align-items: baseline;
          .contentOrderPricesLeft {
            flex-direction: column;
            gap: 8px;
          }
          .contentOrderPricesRight {
            align-items: baseline;
          }
        }
      }
    }
  }
}
