@import "./../../../../styles/dashboard/variables.scss";

#ActiveOrdersListViewHeader {
  width: 100%;

  .filterKeywordContainer {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .filterSelectsGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid $secondary50;
    border-bottom: 1px solid $secondary50;

    .leftSide {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .filterBy {
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
      }

      .groups {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        .filterPopupBtn {
          border: 1px solid $secondary25;
          color: $secondary300;
          background-color: $secondary25;
          display: inline-flex;
          align-items: center;
          flex-wrap: nowrap;
          gap: 8px;
          padding: 8px 12px;
          min-height: 40px;
          border-radius: $borderRadiusLarge;
          cursor: pointer;

          display: none;

          h6 {
            color: $secondary300;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 16px;
              color: $secondary300;
            }
          }
        }
      }
    }

    .clearButton {
      padding-top: 8px;
      #DTextButton {
        button {
          white-space: nowrap;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: $info;
        }
      }
    }
  }

  .filterClipsContainer {
    display: flex;
    gap: 24px;
    align-items: center;

    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .filterClipsContainerline {
      height: 24px;
      width: 1px;
      background-color: $secondary75;
    }
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #ActiveOrdersListViewHeader {
    .filterSelectsGroup {
      .leftSide {
        .filterBy {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ActiveOrdersListViewHeader {
    .filterSelectsGroup {
      .leftSide {
        .groups {
          .groupItem {
            display: none;
          }
          .filterPopupBtn {
            display: inline-flex;
          }
        }
      }
    }
  }
}
