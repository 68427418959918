@import "./../../../../../../styles/dashboard/variables.scss";

#AddOrderTrailerManagement {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .addNewTrailersListButton {
    #DButton {
      button {
        background-color: white;
        color: $primary500;
      }
    }
  }
}
