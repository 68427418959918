@import "./../../../../styles/dashboard/variables.scss";

#SelectDriverModal {
  background-color: $secondary0;
  height: 80vh;
  width: 80vw;
  border-radius: 12px;
  padding: 24px;
  overflow: hidden;

  .SelectDriverModalcontent {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 5px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    .SelectDriverModalTableHeader {
      margin: 12px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      .SelectDriverModalTableHeaderFilters {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      #DTextButton button {
        height: 100%;
        border: 2px solid $secondary500;
        padding: 4px 8px;
        border-radius: $borderRadius;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #SelectDriverModal {
    width: 90vw;
    padding: 12px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #SelectDriverModal {
    .SelectDriverModalcontent {
      .SelectDriverModalTableHeader {
        width: 100%;
        flex-direction: column-reverse;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #SelectDriverModal {
    .SelectDriverModalcontent {
      .SelectDriverModalTableHeader {
        .SelectDriverModalTableHeaderFilters {
          width: 100%;
          flex-direction: column;
        }
      }
    }
  }
}
