@import "./../../../../styles/dashboard/variables.scss";

#DriverPhoneField {
  vertical-align: middle;
  padding: 4px 10px;
  .DriverPhoneFieldUser {
    display: flex;
    align-items: center;
    gap: 4px;
    .DriverPhoneFieldIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }

    .DriverPhoneFieldInfo {
      h4 {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        white-space: nowrap;
        font-weight: 600;
        line-height: 15px;
      }
      h5 {
        color: $secondary300;
        font-size: 11px;
        font-style: normal;
        white-space: nowrap;
        font-weight: 400;
        line-height: 11px;
      }
    }
  }
  .DriverPhoneFieldNoUser {
    display: flex;
    align-items: center;
    gap: 4px;
    .DriverPhoneFieldIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: #b95000;
      }
    }
    p {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
