@import "../../../styles/dashboard/variables.scss";

#DInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  .group {
    position: relative;
    width: 100%;
    input {
      background-color: $white;
      width: 100%;
      height: 40px;
      padding: 10px 12px;
      border: 1px solid $secondary100;
      border-radius: $borderRadius;
      outline: none;
      transition: all 0.3s ease-in-out;

      font-size: 16px;
      font-style: normal;

      font-family: inherit;
      font-weight: 400;
      user-select: none;
      color: black;

      &::placeholder {
        font-weight: 400;
        color: $secondary75;
      }

      &:focus {
        &::placeholder {
          color: $secondary50;
        }
      }
    }

    .DInputsIcon {
      position: absolute;
      top: 11px;
      right: 12px;
      color: $secondary100;
      background-color: transparent;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

#DInputs.error {
  input {
    border: 1px solid $error;
  }
  .DInputsIcon {
    color: $error;
  }
}
