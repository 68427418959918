@import "../../../styles/dashboard/variables.scss";

#AdditionalInformationDetails {
  width: 100%;
  margin-top: 24px;
  .AdditionalInformationDetailsTitle {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin-bottom: 4px;
    user-select: none;
    .text {
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary300;

      svg {
        font-size: 24px;
      }
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    .comment {
      color: $secondary100;

      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .otherInformation {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        .itemTitle {
          color: $secondary400;
          font-size: 13px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px;
        }
        .itemValue {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .servicesValuesContainer {
          display: flex;
          align-items: center;
          gap: 8px;
          flex-wrap: wrap;
          .servicesValue {
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: 8px;
            border-radius: 26px;
            background: $primary50;
            border: 1px solid $primary500;
            p {
              color: $secondary200;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                font-size: 16px;
                color: $secondary200;
              }
            }
          }
        }
        .documentsValuesContainer {
          display: flex;
          align-items: center;
          gap: 8px;
          flex-wrap: wrap;
          .documentContainer {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid $secondary50;
            background: $secondary25;
            .documentContent {
              display: flex;
              align-items: center;
              gap: 8px;
              .documentIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  font-size: 24px;
                  color: $secondary200;
                }
              }
              .documentTitle {
                color: $secondary200;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
