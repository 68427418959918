@import "./../../../../../styles/landing/variables.scss";

#ContactFreightCover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  border-radius: $borderRadiusLarge;
  overflow: hidden;
  background-color: $imageBG;
}

@media only screen and (max-width: $mediaLarge) {
  #ContactFreightCover {
    height: 300px;
  }
}
