@import "./../../../../../../styles/dashboard/variables.scss";

#AccountantEmailCard {
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 24px;
      color: $secondary100;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    h4 {
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    h5 {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .Moreicon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 18px;
      color: $secondary100;
    }
  }
}
