@import "../../../../styles/dashboard/variables.scss";

#tableNoInformation {
  width: 100%;

  h2 {
    min-height: 40px;
    width: 100%;
    color: $secondary100; /* Example: Text color for description */
    text-align: "center";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-bottom: 1px solid $secondary50;
  }
}
