@import "../../../../styles/dashboard/variables.scss";

#AddressDetails2 {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    background-color: $primary100;
    padding: 12px 24px;
    gap: 10px;
    justify-content: space-between;
    border-radius: $borderRadius;
    .titleContainerLeft {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 22px;
          color: $secondary200;
        }
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        h1 {
          color: $secondary500;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
    .titleContainerRight {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }

  .items {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    &::after {
      content: "";
      position: absolute;
      top: 20px;
      left: 11.5px;
      height: calc(100% - 20px);
      z-index: 0;
      border-left: 2px dashed $secondary100;
    }
    .title {
      padding-left: 32px;
      display: flex;
      gap: 6px;
      align-items: center;
      .timeContainer {
        display: flex;
        align-items: center;
        gap: 6px;
        .timeCont,
        .dateCont {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon {
            color: $secondary100;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .time {
            color: $secondary300;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
          }
        }
      }
      h3 {
        color: $secondary500;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
    }
    .AddressDetailsItem2 {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;
      align-items: flex-end;

      .content {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        z-index: 1;
        width: 24px;
        height: 24px;
        background-color: $secondary0;
        color: $secondary200;
        svg {
          font-size: 24px;
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddressDetails2 {
    min-width: auto;
    gap: 12px;
    .titleContainer {
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .title {
        font-size: 20px;
      }
    }
  }
}
