@import "./../../../../styles/dashboard/variables.scss";

#ConnectMobileHeader {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  .ConnectMobileFilters {
    display: flex;
    gap: 12px;
    align-items: center;
    .item {
      cursor: pointer;
      border-radius: 24px;
      color: $secondary100;
      background-color: $secondary0;
      font-size: 14px;
      font-style: normal;
      padding: 4px 12px;
      border: 1px solid $secondary100;

      font-weight: 600;
      line-height: 20px;

      &.active {
        color: $secondary0;
        border: 1px solid $primaryBrand;
        background-color: $primaryBrand;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ConnectMobileHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
