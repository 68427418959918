@import "../../../../styles/dashboard/variables.scss";

#profileInfo {
  display: flex;
  flex-direction: column;
  margin: 12px 24px 0 164px;
  width: 100%;
  .userNameAndTypeContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    .userName {
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 24px;
    }
    .userType {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      border-radius: 4px;
      background: $successBG;
      color: $success;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .userInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    .userInfoItems {
      height: 36px;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;
    }
    .userInfoEdit {
      button {
        color: $info;
      }
    }
  }
}
#editUserProfileModal {
  display: flex;
  justify-content: center;
  .fromGorup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  #profileInfo {
    .userInfo {
      .userInfoItems {
        padding: 4px 0;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #profileInfo {
    margin: 60px 0 0 0;
    justify-content: center;
    align-items: center;
    .userInfo {
      width: 100%;
      padding: 24px;
      justify-content: space-between;
      .userInfoItems {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 8px;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #profileInfo {
    .userInfo {
      padding: 8px 0 0 0;
    }
  }
}
