@import "./../../../styles/dashboard/variables.scss";
#ShipperCharts {
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: $secondary0;
  .skeleton {
    width: 100%;
    height: 350px;
  }
}
