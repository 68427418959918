@import "../../../../styles/dashboard/variables.scss";

#CalendarShipper {
  padding: $pageWrapper;
  width: 100%;
  min-height: calc(100vh - $shipperHeaderHeight);
  overflow: hidden;
  background: #fff;
  .filtersContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .searchContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
    }
    .shipmentTypeFilters {
      display: flex;
      gap: 12px;
      #FilterShippingTypeItem {
        .title {
          text-transform: none;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CalendarShipper {
    padding: $pageWrapperResponsive;
    .filtersContainer {
      .searchContainer{
        flex-direction: column;
      }
    }
  }
}
