@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderProductType {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 12px;

  .ProductSelect {
    width: 100%;
  }
  .hsCodeContainer {
    width: min-content;
    height: 40px;
    margin-top: 22px;
    display: flex;
    align-items: center;

    .hiddenCode {
      display: flex;
      align-items: center;
      #DTextButton {
        button {
          color: $info;
        }
      }
    }
    .ShowCode {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .CodeInputContainer {
        min-width: 150px;
      }

      #DTextButton {
        button {
          color: $error;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderProductType {
    flex-direction: column;
    .hsCodeContainer {
      width: 100%;
      margin-top: 0px;
      .ShowCode {
        width: 100%;
        .CodeInputContainer {
          width: 100%;
        }
      }
    }
  }
}
