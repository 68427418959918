@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderAditionalInformation {
  width: 100%;
  padding: $pageWrapper;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: $borderRadiusLarge;
  background: $white;

  .selectContainers {
    width: 100%;
    max-width: 750px;
    display: flex;
    gap: 12px;
    align-items: flex-start;

    .dateContainer,
    .moneyContainer {
      width: 100%;
      max-width: 50%;
    }
  }

  .docsContainer {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .commentContainer {
    width: 100%;
    max-width: 1524px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderAditionalInformation {
    padding: $pageWrapperResponsive;
    gap: 12px;

    .selectContainers {
      flex-direction: column;

      .dateContainer,
      .moneyContainer {
        max-width: 100%;
      }
    }
  }
}
