@import "./../../../../styles/landing/variables.scss";

#Cover.Cover {
  height: $coverHeight;
  overflow: hidden;
  position: relative;
  min-height: 300px;
  video {
    background-color: $imageBG;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100%;
    opacity: 0.99;
  }
  .blar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($primaryContent, 0.2);
    .container {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .textContainer {
        margin-bottom: 50px;
        overflow: hidden;
        padding: 0px 30px;
        h2 {
          text-align: center;
        }
      }
      .submit {
        display: flex;
        align-items: center;
        background: $primaryBrand;
        border-radius: $borderRadius;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        padding: 16px 32px;
        color: $primarySurfance;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        user-select: none;

        &:hover {
          background-color: $green600;
        }
      }
    }
  }
  .partnersContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 5;
    background-color: $primaryContent;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .item {
      padding: 0 24px;
      display: flex;
      align-items: center;
      .itemTitle {
        display: flex;
        width: 100%;
        height: 100%;
        img {
          height: 100px;
          width: 140px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #Cover.Cover {
    .blar {
      .container {
        .textContainer {
          margin-bottom: 30px;
        }
      }
    }
    .partnersMainContainer {
      .item {
        padding: 12px;
        .itemTitle {
          img {
            height: 80px;
            width: 100px;
          }
        }
      }
    }
  }
}
