@import "./../../../../../../styles/dashboard/variables.scss";

#AddPaymentModal {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 200px;
  .AddPaymentModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddPaymentModal {
    .AddPaymentModalGroup {
      width: 100%;
    }
  }
}
