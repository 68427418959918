@import "../../../styles/landing/variables.scss";

#ErrorText {
  display: flex;
  align-items: center;
  // user-select: none;
  overflow: hidden;
  p {
    color: $redNormal;
    font-size: 12px;
    margin-left: 3px;
  }
}
