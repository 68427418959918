@import "../../../styles/dashboard/variables.scss";

#FilterPriceRangeForm {
  width: 250px;
  .dropdownContentContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .saveButton {
      display: flex;
      justify-content: flex-end;
    }
    #DInputs {
      .group {
        input {
          margin-bottom: 0;
        }
      }
    }
  }

  #DButton {
    &.disabled {
      button {
        border: 1px solid $secondary75;
        background-color: $secondary75;
        cursor: not-allowed;
      }
    }
    button {
      background-color: $secondary500;
      color: $secondary0;
    }
  }
}

@media only screen and (max-width: 700px) {
  #FilterPriceRangeForm {
    width: 100%;
  }
}
