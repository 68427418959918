@import "./../../../../styles/dashboard/variables.scss";

#BidePriceFieldLoadBoard {
  display: flex;
  p {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    color: $warning;
    white-space: nowrap;
  }
}

#AllLoadsTakeOrder {
  #DButton {
    button {
      min-height: 30px;
      font-size: 14px;
    }
  }
}

#AllLoadsMakeBid {
  #DButton {
    button {
      min-height: 30px;
      font-size: 14px;
      background-color: white;
      color: $secondary300;
      border: 1px solid $secondary300;
    }
  }
}
