@import "./../../../../styles/landing/variables.scss";

#Contact.Contact {
  overflow: hidden;
  .contactContainer {
    width: 100%;
    min-height: $pageMinHeight;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #Contact.Contact {
    .contactContainer {
      grid-template-columns: 1fr;

      gap: 24px;
    }
  }
}
