@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderTopMenuBackgdrop {
  height: 50px;
  width: 100%;
}

#CreateOrderTopMenu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: $primary500;
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  position: fixed;
  z-index: 10;

  .CreateOrderTopMenuContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
    overflow-x: auto;
    padding: 10px 30px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.CreateOrderTopMenuItemWrapper {
  // min-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 8px;
  // overflow: hidden;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $primary100;
      font-size: 16px;
    }
  }
  h3 {
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }
}
