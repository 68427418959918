@import "../../styles/dashboard/variables.scss";

#BidsOfferTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  .offerFormTitle {
    font-size: 23px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .offerFormText {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: $secondary100;
    span {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
    }
  }
}
