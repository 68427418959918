@import "../../../../styles/dashboard/variables.scss";

#ProductDetails2 {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .titleContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    background-color: $primary100;
    padding: 12px 24px;
    gap: 10px;
    border-radius: $borderRadius;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
    }
    .title {
      color: $secondary500;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .items {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .item {
      display: flex;
      gap: 4px;
      align-items: center;
      .itemTitle {
        color: $secondary100;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
      .itemText {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        .light {
          color: $secondary100;
        }
      }
    }
    .adr {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        .text {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondary300;
          cursor: pointer;
          svg {
            font-size: 24px;
          }
        }
      }
      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          gap: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ProductDetails2 {
    min-width: auto;
    gap: 12px;
    .titleContainer {
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .title {
        font-size: 20px;
      }
    }
    .items {
      .adr {
        .content {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
