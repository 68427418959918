@import "./../../../../../styles/dashboard/variables.scss";

#ShipperBookModal {
  background-color: $white;
  border-radius: $borderRadiusLarge;
  overflow: hidden;
  max-width: 950px;
  .header {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          transform: rotate(315deg);
          color: $secondary300;
          font-size: 24px;
        }
      }
      h2 {
        color: $secondary300;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .closeIcon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
        color: $secondary100;
      }
    }
  }

  .items {
    border-top: 1px solid $secondary50;
    padding: 48px 24px;
    gap: 32px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .BookModalItem {
      padding: 24px 4px;
      width: 200px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 22px;
      border-radius: $borderRadiusLarge;
      border: 2px solid $secondary75;
      background: $secondary25;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: $secondary200;
          font-size: 48px;
          transition: all 0.3s ease-in-out;
        }
      }

      h3 {
        color: $secondary300;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
        text-align: center;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        border: 2px solid $primary500;
        background: $primary50;

        .icon {
          svg {
            color: $primary500;
          }
        }

        h3 {
          color: $primary500;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShipperBookModal {
    .items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperBookModal {
    .header {
      padding: 12px;
      .title {
        .icon {
          svg {
            font-size: 18px;
          }
        }
        h2 {
          font-size: 18px;
        }
      }
    }
    .items {
      padding: 12px;
      gap: 12px;
      .BookModalItem {
        padding: 12px;
        width: 150px;
        gap: 12px;
        .icon {
          svg {
            font-size: 28px;
          }
        }
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}
