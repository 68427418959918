@import "./../../../../../styles/landing/variables.scss";

#AboutItem.AboutItem {
  .item {
    height: 340px;
    width: 100%;
    border-radius: $borderRadiusLarge;
    overflow: hidden;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      background-color: $imageBG;
    }

    .title {
      width: 100%;
      height: 100%;
      padding: 30px 24px;
      display: flex;
      align-items: flex-end;
      transition: all 0.3s ease-in-out;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
      opacity: 1;
      &:lang(ka),
      &:lang(ru) {
        padding: 20px 16px;
      }
    }
    h2 {
      color: $primarySurfance;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;

      &:lang(ka),
      &:lang(ru) {
        font-size: 18px;
      }
    }

    .body {
      width: 100%;
      height: 100%;
      padding: 30px 24px;
      position: absolute;
      z-index: 5;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: flex-end;
      left: 0;
      bottom: -100%;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &:lang(ka),
      &:lang(ru) {
        padding: 20px 16px;
      }

      p {
        color: $gray50;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.14px;

        &:lang(ka),
        &:lang(ru) {
          font-size: 13px;
          line-height: 13px;
        }
      }
    }

    .transparent {
      transition: all 0.3s ease-in-out;
      position: absolute;
      width: 70%;
      height: 40%;
      z-index: 2;
      left: 0;
      bottom: 0;
      opacity: 0;
      background: linear-gradient(
        180deg,
        rgba(43, 53, 58, 0.2) 0%,
        rgba(43, 53, 58, 0.8) 100%
      );
      border-radius: $borderRadiusLarge;
    }

    &:hover {
      .transparent {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      .body {
        bottom: 0;
        opacity: 1;
      }
      .title {
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #AboutItem.AboutItem {
    .item {
      height: 300px;
      h2 {
        font-size: 20px;
      }
      .transparent {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      .body {
        bottom: 0;
        opacity: 1;
        p {
          font-size: 14px;
        }
      }
      .title {
        opacity: 0;
      }
    }
  }
}
