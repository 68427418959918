@import "../../../styles/landing/variables.scss";
.ContactOffice {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  .desc {
    h1 {
      color: $primaryContent;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 24px;
    }
    h3 {
      margin-top: 24px;
      margin-bottom: 24px;
      color: $primaryContent;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      color: $secondaryContent;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
    }
    .row {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-bottom: 16px;
      .icon {
        font-size: 32px;
        color: $primaryBrand;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .line {
      display: flex;
      margin-top: 24px;
      margin-bottom: 24px;
      width: 100%;
      height: 1px;
      background-color: $disable;
    }
  }

  .card {
    margin-top: 12px;
    width: 100%;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr;
    gap: 16px;
    overflow: hidden;

    .img {
      width: 100%;
      min-height: 200px;
      background-position: center;
      background-size: cover;
      border-radius: $borderRadiusLarge;

      background-color: $imageBG;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      .title {
        color: $primaryContent;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        line-height: normal;
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-height: 200px;
        .item {
          display: grid;
          grid-template-columns: 48px 1fr;
          gap: 19px;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            background-color: $green50;
            border-radius: 50%;
            svg {
              font-size: 20px;
              color: $primaryBrand;
            }
          }

          p {
            font-style: normal;
            font-weight: 500;
            color: $primaryContent;
            font-size: 12px;
            font-style: normal;
            letter-spacing: -0.12px;
          }
          .list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            margin-top: 8px;
            a {
              color: $primaryContent;
              font-style: normal;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  .ContactOffice {
    padding: 0;
    .desc {
      h1 {
        font-size: 28px;
      }
    }
    .card {
      grid-template-columns: 1fr;
    }
  }
}
