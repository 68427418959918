@import "./../../../../styles/dashboard/variables.scss";

#AddExpensesModal {
  width: 100%;
  display: flex;
  //   justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 430px;
  gap: 12px;
  .switcherContainer {
    display: flex;
    padding: 4px;
    border-radius: $borderRadius;
    border: 1px solid $secondary50;
    .item {
      padding: 12px 16px;
      border-radius: $borderRadius;
      display: flex;
      gap: 8px;
      align-items: center;
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;
      .cover {
        width: 30px;
        height: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &.active {
        background: $primary200;
      }
    }
  }
  .AddExpensesModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .groupItem {
      display: flex;
      gap: 12px;
      flex-direction: column;
      .downtimeContainer {
        display: flex;
        align-items: flex-end;
        gap: 12px;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddExpensesModal {
    .AddExpensesModalGroup {
      width: 100%;
    }
  }
}
