@import "../../../../styles/dashboard/variables.scss";

#PlanningAssistantTableOrderTooltip .PlanningAssistantTableOrderTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.26);
  background: $secondary0 !important;
  border-radius: $borderRadius;

  color: $secondary300;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  .InfoWindowHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;

    .truck {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .cover {
        width: 35px;
        height: 30px;
        background-position: center;
        background-size: contain;
        position: relative;
        background-repeat: no-repeat;
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h2 {
          color: $secondary300;
          font-size: 14px;
          font-weight: 600;
        }
        h3 {
          color: $secondary200;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .InfoWindowDriver {
    width: 100%;
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    border-top: 1px solid $secondary50;

    .title {
      padding: 0px 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      position: relative;
      width: 100%;

      h3 {
        color: $secondary300;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .desc {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;
      .name,
      .tel {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 2px;
        overflow: hidden;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }
        h3 {
          color: $secondary300;
          font-size: 13px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
  }
  .InfoWindowTrailer {
    width: 100%;
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    border-top: 1px solid $secondary50;
    .title {
      padding: 0px 4px;

      h3 {
        color: $secondary300;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .desc {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;
      .name {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        white-space: nowrap;
        overflow: hidden;
        .cover {
          min-width: 30px;
          height: 12px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        h3 {
          color: $secondary300;
          font-size: 13px;
          font-weight: 400;
        }
      }

      .tel {
        gap: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }
        h3 {
          color: $secondary300;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
  .InfoWindowAddress {
    width: 100%;
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    .titleContainer {
      width: 100%;
      padding: 0px 4px;
      display: flex;
      justify-content: space-between;
      h3 {
        color: $secondary300;
        font-size: 13px;
        font-weight: 600;
      }
      .routeDistance {
        color: $secondary200;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
    }
    .InfoWindowAddressdesc {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 16px;
            color: $secondary100;
          }
        }

        h4 {
          color: $secondary200;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
  .link {
    width: 100%;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: $info;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
