@import "../../../styles/dashboard/variables.scss";

#PreferencesCover {
  position: relative;
  min-height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .background {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40%;
  }
  .cover {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    .coverImage {
      width: 100%;
      height: 300px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      .descTitle {
        color: $secondary500;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
      .descText {
        color: $secondary200;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 24px;
      #DButton button {
        background-color: transparent;
        color: $secondary300;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
        border: 2px solid $secondary300;
        width: 100%;
        .rightIconButton {
          margin-left: 6px;
          svg {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #PreferencesCover {
    .background {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #PreferencesCover {
    .background {
      display: none;
    }
  }
}
