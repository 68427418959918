@import "./../../../../styles/dashboard/variables.scss";
#QuotesTableHeader {
  display: flex;
  border-bottom: 1px solid $secondary50;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
  .QuotesTableHeaderTitle {
    display: flex;
    align-items: center;
    p {
      padding: 8px 16px;
      font-style: normal;
      font-weight: 600;
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
    }
    .PageSwitcherBadge {
      padding: 4px 12px;
      background-color: $infoBG;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      h3 {
        font-size: 13px;
        color: $info;
        text-align: center;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
  .SeeAllButton {
    a {
      color: $info;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
