@import "../../../styles/landing/variables.scss";

#OTPContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .optWrapper {
    user-select: none;
    & > div {
      input {
        width: 35px !important;
        height: 35px !important;
        margin-right: 6px;
        border-radius: $borderRadius;
        background-color: $grayNormal;
        border: 1px solid $grayNormal;
        padding: 0;
        outline: none;
        user-select: none;
        &:focus-visible {
          border: 1px solid $primaryBorder;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.error {
      & > div {
        input {
          border-color: $redNormal;
        }
      }
    }
  }
}
