@import "../../../../styles/dashboard/variables.scss";

#SideBarSticky {
  height: 100vh;
  width: 100%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: $mediaLarge) {
  #SideBarSticky {
    height: $mobileHeaderHeight;
    width: 100%;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
}
