@import "../../../../styles/dashboard/variables.scss";

#TotalPaymentField {
  vertical-align: middle;
  padding: 4px 10px;

  .TotalPaymentFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .leftSideContainer {
      display: flex;
      flex-direction: column;
      .leftSideTop {
        color: $primary500;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        margin-bottom: 4px;
      }
      .leftSideBottom {
        color: $secondary200;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
      }
    }
    .rightSideContainer {
      opacity: 0;
      transition: all 0.2s ease-in-out;
      svg {
        color: $info;
        font-size: 18px;
      }
    }
    &:hover {
      .rightSideContainer {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

@media (max-width: $mediaMedium) {
  #TotalPaymentField {
    .TotalPaymentFieldWrapper {
      .rightSideContainer {
        opacity: 1;
      }
    }
  }
}
