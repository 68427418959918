@import "./../../../styles/dashboard/variables.scss";

#NotificationsPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  h1 {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #NotificationsPageHeader {
    h1 {
      font-size: 24px;
    }
  }
}
