@import "./../../../../styles/dashboard/variables.scss";
#ConnectAppTimeField {
  vertical-align: middle;
  padding: 12px 10px;

  .TimeFieldContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    h3 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }
    .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
      &.warning {
        svg {
          font-size: 14px;
          color: $warning;
        }
      }
    }
    &.notSend {
      color: $warning;
      .icon {
        svg {
          color: $warning;
        }
      }
    }
  }
}
