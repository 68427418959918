@import "../../../../../styles/landing/variables.scss";

#VectorMapinfo.VectorMapinfo {
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 9;
  .infoContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: $borderRadius;
    background-color: $primarySurfance;
    box-shadow: 0px 0px 20px 0px rgba(8, 15, 52, 0.2);
    padding: 16px 24px;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: $primaryContent;
    }
    .item {
      gap: 16px;
      display: flex;
      align-items: center;
      .icon {
        width: 25px;
        height: 25px;
        background-color: $primaryContent;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.coverage {
          background-color: $primaryBrand;
        }

        .white {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 8px;
          height: 8px;
          background-color: $primarySurfance;
          border-radius: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #VectorMapinfo.VectorMapinfo {
    bottom: 12px;
    left: 12px;
    .infoContent {
      gap: 12px;
      padding: 12px 16px;
      p {
        font-size: 14px;
      }
      .item {
        gap: 12px;
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
          .white {
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}
