@import "./../../../../../styles/dashboard/variables.scss";

#truckGPSRoadIcon {
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 34px;
  background-color: $secondary25;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 16px;
      color: #da1414;
    }
  }

  h5 {
    color: $secondary200;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
  }
}
