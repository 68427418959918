@import "../../../../styles/landing/variables.scss";

#CountrySwitcher {
  position: fixed;
  width: 100%;
  z-index: 11;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 4px 60px;
  background: $gray300;
  &.open {
    display: flex;
  }
  .title {
    color: $gray50;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.14px;
  }

  .switcherContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    .switcherWrapper {
      position: relative;
      width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid $grayNormal;
      background: $primarySurfance;
      user-select: none;
      cursor: pointer;
      .switcher {
        display: flex;
        align-items: center;
        gap: 4px;
        .switcherTitle {
          color: $primaryContent;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.14px;
        }
      }
      .switcherIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $primaryContent;
          font-size: 16px;
        }
      }
      .dropdown {
        position: absolute;
        top: 40px;
        right: 50%;
        transform: translateX(50%);
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        background-color: $primarySurfance;
        border-radius: $borderRadius;
        overflow: hidden;
        min-width: 100%;
        flex-direction: column;
        .item {
          padding: 8px 12px;
          cursor: pointer;
          &:hover {
            background-color: $primaryBrand;
          }
        }
      }
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: $gray100;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: $mediaMedium) {
  #CountrySwitcher {
    &.open {
      display: none;
    }
  }
}
