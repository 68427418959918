@import "../../styles/dashboard/variables.scss";

#BidsProductDetails {
  min-width: 300px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  padding: 12px 24px;
  border-radius: $borderRadiusLarge;
  gap: 24px;
  .titleContainer {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid $secondary50;
    gap: 8px;
    padding-bottom: 12px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
    }
    .title {
      font-size: 23px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .items {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    .item {
      display: flex;
      flex-direction: column;
      .itemTitle {
        color: $secondary400;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
      .itemText {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        .light {
          color: $secondary100;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #BidsProductDetails {
    .items {
      height: auto;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #BidsProductDetails {
    .items {
      grid-template-columns: 1fr;
    }
  }
}
