@import "./../../../../../styles/dashboard/variables.scss";

#ShipperRoadPackage {
  width: 100%;
  padding: $pageWrapper;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: $borderRadiusLarge;
  background: $white;

  .ShipperRoadPackageContainer {
    width: 100%;
    padding: $pageWrapper;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid $secondary50;
    background: rgba(244, 246, 249, 0.3);
    position: relative;

    .productWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 12px;

      .productWrapperProduct {
        width: 100%;
        max-width: 756px;
      }
      .TotalproductWrapperWeight {
        width: 100%;
        max-width: 250px;
      }
      .packageTypeContainer {
        width: 100%;
        max-width: 250px;
      }
    }
    

    .createNewOrderBtn {
      margin-top: 24px;
      #DButton {
        button {
          background-color: transparent;
          color: $primary500;
        }
      }
    }

    .roadTrailersContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .roadOversizedContainer {
      width: 100%;
      margin-top: 12px;
    }
    .unknownContainerContainer {
      width: 100%;
      max-width: 732px;
      gap: 12px;
      margin-bottom: 12px;
      .unknownContainer {
        width: 100%;
        max-width: 200px;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #ShipperRoadPackage {
    .ShipperRoadPackageContainer {
      .productWrapper {
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperRoadPackage {
    padding: $pageWrapperResponsive;
    gap: 12px;

    .ShipperRoadPackageContainer {
      padding: $pageWrapperResponsive;
      .productWrapper {
        .productWrapperProduct {
          max-width: 100%;
        }
        .TotalproductWrapperWeight {
          max-width: 100%;
        }
      }
    }
  }
}
