@import "./../../../../styles/dashboard/variables.scss";

#RouteField {
  vertical-align: middle;
  padding: 12px 10px;
  .RouteFieldContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
    p {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
.RouteFieldTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;
  .RouteFieldContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 8px;
    .routeContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 14px;
        }
      }
      p {
        color: $secondary300;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
