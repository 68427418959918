@import "./../../../../styles/dashboard/variables.scss";

#DetailsHeaderActiveOrder {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  .DetailsHeaderActiveOrderLeft {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    .backBtn {
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $secondary100;
          font-size: 20px;
        }
      }
      h3 {
        color: $secondary200;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .trackingNumber {
      display: flex;
      align-items: center;
      gap: 12px;
      .line {
        height: 30px;
        width: 2px;
        background-color: $secondary50;
      }
      h4 {
        color: $secondary100;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .DetailsHeaderActiveOrderRight {
    display: flex;
    align-items: center;
    gap: 12px;
    .line {
      height: 30px;
      width: 2px;
      background-color: $secondary50;
    }

    h5 {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DetailsHeaderActiveOrder {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    .DetailsHeaderActiveOrderLeft {
      gap: 6px;
      .trackingNumber {
        gap: 6px;
        .line {
          height: 24px;
        }
        h4 {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
    .DetailsHeaderActiveOrderRight {
      gap: 8px;
      .line {
        display: none;
      }
      h5 {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
