@import "./../../../../styles/landing/variables.scss";

#SignUpCompanyVerifyForm.SignUpCompanyVerifyForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  #FormTitle {
    h1 {
      font-size: 20px;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  #NotHaveAccount {
    a {
      color: $secondaryContent;
      .line {
        background-color: $secondaryContent;
      }
    }
  }
}
