@import "./../../../../styles/dashboard/variables.scss";

#LanguageSidebar {
  padding: 12px 14px;
  display: flex;
  width: 100%;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $secondary50;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.min {
    border: 1px solid transparent;
  }

  .container {
    display: flex;
    gap: 12px;
    align-items: center;
    .flag {
      width: 15px;
      height: 15px;
      background-color: $imageBG;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .text {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      opacity: 1;
      transition: all 0.3s ease-in-out;
      &.min {
        opacity: 0;
      }
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 16px;
      color: $secondary100;
    }
  }
}

#LanguageSidebarModal {
  display: flex;
  justify-content: center;
  .LanguageSidebarModalWrapper {
    width: 350px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    #LanguageSidebarItem {
      width: 100%;
      border-radius: $borderRadius;
      background-color: $white;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      cursor: pointer;
      border: 1px solid $secondary50;

      .flag {
        width: 15px;
        height: 15px;
        background-color: $imageBG;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      p {
        color: $secondary100;
      }

      &.active {
        border: 1px solid $primary300;
        background-color: $primary50;

        p {
          color: $secondary300;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #LanguageSidebarModal {
    .LanguageSidebarModalWrapper {
      width: 100%;
    }
  }
}
