@import "./../../../../styles/dashboard/variables.scss";

#ListingHeader {
  .ListingHeaderTitle {
    h1 {
      color: $secondary300;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      margin-top: 4px;
    }
    p {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .linkToGPSPage {
    margin-top: 16px;
    margin-bottom: 8px;
    #DButton {
      button {
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        min-height: 36px;
      }
    }
  }
}
