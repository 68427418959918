@import "./../../../../../../../styles/dashboard/variables.scss";

#RoadWeightGroup {
  width: 100%;
  display: flex;
  flex-direction: column;

  .RoadWeightGroupitems {
    display: inline-flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 8px;
  }

  .RoadWeightGroupActions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;

    .reCount {
      #DTextButton {
        button {
          color: $info;
        }
      }
    }
    .overSize {
      display: flex;
      flex-wrap: nowrap;
      gap: 4px;
      h5 {
        color: $warning;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;

        &.plus {
          color: $success;
        }
        &.minus {
          color: $warning;
        }
      }
      h6 {
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }
}
