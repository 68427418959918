@import "./../../../../styles/dashboard/variables.scss";

#Nav {
  overflow-y: auto;
  align-self: flex-start;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin-top: 24px;
  transition: all 0.3s ease-in-out;
  .list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    &.min {
      margin-top: 55px;
    }
  }
  &::-webkit-scrollbar {
    transition: all 0.3s ease-in-out;
    width: 5px;
  }
  &.min {
    margin-top: 100px;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #Nav {
    margin-top: 0px;
    &.min {
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #Nav {
    .list {
      gap: 8px;
    }
  }
}
