@import "./../../../../styles/landing/variables.scss";

#DetailsFreightSea.DetailsFreightSea {
  overflow: hidden;
  .DetailsFreightSeaContainer {
    padding: 48px 24px;
    border-radius: 33px;
    background: $primaryContent;
    backdrop-filter: blur(10px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 24px;
  }
}
@media only screen and (max-width: $mediaMedium) {
  #DetailsFreightSea.DetailsFreightSea {
    .DetailsFreightSeaContainer {
      padding: 24px 12px;
      grid-template-columns: 1fr;
      align-items: center;
    }
  }
}
