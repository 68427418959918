@import "../../../../styles/dashboard/variables.scss";

#TransportationField {
  vertical-align: middle;
  padding: 6px 10px;
  .TransportationFieldContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      .title {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        white-space: nowrap;
      }
      .value {
        color: $secondary200;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
        line-height: 17px;
      }
    }
  }
}
.TransportationFieldTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;

  .TransportationFieldTooltipContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 8px;
    p {
      color: $secondary300;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
