@import "./../../styles/dashboard/variables.scss";

#PageSwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $pageWrapper;
  .list {
    display: flex;
    li {
      p {
        padding: 8px 16px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: $secondary200;
      }
      .line {
        width: 100%;
        height: 3px;
        background-color: $secondary50;
        border-radius: 2px;
      }
      a {
        display: flex;
        align-items: center;
        #BadgePadding {
          padding-right: 6px;
        }
        .PageSwitcherBadge {
          padding: 1px 2px;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          background-color: $error;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            font-size: 12px;
            color: $secondary0;
            text-align: center;
            line-height: 9px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }

      &.active {
        p {
          padding: 8px 16px;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          color: $primary700;
        }
        .line {
          width: 100%;
          height: 3px;
          background-color: $primary700;
        }
      }
    }
  }
}

// @media only screen and (max-width: $mediaLarge) {
//   #PageSwitcher {
//     // margin-bottom: $pageWrapperResponsive;
//   }
// }

@media only screen and (max-width: $mediaSmall) {
  #PageSwitcher {
    .list {
      flex-direction: column;
      width: 100%;
      li {
        a {
          .PageSwitcherBadge {
            h3 {
              font-size: 9px;
              color: #ffffff;
              text-align: center;
              line-height: 9px;
              font-style: normal;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
