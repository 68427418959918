@import "./../../../../styles/dashboard/variables.scss";

#TrackingTruckList {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  #DSearch {
    width: 100%;
    // height: 30px;
  }
  .TrackingTruckListSelect {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .TrackingTruckListContainer {
    overflow-x: hidden;
    height: 100%;
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow: hidden;
      padding-right: 4px;
      overflow-y: auto;
    }
    .NoTrucksList {
      color: $secondary100;
      font-size: 14px;
      text-align: center;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
