@import "./../../../../../../styles/dashboard/variables.scss";

#AssignCarrierModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  .AssignCarrierPageSwitcher {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    .list {
      display: flex;
      li {
        p {
          padding: 8px 16px;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          color: $secondary200;
          text-align: center;
        }
        .line {
          width: 100%;
          height: 3px;
          background-color: $secondary50;
          border-radius: 2px;
        }

        &.active {
          p {
            padding: 8px 16px;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            color: $primary700;
            text-align: center;
          }
          .line {
            width: 100%;
            height: 3px;
            background-color: $primary700;
          }
        }
      }
    }
  }
  .closeIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 18px;
      color: $secondary100;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #AssignCarrierModalHeader {
    .AssignCarrierPageSwitcher {
      .list {
        display: flex;
        li {
          p {
            padding: 4px 8px;
            font-size: 14px;
            line-height: 20px;
          }

          &.active {
            p {
              padding: 4px 8px;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .closeIcon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
        color: $secondary100;
      }
    }
  }
}
