@import "./../../../../../styles/dashboard/variables.scss";

#invoiceForm {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;

  min-height: 50vh;
  padding: 10px;

  .form {
    width: 100%;
    background-color: white;
    height: calc(100% - 240px);
    overflow-x: auto;

    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 10px;

    .sectiontitle {
      padding: 4px 8px;
      background-color: #f4f6f9;
      width: 100%;
      h2 {
        padding: 4px 8px;
        color: #848a8d;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .bankRequisitesCont {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .addRequisite {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  #DTextarea {
    #invoiceCommentValue {
      height: 80px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #invoiceForm {
    min-height: 60vh;
    padding: 0px;
    zoom: 60%;
  }
}
