@import "../../../../styles/landing/variables.scss";

#CustomersItem.CustomersItem {
  .item {
    width: 100%;
    height: 460px;
    border-radius: $borderRadiusLarge;
    overflow: hidden;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .title {
      width: 100%;
      height: 100%;
      padding: 30px 24px;
      display: flex;
      align-items: flex-end;
      transition: all 0.3s ease-in-out;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
      opacity: 1;
    }
    h2 {
      color: $primarySurfance;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }
    .body {
      width: 100%;
      height: 100%;
      padding: 30px 24px;
      position: absolute;
      z-index: 5;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: flex-end;
      left: 0;
      bottom: -100%;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      p {
        color: $gray50;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.14px;
      }

      #LinkTransparent {
        justify-content: flex-start;
        a {
          padding: 6px 16px;
          font-size: 14px;
        }
      }
    }

    .transparent {
      transition: all 0.3s ease-in-out;
      position: absolute;
      width: 70%;
      height: 40%;
      z-index: 2;
      left: 0;
      bottom: 0;
      opacity: 0;
      background: linear-gradient(
        180deg,
        rgba(43, 53, 58, 0.2) 0%,
        rgba(43, 53, 58, 0.8) 100%
      );
      border-radius: $borderRadiusLarge;
    }

    &:hover {
      .transparent {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      .body {
        bottom: 0;
        opacity: 1;
      }
      .title {
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CustomersItem.CustomersItem {
    .item {
      height: 300px;
      h2 {
        font-size: 20px;
      }
      .transparent {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      .body {
        bottom: 0;
        opacity: 1;
        p {
          font-size: 14px;
        }
      }
      .title {
        opacity: 0;
      }
    }
  }
}
