@import "../../../../styles/dashboard/variables.scss";

#ProductCategoryField {
  vertical-align: middle;
  padding: 12px 10px;
  .ProductCategoryFieldContainer {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
    p {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
    }

    &.many {
      cursor: pointer;
    }
  }
}

.ProductCategoryFieldTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;

  .ProductCategoryFieldTooltipContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 8px;
    p {
      color: $secondary300;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
