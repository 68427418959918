@import "../../../../styles/landing/variables.scss";

#CoverShipper.CoverShipper {
  width: 100%;
  height: $coverHeight;
  background-color: $grayNormal;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 300px;

  .blar {
    width: 100%;
    height: 100%;
    .wrapper {
      height: 100%;
    }
    .container {
      position: relative;
      width: 100%;
      height: 100%;
      .text {
        position: absolute;
        width: 80%;
        bottom: 50px;
        left: 0;
        z-index: 1;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CoverShipper.CoverShipper {
    .blar {
      background-color: rgba($primaryContent, $alpha: 0.3);
      .container {
        .text {
          width: 100%;
          bottom: 50%;
          h2 {
            text-align: center;
          }
        }
      }
    }
  }
}
