@import "./../../../../../styles/dashboard/variables.scss";

#ShipperProfileAccountantEmail {
  .ShipperProfileAccountantHeader {
    width: 100%;
    border-top: 1px solid $secondary50;
    border-bottom: 1px solid $secondary50;
    background: $secondary25;
    padding: 4px 8px;
    align-items: center;
    margin-bottom: 8px;

    h2 {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .NoEmail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    p {
      color: $info;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
