@import "./../../../../styles/dashboard/variables.scss";

#OrdersListHeader {
  width: 100%;
  margin-bottom: 12px;

  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 12px;
    .searchContainer {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .filtersContainer {
    width: 100%;
    margin-top: 16px;
  }
  .sortContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 0 12px;
    .sortButton {
      width: 230px;
    }
    .result {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      color: $secondary200;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #OrdersListHeader {
    .title {
      font-size: 24px;
      margin-bottom: $pageWrapperResponsive;
    }
    .bar {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OrdersListHeader {
    .title {
      font-size: 24px;
      margin-bottom: $pageWrapperResponsive;
    }
    .bar {
      flex-direction: column-reverse;
      align-items: flex-start;
      .searchContainer {
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-start;
        .test {
          width: 100%;
        }
      }
    }
  }
}
