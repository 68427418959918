@import "../../../../styles/landing/variables.scss";

#CheckIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  
  .CheckIconContainer {
    width: 80px;
    height: 80px;
    background-color: #f0fcf7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 48px;
      color: $primaryBrand;
    }
  }
}
