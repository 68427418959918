@import "../../../../../styles/dashboard/variables.scss";

#editNewOrderFromCompany {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: #f9f9f9;
  .header {
    margin-bottom: 24px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .submitContainer {
      padding: 24px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #editNewOrderFromCompany {
    padding: $pageWrapperResponsive $pageWrapperResponsive 50px
      $pageWrapperResponsive;
    .header {
      margin-bottom: $pageWrapperResponsive;
    }
    form {
      gap: 12px;
      .submitContainer {
        padding: 12px;
      }
    }
  }
}
