@import "./../../../../../../styles/dashboard/variables.scss";

#invoiceFormHeader {
  width: 100%;
  height: 170px;
  padding: 10px;
  //   margin-bottom: 10px;

  .title {
    margin-bottom: 10px;
    h1 {
      color: $secondary300;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .card {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid $secondary50;
    background: rgba(244, 246, 249, 0.3);

    .top {
      width: 100%;
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $secondary50;
      padding-bottom: 12px;
      .item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        h3 {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          span {
            color: $primary500;
          }
        }
        h4 {
          color: $secondary400;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
        }
      }
    }
    .payment {
      margin-top: 12px;
      h4 {
        color: $secondary400;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        span {
          margin-left: 8px;
          color: $secondary300;
          font-weight: 600;
        }
      }
    }
  }
}
