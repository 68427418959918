@import "./../../../../../styles/dashboard/variables.scss";

#BankAccountList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .bankContainerHeader {
    width: 100%;
    border-top: 1px solid $secondary50;
    border-bottom: 1px solid $secondary50;
    background: $secondary25;
    padding: 4px 8px;
    align-items: center;

    h2 {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .BankAccountList {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;

    .createButton {
      #DButton {
        button {
          color: $secondary500;
          border: 1px solid $secondary500;
          background-color: $white;
        }
      }
    }
  }
}
