@import "../../../../../styles/landing/variables.scss";

#FAQ {
  background-color: $primarySurfance;
  min-height: $pageMinHeight;
  width: 100%;
  overflow: hidden;
  .container {
    margin-top: 48px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .FAQtitle {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      h1 {
        color: $primaryContent;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        text-align: center;
      }
    }
    .body {
      width: 100%;
      display: grid;
      grid-template-columns: 200px 1fr 200px;
      gap: 24px;
      .FAQcontainer {
        width: 100%;

        .stillQuestion {
          width: 100%;
          margin-top: 48px;
          background-color: $primaryContent;
          padding: 24px;
          border-radius: $borderRadiusLarge;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 24px;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6px;

            h4 {
              color: $primarySurfance;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: -0.32px;
            }
            h5 {
              color: $gray75;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              letter-spacing: -0.14px;
            }
          }

          .right {
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              color: $primarySurfance;
              padding: 10px 16px;
              background-color: $primaryBrand;
              transition: all 0.3s ease-in-out;
              border-radius: $borderRadius;
              text-align: center;
              &:hover {
                background-color: $green600;
              }
            }
          }
        }
      }

      .filter {
        .filterList {
          width: 100%;
          li {
            height: 40px;
            width: 100%;
            cursor: pointer;
            color: $primaryContent;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.32px;
            background-color: $primarySurfance;
            border-left: 4px solid $primarySurfance;
            transition: all 0.3s ease-in-out;
            padding-left: 12px;
            display: flex;
            align-items: center;

            &.active,
            &:hover {
              background-color: $secondarySurface;
              border-left: 4px solid $green700;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #FAQ {
    .container {
      margin-top: 24px;
      margin-bottom: 24px;
      .FAQtitle {
        margin-bottom: 12px;
        h1 {
          font-size: 24px;
        }
      }
      .body {
        grid-template-columns: 1fr;
      }
    }
  }
}
