@import "./../../../../styles/dashboard/variables.scss";

#AddTrucksModal {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 200px;
  .AddTrucksModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddTrucksModal {
    .AddTrucksModalGroup {
      width: 100%;
    }
  }
}
