@import "./../../../../styles/dashboard/variables.scss";

#TruckField {
  // text-align: center;
  vertical-align: middle;
  .TruckFieldContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4px 10px;
    .cover {
      width: 30px;
      height: 20px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    h3 {
      color: $secondary300;
      font-style: normal;
      white-space: nowrap;
      font-size: 13px;
      font-weight: 600;
    }
    p {
      color: $secondary300;
      font-style: normal;
      font-size: 11px;
      white-space: nowrap;
      font-weight: 400;
    }
  }
  .NoTruck {
    white-space: nowrap;
    color: $warning;
    font-size: 13px;
    font-style: normal;
    padding: 4px 10px;
    font-weight: 400;
  }
  &.link {
    cursor: pointer;
    &:hover {
      background-color: $secondary50;
    }
  }
}
