@import "./../../../../../styles/landing/variables.scss";

#ContactFormLanding {
  max-width: 400px;
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .buttonContainer {
      width: 100%;
      margin-top: 8px;
      display: flex;
      justify-content: center;
      #Button {
        display: inline-flex;
        width: auto;
      }
    }
  }
}
