@import "../../styles/landing/variables.scss";

#Footer.Footer {
  position: relative;
  overflow: hidden;

  .backgroundImage {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left center;
  }
  .backgroundImage.two {
    background-position: right center;
  }
  .backgroundColor {
    width: 100%;
    height: 100%;
    background-color: $primaryContent;
    .borderLine {
      width: 100%;
      border-bottom: 1px solid #4f575b;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #Footer.Footer {
    .backgroundImage {
      background-image: none !important;
      background: none;
    }
  }
}
