@import "./../../../../../styles/dashboard/variables.scss";

#ViewSwitcherShipperOrders {
  display: flex;
  user-select: none;
  .item {
    display: flex;
    padding: 6px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &.active {
      border: 1px solid #2e5aac !important;
      background-color: #eef2fa !important;

      p {
        color: #2e5aac;
      }
    }

    &.left {
      border-radius: 8px 0px 0px 8px;
      border-left: 1px solid $secondary75;
      border-top: 1px solid $secondary75;
      border-bottom: 1px solid $secondary75;
    }
    &.right {
      border-radius: 0px 8px 8px 0px;
      border-right: 1px solid $secondary75;
      border-top: 1px solid $secondary75;
      border-bottom: 1px solid $secondary75;
    }

    p {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
