@import "./../../../styles/dashboard/variables.scss";

#OrderMapImage {
  width: 100%;
  height: 300px;
  background-color: $imageBG;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $borderRadiusXLarge;
  box-shadow: 0px 2px 6px 0px rgba(168, 172, 174, 0.2);
  position: relative;
  padding: 10px;
}
