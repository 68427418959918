@import "./../../../../styles/dashboard/variables.scss";
#OrderComment {
  margin-top: 12px;
  color: $secondary100;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  span {
    font-weight: 600;
    color: $secondary200;
  }
}
