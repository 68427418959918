@import "./../../../../../../styles/dashboard/variables.scss";

#CreateOrderDimentionDescription {
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  .dimentionDesctiptionItem {
    display: inline-flex;
    align-items: center;
    gap: 12px;

    h6 {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
    }
    .valueNumber {
      padding: 8px 16px;
      border-radius: 5px;
      border: 1px solid $secondary50;
      background: $secondary25;

      p {
        color: $secondary300;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }
}
