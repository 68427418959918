@import "./../../../../styles/dashboard/variables.scss";

#ShipperFinancialManagement {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: $white;

  .ShipperFinancialManagementHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
  }
  .infoMessageContainer {
    max-width: 700px;
    margin-top: 24px;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;

    border-radius: 8px;
    background: $warningBG;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 24px;
        color: $warning;
      }
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      h3 {
        color: $warning;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      p {
        color: $warning;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ShipperFinancialManagement {
    padding: $pageWrapperResponsive;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperFinancialManagement {
    .infoMessageContainer {
      flex-direction: column;
      .textContainer {
        text-align: center;
      }
    }
  }
}