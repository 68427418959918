@import "../../../../styles/landing/variables.scss";

#NotFound {
  min-height: $pageMinHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      color: $primaryContent;
      margin-top: 40px;
      margin-bottom: 25px;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
    }
    p {
      color: $secondaryContent;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
      width: 576px;
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #NotFound {
    .container {
      svg {
        height: 90px;
        width: 210px;
      }
      h3 {
        font-size: 24px !important;
      }
      p {
        width: 100%;
      }
    }
  }
}
