@import "./../../../../styles/dashboard/variables.scss";

#TrailerDetails2 {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .titleContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    background-color: $primary100;
    padding: 12px 24px;
    gap: 10px;
    border-radius: $borderRadius;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 22px;
        color: $secondary200;
      }
    }
    .title {
      color: $secondary500;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .TrailerDetails2Container {
    display: grid;
    grid-template-columns: 180px 1fr;
    gap: 12px;
    .TrailerDetails2Cover {
      width: 100%;
      height: 120px;
      border-radius: $borderRadiusLarge;
      background-color: $secondary25;
      border: 1px solid $secondary50;
      display: flex;
      align-items: center;
      justify-content: center;
      .TrailerDetails2Img {
        width: 130px;
        height: 80px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .TrailerDetails2content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      p {
        color: $secondary100;
        font-size: 16px;
        font-weight: 400;
        span {
          color: $secondary300;
          font-weight: 600;
          margin-left: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #TrailerDetails2 {
    gap: 12px;
    min-width: auto;
    .titleContainer {
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .title {
        font-size: 20px;
      }
    }
    .TrailerDetails2Container {
      grid-template-columns: 1fr;
      .TrailerDetails2Cover {
        width: 100%;
      }
    }
  }
}
