@import "./../../../../styles/dashboard/variables.scss";

#SelectTrailerField {
  padding: 8px 10px;
  vertical-align: middle;
  min-width: 250px;
  .SelectTrailerFieldContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $secondary100;
    background: $secondary0;
    padding: 2px 12px;
    min-height: 30px;
    .cover {
      width: 30px;
      height: 15px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .content {
      width: 100%;
      color: $secondary300;
      font-style: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $secondary100;
          font-size: 16px;
        }
      }
      .text {
        color: $secondary300;
        font-style: normal;
        display: flex;
        flex-direction: column;
        gap: 2px;
        white-space: nowrap;

        h3 {
          font-size: 13px;
          font-weight: 600;
        }
        p {
          font-size: 11px;
          font-weight: 400;
        }
      }
    }
  }
  .noTrailerContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $secondary75;
    background: $secondary0;
    padding: 4px 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
    .noTrailerContainerContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
    }
    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $secondary75;
        font-size: 20px;
      }
    }
  }
}
