@import "./../../../../../styles/dashboard/variables.scss";

#ShipperOceanPackage {
  width: 100%;
  padding: $pageWrapper;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: $borderRadiusLarge;
  background: $white;

  .packagesContainer {
    width: 100%;
    max-width: 1524px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .ShipperOceanPackageNewItemBtn {
    #DTextButton {
      button {
        color: $primary500;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperOceanPackage {
    padding: $pageWrapperResponsive;
    gap: 12px;
  }
}
