@import "../../../../../../styles/dashboard/variables.scss";

#CustomerDetails {
  padding: $pageWrapper;
  min-height: 100vh;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: $pageWrapper;
    .actions {
      display: flex;
    }
  }

  .participantInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #CustomerDetails {
    padding: $pageWrapperResponsive;
    .header {
      margin-bottom: $pageWrapperResponsive;
    }
  }
}
