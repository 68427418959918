@import "./../../../../styles/dashboard/variables.scss";

#TrailersListHeader {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  .TrailersListHeaderLeft {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

@media only screen and (min-width: $mediaLarge) and (max-width: 1215px) {
  #TrailersListHeader {
    .TrailersListHeaderLeft {
      #FilterLinkedSelect {
        max-width: 230px;
      }
      #TrailerSelect {
        max-width: 230px;
      }
    }
  }
}

@media only screen and (max-width: 915px) {
  #TrailersListHeader {
    .TrailersListHeaderLeft {
      display: grid;
      grid-template-columns: 1fr 1fr;
      #DSearch {
        grid-row: 1;
        grid-column: 1 / 3;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #TrailersListHeader {
    flex-direction: column-reverse;
    .TrailersListHeaderLeft {
      display: flex;
      flex-direction: column;
    }
  }
}
