@import "../../../../styles/landing/variables.scss";

#ForAbout.ForAbout {
  padding-top: 48px;
  padding-bottom: 48px;
  display: block;
  background-color: $primarySurfance;
  #HomeTitle.HomeTitle {
    .title {
      width: 100%;
      p {
        width: 70%;
      }
    }
  }
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 48px;
    overflow: hidden;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #ForAbout.ForAbout {
    #HomeTitle.HomeTitle {
      .title {
        p {
          width: 100%;
        }
      }
    }
    .container {
      grid-template-columns: 1fr;
    }
  }
}
