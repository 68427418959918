@import "../../../../../../styles/landing/variables.scss";

#CarrierSubscribeFormTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      font-size: 32px;
      color: #848a8d;
    }
  }
  h1 {
    color: $gray300;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;

    span {
      color: $primaryBrand;
    }
  }
}
