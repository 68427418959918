@import "../../../../styles/landing/variables.scss";

#AuthCover {
  background-color: $imageBG;
  width: 100%;
  height: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

@media only screen and (max-width: $mediaLarge) {
  #AuthCover {
    display: none;
  }
}
