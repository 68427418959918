@import "./../../../styles/dashboard/variables.scss";

#ProfileCompanyInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;

  .companyContainer {
    display: inline-flex;
    flex-direction: column;
    gap: 6px;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      .companyLogo {
        position: relative;
        width: 72px;
        height: 56px;
        border-radius: $borderRadiusLarge;
        border: 1px solid $secondary50;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        .editCompanyLogo {
          width: 24px;
          height: 24px;
          background-color: white;
          border-radius: $borderRadius;
          position: absolute;
          display: none;
          align-items: center;
          justify-content: center;
          top: 2px;
          right: 2px;
          cursor: pointer;
          .icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 16px;
            }
          }
          input {
            display: none;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
          }
        }
        &:hover {
          opacity: 0.7;
          .editCompanyLogo {
            display: flex;
          }
        }
      }
      .companyPhotoContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 16px 24px;
        background: $secondary25;
        border: 1px solid $secondary50;
        border-radius: $borderRadiusLarge;
        svg {
          font-size: 24px;
        }
        input {
          display: none;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
      h2 {
        color: $secondary200;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
    }
    .desc {
      display: flex;
      gap: 16px;
      align-items: center;
      flex-wrap: wrap;
      h4 {
        color: $secondary100;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .branch {
        display: flex;
        align-items: center;
        gap: 4px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: $secondary100;
            font-size: 16px;
          }
        }
        h4 {
          color: $secondary100;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      #profileInfoItem {
        #DTextButton {
          button {
            color: $info;
            padding: 0;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ProfileCompanyInfo {
    padding-left: 0;
    padding-right: 0;
    .companyContainer {
      .title {
        .companyLogo {
          .editCompanyLogo {
            display: flex;
          }
        }
      }
      .desc {
        gap: 8px;
      }
    }
  }
}
