@import "../../../../../styles/dashboard/variables.scss";

#OrderDetails {
  padding: $pageWrapper;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

@media only screen and (max-width: $mediaLarge) {
  #OrderDetails {
    padding: $pageWrapperResponsive;
  }
}
