@import "./../../../../styles/dashboard/variables.scss";

#NoTruck {
  padding: 16px;
  box-shadow: 0px 17.5px 70px 0px rgba(0, 0, 0, 0.12);
  background: $secondary0;
  border: 1px solid $secondary50;
  border-radius: $borderRadiusLarge;
  .NoTruckContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;
    .left {
      width: 67px;
      height: 50px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: $borderRadiusLarge;
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 4px;
      h1 {
        color: $warning;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
      .NoTruckContainerContent {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        h3 {
          color: $secondary100;
          font-weight: 400;
          span {
            color: $secondary300;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #NoTruck {
    padding: 8px;
    .NoTruckContainer {
      gap: 8px;
      .right {
        .NoTruckContainerContent {
          h3 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
