@import "./../../../../../styles/dashboard/variables.scss";

.gm-style .gm-style-iw-c,
.gm-style-iw-d {
  padding: 0;
  overflow: hidden !important;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

#TrackingMarkerContainer {
  width: 300px;
  min-height: 100px;
  padding: 16px;
  .line {
    height: 1px;
    width: 100%;
    background-color: $secondary50;
  }
  .link {
    width: 100%;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: $info;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #TrackingMarkerContainer {
    zoom: 50%;
  }
}
