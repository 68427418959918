@import "./../../../styles/landing/variables.scss";

#CountryMobileList {
  display: none;
}

@media only screen and (max-width: $mediaLarge) {
  #CountryMobileList {
    position: absolute;
    left: -100vw;
    z-index: 103;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: $primaryContent;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    padding: 12px;
    user-select: none;
    overflow: hidden;
    &.open {
      left: 0;
      opacity: 1;
    }
    .close {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        svg {
          font-size: 32px;
          color: $primarySurfance;
        }
      }
      h3 {
        color: $primarySurfance;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.32px;
      }
    }
    .list {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      li {
        user-select: none;
        cursor: pointer;
        width: 100%;
        padding: 12px;
        border-radius: $borderRadius;
        color: $primarySurfance;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          gap: 6px;
          align-items: center;
        }
        .flag {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $secondaryHover;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        .check {
          display: none;
        }
        &.active {
          background-color: #4f575b;
          .check {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              font-size: 16px;
              color: $primarySurfance;
            }
          }
        }
      }
    }
  }
}
