@import "../../../styles/dashboard/variables.scss";

#ShipperCalendarOrderTooltip .ShipperCalendarOrderTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.26);
  background: $secondary0 !important;
  border-radius: $borderRadius;

  color: $secondary300;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  &.MuiTooltip-tooltip {
    max-width: none !important;
  }
  .headerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 8px;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
      .text {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
      }
    }
  }
  .separator {
    height: 1px;
    width: 100%;
    background: $secondary50;
    margin: 8px 0;
  }

  .routesContainer {
    width: 100%;
    display: flex;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .routesHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .routeTitle {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .distance {
        color: $secondary200;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        span {
          font-weight: 600;
        }
      }
    }
    .routes {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .AddressDetailsItem {
        display: flex;
        gap: 8px;
        margin-bottom: 8px;
        align-items: flex-end;

        .content {
          color: $secondary100;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: flex;
          align-items: center;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: $secondary100;
            font-size: 16px;
          }
        }
      }
    }
  }
  .seeOrderButton {
    color: $info;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
  }
  .MuiTooltip-arrow {
    color: $secondary0 !important;
  }
}
