@import "./../../../../styles/dashboard/variables.scss";

#LoadBidTableItemactionfield {
  display: flex;
  gap: 12px;

  #DRefuseButton button,
  button {
    min-height: 30px;
    font-size: 14px;
  }
}

#LoadBidTableItemNoaction {
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
    color: $warning;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
}

#AllLoadsTakeOrder {
  > #DButton {
    button {
      background: transparent;
      color: $primaryBrand;
    }
  }

  .disabled {
    > #DButton {
      button {
        background: transparent;
        color: $secondary75;
      }
    }
  }
}
.LoadBidsTableDisabledItemTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  .LoadBidsTableDisabledItemTooltipContent {
    padding: 8px 8px;
    background: $secondary0;
    color: $secondary200;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .css-kudwh-MuiTooltip-arrow {
    color: $secondary0;
  }
}
