@import "./../../../../styles/dashboard/variables.scss";
#AssignCarrier {
  width: 100%;

  .assignCarrierContainer {
    display: flex;
    padding: 18px 32px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: $borderRadiusLarge;
    border: 1px solid $warning;
    background: $secondary0;
    box-shadow: 0px 2px 6px 0px rgba(168, 172, 174, 0.2);
    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      #DButton {
        button {
          border: 1px solid $warning;
          background-color: $warning;
        }
      }
      .date {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        span {
          color: $secondary500;
        }
        .icon {
          svg {
            color: $warning;
            font-size: 16px;
          }
        }
      }
    }
    .sendBidContainer {
      width: 100%;
      padding: 12px 16px;
      border-radius: $borderRadiusLarge;
      border: 1px solid $info;
      background: rgba(238, 242, 250, 0.4);
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      .partnerListContainer {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: space-between;
        .partnerList {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .partnerListItem {
            cursor: pointer;
            padding: 4px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            border: 1px solid $secondary75;
            h1 {
              color: $secondary300;
              text-align: center;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
            h3 {
              color: $primaryBrand;
              text-align: center;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
        #DButton {
          width: 100%;
          display: flex;
          justify-content: center;
          button {
            padding: 0;
            color: $info;
            background-color: transparent;
            min-height: auto;
            min-width: auto;
            border: 0;
            font-size: 13px;
          }
        }
      }
      .sendBidToPartner {
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        .sendBidText {
          h3 {
            color: $secondary100;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
          }
        }

        #DButton button {
          background: $secondary500;
          border-color: $secondary500;
          font-size: 14px;
          padding: 8px 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #AssignCarrier {
    .assignCarrierContainer {
      padding: 32px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #AssignCarrier {
    .sendBid {
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #AssignCarrier {
    .assignCarrierContainer {
      padding: 16px 12px;
      .sendBidContainer {
        flex-direction: column-reverse;
        .partnerListContainer {
          width: 100%;
        }
        .sendBidToPartner {
          width: 100%;
        }
      }
    }
  }
}

.AssignCarrierWarningTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  padding: 15px !important;
  background: $secondary0 !important;
  border-radius: $borderRadius;
  color: $secondary300 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
