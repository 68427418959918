@import "./../../../../../styles/landing/variables.scss";

#DigitalCover {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .img {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center;
    border-radius: $borderRadiusLarge;
    width: 100%;
    height: 400px;
    background-color: $imageBG;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #DigitalCover {
    grid-row: 1;
    .img {
      height: 300px;
    }
  }
}
