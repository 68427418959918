@import "./../../../../styles/dashboard/variables.scss";
#ActivityTableSwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  .list {
    display: flex;
    li {
      p {
        padding: 8px 16px;
        font-style: normal;
        font-weight: 600;
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
      }
      .ActivityTableSwitcherItem {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $secondary50;
        cursor: pointer;
        .PageSwitcherBadge {
          padding: 4px 12px;
          background-color: $infoBG;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-right: 8px;
          h3 {
            font-size: 13px;
            color: $info;
            text-align: center;
            font-style: normal;
            font-weight: 600;
          }
        }
      }

      &.active {
        p {
          padding: 8px 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
          color: $primary700;
        }
        .ActivityTableSwitcherItem {
          border-color: $primary700;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ActivityTableSwitcher {
    width: 100%;

    .list {
      width: 100%;
      flex-direction: column;
      li {
        .ActivityTableSwitcherItem {
          justify-content: space-between;
        }
        &.active {
          .ActivityTableSwitcherItem {
            justify-content: space-between;
          }
        }
      }
    }
  }
}
