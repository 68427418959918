@import "../../../../../styles/landing/variables.scss";

#CarrierSubscribeFormStep1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    .mainForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      .switcher {
        display: flex;
        justify-content: center;
        .switcherItems {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 4px;
          border-radius: 4px;
          border: 1px solid $gray50;
          .item {
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            cursor: pointer;

            color: $gray300;

            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            &.active {
              background: $gray200;
              color: $primarySurfance;
            }
          }
        }
      }
      .selectableButtonContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .label {
          color: $gray200;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        .buttons {
          display: flex;
          align-items: center;
          gap: 4px;
          flex-wrap: wrap;
        }
      }
      .checkBoxList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .item {
          display: flex;
          gap: 12px;
          align-items: center;
          user-select: none;
          p {
            cursor: pointer;
            display: inline-block;
            color: $secondaryContent;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.32px;
          }
          .icon {
            cursor: pointer;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid $secondaryBorder;
            background-color: $primarySurfance;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              display: none;
              color: $primarySurfance;
              font-size: 10px;
            }
          }

          &.active {
            .icon {
              background-color: $primaryBrand;
              border: 1px solid $primaryBrand;
              svg {
                display: block;
              }
            }
          }
        }
      }
    }
    .orContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .or {
        text-align: center;
        color: $gray75;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .requestCall {
        text-align: center;
        cursor: pointer;

        color: $primaryBrand;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
}
