@import "../../../../styles/dashboard/variables.scss";

#userProfile {
  padding: $pageWrapper;
  background-color: $secondary0;
  width: 100%;
  min-height: 100vh;
  position: relative;

  .profileHeaderBackground {
    height: 120px;
    background: $secondary300;
    border-radius: $borderRadiusXLarge;
    z-index: 1;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #userProfile {
    padding: $pageWrapperResponsive;
  }
}
