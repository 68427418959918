@import "../../../../styles/dashboard/variables.scss";

#PlanningAssistant {
  padding: $pageWrapper;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  .filters {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  .tableContainer {
    width: 100%;
    min-width: $pageWidthMin;
    max-width: $pageWidthMax;
    overflow-x: auto;
  }
  .loadingContainer {
    height: 860px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .noShipmentInfoContainer {
    display: inline-flex;
    padding: 16px 24px;
    align-items: center;
    gap: 24px;
    margin-top: 24px;

    border-radius: 8px;
    background: $successBG;
    .leftContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: $success;
        }
      }
      .text {
        color: $secondary500;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .rightContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      .button {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 12px;

        border-radius: 8px;
        background: $success;
        text-wrap: nowrap;
        user-select: none;
        cursor: pointer;

        .text {
          color: $secondary0;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          flex-wrap: nowrap;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 17px;
            color: $secondary0;
          }
        }
      }
    }
  }
  #EmptyList {
    .text {
      h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      p {
        max-width: 700px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #PlanningAssistant {
    padding: $pageWrapperResponsive;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #PlanningAssistant {
    .filters {
      flex-direction: column;
      align-items: flex-end;
    }
    .noShipmentInfoContainer {
      flex-direction: column;
      margin-top: 12px;
    }
  }
}
