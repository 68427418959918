@import "./../../../../styles/dashboard/variables.scss";

#QuotationModal {
  width: 750px;
  background-color: $white;
  border-radius: $borderRadiusLarge;
  overflow: hidden;

  .header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: $secondary0;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $secondary500;
      }
      .titleIcon {
        display: flex;
        svg {
          font-size: 16px;
          color: #545d69;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .content {
    width: 100%;
    min-height: 120px;
    padding: 16px 24px;
    background-color: $white;
    border-top: 1px solid $secondary50;
    border-bottom: 1px solid $secondary50;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .paymentContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;
      .payment {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        h3 {
          color: $secondary200;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .paymentOptions {
          display: flex;
          align-items: center;
          gap: 12px;
          .paymentOption {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 4px;
            .title {
              color: $secondary400;
              font-size: 13px;
              font-style: normal;
              font-weight: 300;
              line-height: 20px;
            }
            .value {
              color: $secondary300;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }
      }
      .price {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        h3 {
          color: $secondary200;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .priceValue {
          color: $primary500;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
    .comment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      .title {
        color: $secondary300;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .text {
        color: $secondary100;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .footer {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    .refuseButton {
      #DButton button {
        background-color: $error;
        border-color: $error;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #QuotationModal {
    width: 610px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #QuotationModal {
    width: 95vw;
    .header {
      padding: 12px 16px;
      gap: 4px;
      .title {
        gap: 4px;
      }
    }
    .content {
      padding: 12px 16px;
      gap: 4px;
      .paymentContainer {
        .paymentOptions {
          flex-direction: column;
          align-items: flex-start !important;
        }
      }
    }
    .footer {
      padding: 8px 16px;
      gap: 8px;
      flex-direction: column;
      .refuseButton {
        width: 100%;
      }
    }
  }
}
