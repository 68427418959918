@import "./../../../../styles/landing/variables.scss";
#TmsSolutionCarrierItem {
  display: flex;
  gap: 24px;
  padding: 8px 16px;
  cursor: pointer;
  border: 2px solid $grayNormal;
  box-shadow: 4px 4px 0px 0px $grayNormal;
  transition: all transition ease-in-out;
  .TmsSolutionCarrierItemLeft {
    color: $gray75;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
  .TmsSolutionCarrierItemRight {
    h1 {
      color: $primaryContent;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }
    p {
      color: $primaryContent;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
      margin-top: 8px;
    }
  }
  &.active {
    border-radius: 4px;
    border: 2px solid $primaryBorder;
    background: $green50;
    box-shadow: 4px 4px 0px 0px $primaryBrand;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #TmsSolutionCarrierItem {
    display: flex;
    gap: 6px;
    padding: 4px 8px;
    .TmsSolutionCarrierItemRight {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
