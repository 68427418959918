@import "./../../../styles/dashboard/variables.scss";
#DashboardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  .DashboardHeaderButtons {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DashboardHeader {
    flex-direction: column;
    align-items: flex-start;
    .DashboardHeaderButtons {
      width: 100%;
      flex-direction: column;
    }
  }
}
