@import "../../../../../../styles/dashboard/variables.scss";

#TimeSelectDropdown {
  max-width: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dropdown {
    display: inline-block;
    position: relative;
    .dropdown-container {
      display: relative;
      .dropdownTitle {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #848a8d;
        background-color: #fff;
        padding: 0px 0px 0px 10px;
        transition: all 0.3s ease-in-out;
        height: 40px;
        cursor: pointer;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-size: 13px;
          font-style: normal;
          font-family: inherit;
          font-weight: 500;
          color: #848a8d;
        }
      }
      .dropdownIcon {
        position: absolute;
        width: 36px;
        height: 38px;
        right: 0;
        top: calc(50% - 19px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 20px;
          path {
            fill: #848a8d;
          }
        }
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      background-color: #ffffff;
      border: 1px solid #ccc;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px;
      min-width: 100%;
      z-index: 1;
      input {
        padding: 8px;
        font-size: 14px;
      }
      .dropdownItems {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .dropdownSaveButton {
          display: flex;
          justify-content: flex-end;
        }
      }

      &button {
        padding: 8px;
        background-color: #4caf50;
        color: #fff;
        border: none;
        cursor: pointer;
      }
    }

    &.open {
      .dropdown-content {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #TimeSelectDropdown {
    max-width: none;
  }
}
