@import "./../../../styles/landing/variables.scss";
#WebPlatformCarrier.WebPlatformCarrier {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}

@media only screen and (max-width: $mediaLarge) {
  #WebPlatformCarrier.WebPlatformCarrier {
    grid-template-columns: repeat(1, 1fr);
  }
}
