@import "./../../../styles/dashboard/variables.scss";

#EmptyList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: $pageWrapper;
  .cover {
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .text {
    text-align: center;
    h1 {
      margin-bottom: 8px;
      color: $secondary500;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
    }
    p {
      max-width: 640px;
      color: $secondary200;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
}
