@import "./../../../../../styles/dashboard/variables.scss";

#InvoiceView {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 10px;
  min-height: 35vh;

  h1 {
    color: #212121;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
  h2 {
    color: #1a2023;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
  h3 {
    color: #1a2023;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  h4 {
    color: #4f575b;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    span {
      font-weight: 500;
      color: #4f575b;
    }
  }
  h5 {
    color: #4f575b;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  h6 {
    color: #848a8d;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  p {
    color: #a8acae;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .InvoiceViewWrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-width: 800px;

    zoom: 90%;

    .header {
      background-color: white;
      width: 100%;
      border-bottom: 1px solid #dddddd;
      display: grid;
      grid-template-columns: 2fr 250px;
      .title {
        padding: 10px;
        display: flex;
        align-items: center;

        .companyLogo {
          background-color: white;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          width: 100px;
          height: 55px;
        }
      }
      .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 25px 10px;
        border-left: 1px solid #dddddd;
        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .info {
      background-color: white;
      padding: 50px 10px 50px 10px;
      width: 100%;
      display: grid;
      gap: 10px;
      grid-template-columns: 1.2fr 1.2fr 1fr;

      .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .table {
      background-color: white;
      width: 100%;
      padding: 0px 10px 50px 10px;
      .currency {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        p {
          font-size: 20px;
          color: #a8acae;
          font-weight: 600;
        }
      }
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          border: 1px solid #dddddd;
        }
        th {
          padding: 15px 20px;
        }
        td {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 15px 20px;
          vertical-align: middle;
          p {
            margin-top: 4px;
          }
        }
      }
      .noCharges {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        p {
          font-size: 16px;
        }
      }
    }
    .finance {
      width: 100%;
      background-color: white;
      padding: 0px 10px 50px 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      .requisitesContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .requisites {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .item {
            display: grid;
            grid-template-columns: 2fr 3fr;
            gap: 10px;
          }
        }
      }
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .content {
          border: 1px solid #dddddd;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 25px 15px;
        }
      }
    }
    .comment {
      width: 100%;
      background-color: white;
      padding: 0px 10px 50px 10px;
    }
  }

  /* custom scroll bar */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondary100;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $secondary100;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #InvoiceView {
    border-bottom: 2px solid gray;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #InvoiceView {
    min-height: 25vh;
    .InvoiceViewWrapper {
      zoom: 35%;
    }
  }
}
