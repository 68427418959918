@import "../../../../styles/dashboard/variables.scss";

#TrailerDetailsShipper {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media only screen and (max-width: $mediaSmall) {
  #TrailerDetailsShipper {
    gap: 12px;
    min-width: auto;
  }
}
