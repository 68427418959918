@import "./../../../styles/landing/variables.scss";

#CountryMobileMenu {
  display: none;
}

@media only screen and (max-width: $mediaLarge) {
  #CountryMobileMenu {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    display: inline-block;
    padding: 12px;
    .countryButtonResponsive {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      user-select: none;
      cursor: pointer;
      width: 100%;
      padding: 8px 12px;
      background-color: $gray200;
      border-radius: $borderRadius;
      p {
        color: $primarySurfance;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.32px;
      }
      .flag {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: $primarySurfance;
        }
      }
    }
  }
}
