@import "./../../../../../styles/dashboard/variables.scss";

#FinancialDetailShipperPayment {
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  h2 {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $secondary100;
    white-space: nowrap;
    span {
      font-weight: 600;
    }
  }
  .icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $secondary100;
      font-size: 16px;
    }
  }
}

.FinancialDetailShipperPaymentTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  .StatusFieldContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      &.tooltipTitle {
        font-weight: 600;
      }
      p {
        color: $secondary100;
      }
    }
  }
}
