@import "../../../../styles/dashboard/variables.scss";

#RateField {
  vertical-align: middle;
  padding: 12px 10px;
  .RateFieldContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: nowrap;
    align-items: center;
    .text {
      white-space: nowrap;
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .noRate {
      white-space: nowrap;
      color: $warning;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
