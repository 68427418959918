@import "./../../../../styles/dashboard/variables.scss";

#PaymentDeadlineTimeField {
  vertical-align: middle;
  padding: 12px 10px;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: $secondary100;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
    }
  }
}
