@import "./../../../../../styles/dashboard/variables.scss";

#DocumentsDetailsItem {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  // a {
  //   width: 100%;
  // }
  .item {
    padding: 8px;
    width: 100%;
    background-color: $secondary25;
    border-radius: $borderRadius;
    border: 1px solid $secondary50;
    gap: 4px;
    display: grid;
    grid-template-columns: 1fr 18px;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      gap: 4px;
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: $secondary200;
        }
      }
      h1 {
        gap: 5px;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        color: $secondary200;
        font-weight: 600;
        white-space: nowrap;
        p {
          color: $secondary100;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
  .MoreIcon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    svg {
      font-size: 18px;
      color: $secondary300;
    }
  }
}
.DocumentsDetailsItemTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  display: inline-flex;
  min-width: 200px;

  .DocumentsDetailsItemTooltipTitle {
    padding: 4px ;
    color: $secondary300;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
