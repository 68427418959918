@import "../../../../../styles/landing/variables.scss";

#CarrierSubscribeFormStep2 {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .title {
      color: #32315e;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .subTitle {
      color: $secondaryContent;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.14px;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border-radius: 60px;
    background: $green50;
    svg {
      font-size: 56px;
      color: $primaryBrand;
    }
  }
}
