@import "./../../../../../../styles/dashboard/variables.scss";

#OwnFleetTable {
  margin-top: 8px;
  overflow: hidden;
  .OwnFleetTableDescription {
    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-self: flex-start;
  }
  .OwnFleetTableContainer {
    .OwnFleetTableHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0px;
      gap: 12px;
      #DButton button {
        border: 1px solid $secondary500;
        background-color: $white;
        color: $secondary500;
      }
    }
    .tableWrapper {
      width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 6px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OwnFleetTable {
    .OwnFleetTableDescription {
      font-size: 14px;
      line-height: 18px;
    }
    .OwnFleetTableContainer {
      .OwnFleetTableHeader {
        flex-direction: column-reverse;
      }
    }
  }
}
