@import "./../../../styles/landing/variables.scss";

#NotHaveAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  p {
    color: $gray200;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.16px;
    text-align: center;
  }
  a {
    position: relative;
    color: $primaryBrand;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    .line {
      width: 0;
      height: 0.5px;
      background-color: $primaryHover;
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
    }
    &:hover .line {
      width: 100%;
      transition: all 0.4s ease-in-out;
    }
  }
}
