@import "./../../../styles/dashboard/variables.scss";

#SideViewDetails {
  width: 100%;
  background-color: $white;
  height: calc(100vh - 80px);
  overflow: hidden;
  overflow-y: auto;

  .SideViewDetailsWrapper {
    width: 100%;
    padding: $pageWrapper;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .SideViewDetailsWrapperCards {
      display: grid;
      grid-template-columns: 3fr 4fr;
      gap: 12px;
      width: 100%;

      .SideViewDetailsWrapperCardsleft {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
      }
    }
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #SideViewDetails {
    .SideViewDetailsWrapper {
      .SideViewDetailsWrapperCards {
        grid-template-columns: 1fr;
        .SideViewDetailsWrapperCardsleft {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #SideViewDetails {
    .SideViewDetailsWrapper {
      padding: $pageWrapperResponsive;
      .SideViewDetailsWrapperCards {
        .SideViewDetailsWrapperCardsleft {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  #SideViewDetails {
    .SideViewDetailsWrapper {
      .SideViewDetailsWrapperCards {
        .SideViewDetailsWrapperCardsleft {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
