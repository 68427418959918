#LoadingPage {
  background-color: #ffffff;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .spinner-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    .circle-border {
      width: 50px;
      height: 50px;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: (0, 181, 108);
      background: linear-gradient(
        0deg,
        rgba(0, 181, 108, 0.1) 33%,
        rgba(0, 181, 108, 1) 100%
      );
      animation: spin 0.8s linear 0s infinite;

      .circle-core {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 50%;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
