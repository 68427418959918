@import "./../../../styles/dashboard/variables.scss";

#DCurrencyInput {
  width: 100%;
  .container {
    display: grid;
    grid-template-columns: 1fr 85px;
    gap: 4px;
  }
}
