@import "./../../../../../styles/dashboard/variables.scss";

#CostsModalShipper {
  width: 700px;
  min-height: 470px;
  background-color: $secondary0;
  border-radius: $borderRadiusLarge;
  overflow: hidden;
  .ShowCostsModalHeader {
    border-bottom: 2px solid $secondary50;
    background-color: $secondary0;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      h2 {
        color: #09101d;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .titleIcon {
        display: flex;
        svg {
          color: $secondary100;
          font-size: 24px;
        }
      }
    }
    .closeIcon {
      display: flex;
      cursor: pointer;
      padding: 5px;
      svg {
        font-size: 18px;
        color: $secondary200;
      }
    }
  }
  .ShowCostsModalContainer {
    padding: 16px 16px;
    max-height: 380px;
    min-height: 30px;
    overflow-y: auto;
    .ShowCostsModalCostList {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .noCosts {
      color: $secondary75;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #CostsModalShipper {
    width: 95vw;
    .ShowCostsModalHeader {
      padding: 12px 16px;
      gap: 4px;
    }
  }
}
