@import "./../../../../styles/dashboard/variables.scss";

#DFileFormLarge {
  width: 100%;

  .noDocument {
    text-align: center;
    border-radius: $borderRadius;
    position: relative;
    cursor: pointer;
    border: 1px dashed $secondary75;
    height: 70px;
    width: 100%;
    padding: 4px 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 32px;
        color: $secondary100;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h3 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
      }
      h4 {
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
      }
    }
    input {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  &.error {
    .noDocument {
      border: 1px dashed $error;
    }
  }
}
