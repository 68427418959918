@import "../../../styles/dashboard/variables.scss";

#ShipperCalendarSelectDate {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid $secondary200;
  background: $secondary0;
  .icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
      color: $secondary300;
    }
  }
  .content {
    text-wrap: nowrap;
    color: $secondary300;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    user-select: none;
  }
}

@media screen and (max-width: $mediaSmall) {
  #ShipperCalendarSelectDate {
    width: 100%;
    justify-content: space-between;
  }
}
