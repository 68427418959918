@import "./../../../../styles/landing/variables.scss";

#SmallHomeTitle.SmallHomeTitle {
  h1 {
    color: $secondaryContent;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
