@import "./../../../styles/dashboard/variables.scss";

#OrderStatusBar {
  width: 100%;
  margin-bottom: 24px;
  min-height: 120px;
  display: flex;
  justify-content: center;
  position: relative;
  .OrderStatusBarItemContainer {
    position: relative;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
  ul.progress-bar {
    position: relative;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    .progress {
      position: absolute;
    }
    li {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.passive {
        &::after {
          content: "";
          position: absolute;
          top: 11.5px;
          left: 50%;
          height: 1px;
          width: 100%;
          z-index: 1;
          background-color: $secondary50;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
      &.active {
        &::after {
          content: "";
          position: absolute;
          top: 11.5px;
          left: 50%;
          height: 1px;
          width: 100%;
          z-index: 2;
          background-color: $secondary200;
        }
      }
      .dots {
        position: relative;
        width: 24px;
        height: 24px;
        .smallDot {
          position: absolute;
          top: calc(50% - 4px);
          left: calc(50% - 4px);
          background: #000;
          border-radius: 100px;
          width: 8px;
          height: 8px;
          z-index: 4;
        }
        .largeDot {
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          background: $secondary25;
          border-radius: 100px;
          width: 24px;
          height: 24px;
          z-index: 3;
        }
      }
      .currentStatusContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid $primary500;
        margin-top: 4px;
      }
      .statusContainer {
        margin-top: 4px;
      }
    }
  }
}

.noCustomerMailText {
  color: $warning;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.getCargoStatusesLoadingSkeleton {
  height: 120px !important;
  margin-bottom: 24px;
}

@media only screen and (max-width: $mediaSmall) {
  .getCargoStatusesLoadingSkeleton {
    height: 265px !important;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OrderStatusBar {
    justify-content: flex-start;
    ul.progress-bar {
      flex-direction: column;
      justify-content: flex-start;
      li {
        justify-content: flex-start;
        flex-direction: row;
        min-height: 65px;
        &.passive {
          &::after {
            left: 11.5px;
            top: 50%;
            width: 1px;
            height: 100%;
          }
        }
        &.active {
          &::after {
            left: 11.5px;
            top: 50%;
            width: 1px;
            height: 100%;
          }
        }
        .currentStatusContainer {
          margin-top: 0;
          gap: 4px;
          padding: 4px 8px;
          #DButton {
            button {
              min-height: 32px;
            }
          }
        }
        .dots {
          margin-right: 4px;
        }
      }
    }
  }
}
