@import "../../styles/dashboard/variables.scss";

#OfferForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .offerFormButtons {
    display: flex;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OfferForm {
    .offerFormButtons {
      flex-direction: column;
      gap: 8px;
    }
  }
}
