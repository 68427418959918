@import "../../../../styles/dashboard/variables.scss";

#SelectableButton {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  user-select: none;
  cursor: pointer;
  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
      color: $secondary0;
    }
  }
}
