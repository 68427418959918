@import "./../../../../styles/landing/variables.scss";

#VectorMap {
  height: 660px;
  width: 100%;
  position: relative;
  overflow: hidden;
  svg {
    height: 100%;
  }
  .VectorMap {
    cursor: pointer;
    position: absolute;
    path:hover {
      fill-opacity: 1;
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      box-shadow: 0px 0px 20px 0px rgba(8, 15, 52, 0.2);
      position: absolute;
      right: 24px;
      top: 24px;
      left: auto;
      border-radius: 3px;
      background: $primarySurfance;
      padding: 3px;
      color: $primaryContent;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
    }
    .jvectormap-zoomout {
      top: 60px;
    }

    path {
      fill: #cecdca;
    }
    path[data-code="AE"],
    path[data-code="SO"],
    path[data-code="LK"],
    path[data-code="UY"],
    path[data-code="PY"],
    path[data-code="CO"],
    path[data-code="PH"],
    path[data-code="ID"],
    path[data-code="MA"],
    path[data-code="CD"],
    path[data-code="ZA"],
    path[data-code="PG"],
    path[data-code="KR"],
    path[data-code="LA"],
    path[data-code="JP"],
    path[data-code="MR"],
    path[data-code="MG"],
    path[data-code="NZ"],
    path[data-code="AU"],
    path[data-code="CL"],
    path[data-code="PE"],
    path[data-code="BO"],
    path[data-code="AR"],
    path[data-code="BR"],
    path[data-code="MX"],
    path[data-code="CA"],
    path[data-code="US"],
    path[data-code="JO"],
    path[data-code="LB"],
    path[data-code="PS"],
    path[data-code="IL"],
    path[data-code="TN"],
    path[data-code="DZ"],
    path[data-code="LY"],
    path[data-code="EG"],
    path[data-code="TH"],
    path[data-code="MM"],
    path[data-code="BT"],
    path[data-code="CN"],
    path[data-code="BD"],
    path[data-code="NP"],
    path[data-code="TJ"],
    path[data-code="KG"],
    path[data-code="IN"],
    path[data-code="PK"],
    path[data-code="TM"],
    path[data-code="AF"],
    path[data-code="UZ"],
    path[data-code="KZ"],
    path[data-code="CZ"],
    path[data-code="IR"],
    path[data-code="AZ"],
    path[data-code="GE"],
    path[data-code="AM"],
    path[data-code="IQ"],
    path[data-code="OM"],
    path[data-code="YE"],
    path[data-code="TR"],
    path[data-code="GR"],
    path[data-code="BG"],
    path[data-code="MD"],
    path[data-code="UA"],
    path[data-code="RS"],
    path[data-code="BA"],
    path[data-code="ME"],
    path[data-code="AL"],
    path[data-code="PL"],
    path[data-code="MK"],
    path[data-code="DK"],
    path[data-code="DE"],
    path[data-code="EE"],
    path[data-code="IE"],
    path[data-code="EL"],
    path[data-code="ES"],
    path[data-code="FR"],
    path[data-code="HR"],
    path[data-code="IT"],
    path[data-code="CY"],
    path[data-code="LV"],
    path[data-code="LT"],
    path[data-code="HU"],
    path[data-code="NL"],
    path[data-code="AT"],
    path[data-code="PL"],
    path[data-code="PT"],
    path[data-code="RO"],
    path[data-code="SI"],
    path[data-code="SK"],
    path[data-code="FI"],
    path[data-code="SE"],
    path[data-code="IS"],
    path[data-code="LI"],
    path[data-code="NO"],
    path[data-code="CH"],
    path[data-code="BY"],
    path[data-code="GB"] {
      fill: $primaryBrand;
    }

    .jvectormap-marker.jvectormap-element {
      fill: $primarySurfance;
      r: 8;
      stroke-width: 8px;
      stroke: $primaryContent;
    }
  }
}

.jvectormap-tip {
  display: none !important;
}

@media only screen and (max-width: $mediaLarge) {
  #VectorMap {
    height: 400px;
    .VectorMap {
      .jvectormap-zoomin,
      .jvectormap-zoomout {
        right: 12px;
        top: 12px;
      }
      .jvectormap-zoomout {
        top: 50px;
      }
      .jvectormap-marker.jvectormap-element {
        r: 7;
        stroke-width: 7px;
      }
    }
  }
}
