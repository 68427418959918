@import "./../../../../../../styles/dashboard/variables.scss";

#CreateOrderAditionalServicesItem {
  padding: 8px 12px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;

  border: 1px solid $secondary100;
  background: $secondary25;
  color: $secondary300;
  h6 {
    color: inherit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .icon {
    display: flex;
    min-width: 20px;
    align-items: center;
    justify-content: flex-start;
    svg {
      color: inherit;
      font-size: 16px;
    }
  }

  &.active {
    border: 1px solid $primary500;
    background: $primary500;
    color: $white;
  }
}
