@import "../../../../styles/dashboard/variables.scss";

#HeaderItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
      color: $secondary300;
    }
  }
  .title {
    color: $secondary300;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #HeaderItem {
    gap: 8px;
  }
}
