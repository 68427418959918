@import "./../../../styles/dashboard/variables.scss";

#GPSCompaniesSection {
  margin-top: 24px;
  margin-bottom: 24px;
  h2 {
    color: $secondary500;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .GPSCompaniesList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;

    #GPSCompany {
      // height: 40px;
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      border-radius: $borderRadius;
      background-color: $successBG;
      border: 1px solid $success;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 20px;
          color: $success;
        }

        &.logout {
          display: none;
          svg {
            color: $error;
          }
        }
      }

      &:hover {
        .icon.success {
          display: none;
        }
        .icon.logout {
          display: flex;
        }
      }

      h3 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        white-space: nowrap;
        line-height: 24px; 
      }
      h4 {
        color: $secondary200;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
        line-height: 20px; 
      }
    }
  }
}
