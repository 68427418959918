@import "./../../../styles/dashboard/variables.scss";
#OrderInformationDetails {
  min-width: 300px;
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  padding: 12px 24px;
  border-radius: $borderRadiusLarge;
  .titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary50;
    padding-bottom: 12px;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 24px;
    .titleContainerLeft {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 22px;
          color: $secondary200;
        }
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        h1 {
          color: $secondary500;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
    .titleContainerRight {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
      span {
        font-weight: 600;
      }
    }
  }
  .contentContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    gap: 12px;
    .shippingType {
      display: inline-flex;
      padding: 4px 12px;
      margin-bottom: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid $secondary50;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
          color: $secondary75;
        }
      }
    }
    .contentContainerLeft {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      &::after {
        content: "";
        position: absolute;
        top: 26px;
        left: 11.5px;
        height: calc(100% - 26px);
        z-index: 0;
        border-left: 2px dashed $secondary100;
      }
      .title {
        padding-left: 32px;
        display: flex;
        gap: 6px;
        align-items: center;
        .timeContainer {
          display: flex;
          align-items: center;
          gap: 4px;
          .timeCont,
          .dateCont {
            display: flex;
            align-items: center;
            gap: 2px;
            .icon {
              color: $secondary100;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                font-size: 14px;
              }
            }
            .time {
              color: $secondary300;
              font-size: 13px;
              font-style: normal;
              font-weight: 300;
            }
          }
        }
        h3 {
          color: $secondary500;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }
      .AddressDetailsItem {
        display: flex;
        gap: 8px;
        margin-bottom: 8px;
        align-items: flex-end;

        .content {
          color: $secondary200;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          display: flex;
          align-items: center;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .icon {
          z-index: 1;
          width: 24px;
          height: 24px;
          background-color: $secondary0;
          svg {
            font-size: 24px;
          }
          &.from {
            color: $warning;
          }
          &.to {
            color: $success;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #OrderInformationDetails {
    min-width: auto;
    padding: 12px;
    .titleContainer {
      margin-bottom: 12px;
      .icon {
        svg {
          font-size: 20px;
        }
      }
      .title {
        font-size: 20px;
      }
    }
    .contentContainer {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
