@import "./../../../../styles/dashboard/variables.scss";

#NotificationsButton {
  .NotificationsIcon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary25;
    border-radius: $borderRadiusLarge;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
    .NotificationsIconBadge {
      border-radius: $borderRadiusXLarge;
      transition: all 0.3s ease-in-out;
      background: $error;
      width: 8px;
      height: 8px;
      opacity: 1;
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 1;
    }
    svg {
      position: relative;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      color: $secondary500;
    }
    &.active {
      background-color: $secondary300;
      svg {
        color: $secondary0;
      }
    }
  }
}
