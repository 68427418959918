@import "./../../../../../../../styles/dashboard/variables.scss";

#ShowPrePaymentModalItem {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  .ShowPrePaymentModalItemLeft {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    .ShowPrePaymentModalItemTitle {
      display: flex;
      gap: 30px;
      .noComment {
        font-weight: 400;
        color: $secondary100;
        font-size: 13px;
        font-style: normal;
      }
      h1 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
      h3 {
        color: $secondary200;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .ShowPrePaymentModalItemRight {
    display: flex;
    gap: 12px;
    margin-right: 8px;
    .ShowPrePaymentModalItemContent {
      p {
        color: $primary700;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        white-space: nowrap;
        line-height: 20px;
      }
    }
    .actions {
      display: flex;
      gap: 8px;
      .icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 16px;
          color: $secondary500;
        }

        &.delete {
          svg {
            color: $error;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShowPrePaymentModalItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .ShowPrePaymentModalItemLeft {
      .ShowPrePaymentModalItemTitle {
        gap: 0px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .ShowPrePaymentModalItemRight {
      width: 100%;
      justify-content: space-between;
    }
  }
}
