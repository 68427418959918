@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  h2 {
    color: $secondary300;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
    text-align: center;
  }
  .empty {
    width: 50px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderHeader {
    h2 {
      font-size: 20px;
    }
    .empty {
      display: none;
    }
  }
}
