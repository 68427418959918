@import "../../../styles/dashboard/variables.scss";

#profileSettings {
  margin: 0 24px;
  .titleContainer {
    height: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid $secondary50;
    margin-bottom: 12px;
    .title {
      font-size: 23px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #profileSettings {
    margin: 0;
  }
}
