@import "../../../../../styles/dashboard/variables.scss";

#profileEditPhone {
  display: flex;
  align-items: center;
  justify-content: center;
  .formGroup {
    min-height: 150px;
    display: flex;
    width: 350px;
    flex-direction: column;
    gap: 12px;
    .otpTitle {
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #profileEditPhone {
    .formGroup {
      width: 100%;
    }
  }
}
