@import "../../../../../../styles/dashboard/variables.scss";

#ContainerTrackingItemEvent {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  .event {
    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .eventDate {
    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
