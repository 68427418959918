@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
}

#PartnerBids {
  font-family: SourceSansPro;
  width: 100%;
  padding: 30px 65px;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  #B-Layout {
    display: flex;
    gap: 24px;
    width: 100%;
  }
  .bidsLeftContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    .bidsLeft {
      min-width: 300px;
      max-width: 350px;
      gap: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .offerFormSent {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 8px;
        gap: 8px;
        padding: 16px 24px;
        background-color: $successBG;
        svg {
          color: $success;
          width: 24px;
          height: 24px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: $success;
        }
      }
      svg {
        width: 300px;
        height: 90px;
      }
    }
  }
  .bidsRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #PartnerBids {
    #B-Layout {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #PartnerBids {
    padding: 12px;
    .bidsLeftContainer {
      .bidsLeft {
        max-width: 100%;
        min-width: 0;
      }
    }
  }
}
