@import "./../../../../../../styles/dashboard/variables.scss";

#RoadLocationRouteShipper {
  max-width: 650px;

  padding-left: 24px;
  padding-right: 36px;

  &.edit {
    padding-right: 0px;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  .managementItem {
    position: relative;
    #DInputs {
      .group {
        input {
          border: none;
          border-radius: 4px 4px 0px 0px;
          border-bottom: 1px solid $secondary75;
          background: $secondary25;
        }
      }

      &.error {
        .group {
          input {
            border-bottom: 1px solid $error;
          }
        }
      }
    }
    .managementItemIcon {
      position: absolute;
      cursor: pointer;
      right: -30px;
      z-index: 0;
      border-radius: 100%;
      background-color: $primary500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
      svg {
        font-size: 14px;
        color: $white;
      }
      &.remove {
        background-color: $error;
        top: 30px;
        right: -48px;
      }
      &.add {
        top: 30px;
        right: -24px;
      }
    }
    .locationManagementItemIcon {
      position: absolute;
      top: 28px;
      left: -30px;
      z-index: 0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      svg {
        background-color: $white;
        font-size: 25px;
        color: $secondary500;
      }
    }
    .suggestionsContainer {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 24px;
      overflow-x: auto;
      overflow-y: hidden;
      max-width: 320px;
      &::-webkit-scrollbar {
        height: 6px;
      }
      .suggestionItem {
        display: flex;
        padding: 2px 8px;
        align-items: center;

        border-radius: 4px;
        background: $infoBG;
        color: $info;

        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        cursor: pointer;
      }
    }
  }

  .lineVertical {
    position: absolute;
    top: 38px;
    left: 8.5px;
    z-index: 0;
    width: 5px;
    height: calc(100% - 92px);
    border: none;
    border-left: 2px dashed $secondary100;
  }
}
