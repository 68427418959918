@import "./../../../../styles/dashboard/variables.scss";

#AssignCarrierModal {
  background-color: $secondary0;
  height: 80vh;
  width: 80vw;
  border-radius: 12px;
  padding: 12px 24px;
  overflow: hidden;

  .AssignCarrierModalContent {
    height: 100%;
    padding: 5px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #AssignCarrierModal {
    width: 90vw;
    padding: 12px;
  }
}
