@import "./../../../styles/dashboard/variables.scss";

#GPSTruckFilter {
  h2 {
    color: $secondary500;
    font-size: 16px;
    font-weight: 600;
  }

  .GPSTruckFilterWrapper {
    margin-top: 8px;
    margin-bottom: 8px;

    .matchedselect {
      display: inline-flex;

      min-width: 200px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #GPSTruckFilter {
    .GPSTruckFilterWrapper {
      .matchedselect {
        width: 100%;
      }
    }
  }
}
