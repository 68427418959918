@import "./../../styles/dashboard/variables.scss";

#BidsLang {
  width: 170px;

  #DSelect {
    .selectComponent {
      .select__control {
        border: 1px solid $secondary50;
      }
      .select__control--is-focused {
        border-color: $secondary50;
      }
      .select__placeholder {
        color: $secondary50;
      }
      .select__menu {
        border: 1px solid $secondary50;
      }
    }
  }
}
