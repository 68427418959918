@import "./../../../../styles/dashboard/variables.scss";
.ShipperNavMobile {
  display: none;
  position: absolute;
  left: -100vw;
  z-index: 101;
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: 80px;
  transition: all 0.3s ease-in-out;
  background-color: $secondary300;
  top: 0;
  opacity: 0;
  overflow-x: auto;
  .ShipperNavMobileContainer {
    width: 100%;
    border-top: 1px solid $secondary100;
    .list {
      display: flex;
      flex-direction: column;
    }
    #ShipperNavItemMobile {
      border-bottom: 1px solid $secondary100;
      a {
        &.disabled {
          pointer-events: none;
          .item p {
            color: $secondary75;
          }
        }
        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 12px;

          p {
            color: $secondary0;
            font-size: 18px;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
          }

          .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              transition: all 0.3s ease-in-out;
              font-size: 24px;
              color: $secondary0;
            }
          }
        }

        &.active {
          .item p,
          .arrow svg {
            color: $primaryBrand;
          }
        }
      }
    }
  }

  &.open {
    left: 0;
    opacity: 1;
  }
}
@media only screen and (max-width: $mediaLarge) {
  .ShipperNavMobile {
    display: flex;
  }
}
