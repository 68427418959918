@import "./../../../../styles/dashboard/variables.scss";
#ProductInformationDetails {
  border-left: 1px solid $secondary50;
  padding-left: 12px;
  #ShowCustomerButton {
    background-color: $secondary0;
    border-radius: $borderRadiusLarge;
    border: 1px solid $secondary50;

    #DShowButton svg {
      color: $secondary100;
    }
  }
  .productDetails {
    margin-top: 24px;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: space-around;
    gap: 6px;
    .productDetailsItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      // border-right: 1px solid $secondary50;
      // padding-right: 12px;
      // padding-left: 6px;
      // &:last-child {
      //   padding-right: 0px;
      //   border-right: none;
      // }
      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 32px;
          color: $primary200;
        }
      }
      h3 {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
      }
    }
    .line {
      width: 1px;
      background: $secondary50;
      height: 50px;
      align-self: center;
    }
  }

  .warningContainer {
    border-radius: $borderRadiusLarge;
    padding: 6px 8px;
    background: $infoBG;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    align-items: center;
    .warningContainerLeft {
      display: flex;
      align-items: center;
      gap: 4px;
      .icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $info;
        }
      }
      h1 {
        color: $info;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .warningContainerRight {
      cursor: pointer;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $info;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ProductInformationDetails {
    border-left: none;
    padding-left: 0;
  }
}
