@import "./../../../styles/dashboard/variables.scss";

#FinancialDetails {
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  padding: 12px 24px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .FinancialDetailsContent {
    border-top: 1px solid $secondary50;
    .FinancialDetailslist {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-self: auto;
      gap: 12px;
      padding-top: 16px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #FinancialDetails {
    padding: 12px;
  }
}
