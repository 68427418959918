@import "../../../styles/dashboard/variables.scss";

#FiltersContainer {
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid $secondary50;
  border-bottom: 1px solid $secondary50;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  .filters {
    display: flex;
    gap: 4px;
    .filtersTitle {
      white-space: nowrap;
      padding: 10px 8px 4px 0;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      color: $secondary100;
    }
    .filtersBody {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .clear {
    padding-top: 8px;
    #DTextButton {
      button {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: $info;
      }
    }
  }
}

@media only screen and (min-width: 1540px) {
  #FiltersContainer {
    .filters {
      .filtersBody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}

@media only screen and (max-width: 1540px) {
  #FiltersContainer {
    .filters {
      .filtersBody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1290px) {
  #FiltersContainer {
    .filters {
      .filtersBody {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media only screen and (max-width: 975px) {
  #FiltersContainer {
    .filters {
      .filtersBody {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #FiltersContainer {
    flex-direction: column;
    .filters {
      flex-direction: column;
      width: 100%;
      .filtersTitle {
        padding: 0;
      }
      .filtersBody {
        grid-template-columns: 1fr;
      }
    }
    .clear {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
  }
}
