@import "./../../../../styles/landing/variables.scss";
#CarrierApp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  height: 100%;
  overflow: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  .CarrierAppLeft {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .subscribeContainer {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      margin-top: 32px;

      max-width: 600px;
      #Button {
        max-width: 220px;
        height: 44px;
      }
    }
  }
}
@media only screen and (max-width: $mediaLarge) {
  #CarrierApp {
    grid-template-columns: 1fr;
    gap: 0;
    .CarrierAppLeft {
      grid-row: 2;
      gap: 24px;
    }
  }
}

@media screen and (max-width: $mediaSmall) {
  #CarrierApp {
    .subscribeContainer {
      flex-direction: column;
      #Button {
        max-width: 100% !important;
      }
    }
  }
}
