@import "./../../../../styles/dashboard/variables.scss";

#DriverField {
  // text-align: center;
  vertical-align: middle;
  padding: 12px 10px;
  .active {
    display: flex;
    align-items: center;
    gap: 4px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }

    h4 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px;
      white-space: nowrap;
    }
  }
  .inactive {
    display: flex;
    align-items: center;
    gap: 4px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: #b95000;
      }
    }
    p {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
