@import "./../../../../../styles/dashboard/variables.scss";

#TrailersNewOrder {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background-color: $white;

  .TrailersNewOrderWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #TrailersNewOrder {
    padding: 12px;
    gap: 12px;
  }
}
