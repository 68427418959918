@import "./../../../../../styles/dashboard/variables.scss";

#financialDetailsCustomerPrice {
  display: flex;
  min-height: 150px;
  height: min-content;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid $secondary50;
  flex: 1 0 auto;

  .titlePrice {
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    h2 {
      color: $secondary200;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      span {
        color: $primary700;
        white-space: nowrap;
      }
    }
  }

  .agreedPrice {
    width: 100%;
    margin-left: 12px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;

    h2 {
      color: $secondary100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      span {
        color: $secondary200;
        white-space: nowrap;
      }
    }
    .editableicon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding-left: 5px;
      padding-right: 5px;
      transition: all 0.3s ease-in-out;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
  }

  .extraChargesContainer {
    width: 100%;
    .chargesHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      user-select: none;
      .title {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 4px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            font-size: 20px;
            color: $secondary100;
          }
        }
        h4 {
          color: $secondary100;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;

          span {
            color: $secondary200;
          }
        }
      }
      .AddButton {
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid $info;
        cursor: pointer;

        svg {
          font-size: 16px;
          color: $info;
        }
      }
    }

    .loading {
      display: flex;
      justify-content: center;
    }

    .chargeslist {
      display: none;
      user-select: none;
      &.show {
        display: block;
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        // margin-left: 16px;
        margin-top: 6px;
        border-left: 2px solid $secondary50;
      }
    }
  }
  .noExtraCharges {
    display: flex;
    #DTextButton {
      button {
        color: $info;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

#financialDetailsNoCustomerPrice {
  display: flex;
  min-height: 150px;
  width: max-content;
  height: min-content;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border: 1px solid $secondary50;
  border-radius: 8px;
  flex: 1 0 auto;

  .title {
    padding: 8px;
    h2 {
      color: $secondary200;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  #DButton {
    button {
      background-color: $white;
      color: $warning;
      border-color: $warning;
    }
  }
}
