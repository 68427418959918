@import "../../../../styles/landing/variables.scss";

#Socials.Socials {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .social {
    display: flex;
    gap: 12px;
    align-items: center;
    .icon {
      width: 32px;
      height: 32px;
      svg {
        font-size: 32px;
        transition: all 0.3s ease-in-out;
        color: $primarySurfance;
      }
      &:hover {
        svg {
          color: $primaryBrand;
        }
      }
    }
  }
  .applications {
    display: flex;
    gap: 16px;
    .img {
      width: 135px;
      height: 40px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #Socials.Socials {
    flex-direction: column;
    .social {
      margin-bottom: 16px;
    }
  }
}
@media only screen and (max-width: 310px) {
  #Socials.Socials {
    .applications {
      flex-direction: column;
      gap: 8px;
    }
  }
}
