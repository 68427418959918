@import "./../../../styles/dashboard/variables.scss";

#NotificationItem {
  border-bottom: 1px solid $secondary50;
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  .NotificationItemLeft {
    .cover {
      width: 40px;
      height: 40px;
      background-color: #d9d9d9;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .icon {
        width: 100%;
        height: 100%;
        background-color: $successBG;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 20px;
          color: $success;
        }
      }
    }
  }
  .NotificationItemRight {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 6px;
    #DButton button {
      border: 2px solid $primaryBrand;
      border-radius: $borderRadius;
      font-size: 14px;
      padding: 4px 8px;
      color: $primaryBrand;
      background-color: transparent;
      min-height: auto;
    }
    .NotificationItemTitle {
      position: relative;
      h1 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-right: 10px;
      }
      .dot {
        top: 0;
        right: 0;
        position: absolute;
        display: none;
        background-color: $info;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        &.show {
          display: block;
        }
      }
    }

    h3 {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
    p {
      color: $secondary75;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #NotificationItem {
    padding: 12px 10px;
    gap: 6px;
    .NotificationItemLeft {
      .cover {
        width: 30px;
        height: 30px;
      }
    }
    .NotificationItemRight {
      #DButton button {
        padding: 2px 6px;
      }
    }
  }
}
