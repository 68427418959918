@import "./../../../../../styles/dashboard/variables.scss";

#DashboardOrdersTableHeader {
  h1 {
    color: $secondary300;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
  }
  #FiltersContainer {
    border: 0;
    .clear {
      display: none;
    }
  }
}
