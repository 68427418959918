@import "../../../styles/dashboard/variables.scss";

#ShipperCalendarOrder {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  padding: 4px 8px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  overflow: hidden;
  &.quotation {
    border: 2px dashed $secondary100;
    background: $secondary25;

    .icon {
      svg {
        color: $secondary100;
      }
    }
  }
  &.road {
    border: 2px solid $success;
    background: $successBG;
    .icon {
      svg {
        color: $success;
      }
    }
  }
  &.sea {
    border: 2px solid $info;
    background: $infoBG;
    .icon {
      svg {
        color: $info;
      }
    }
  }
  &.air {
    border: 2px solid $warning;
    background: $warningBG;
    .icon {
      svg {
        color: $warning;
      }
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
    }
  }
  .separator {
    width: 1px;
    height: 16px;

    background: $secondary75;
  }
  .offer {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 15px;
    border: 1px solid $success;
    background: $successBG;
    cursor: pointer;
    flex-wrap: nowrap;
    .title {
      color: $success;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $success;
      }
    }
    &.accepted {
      border: none;
      background: transparent;
    }
  }
  .orderItem {
    margin: 0 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: nowrap;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
    .text {
      color: $secondary200;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
