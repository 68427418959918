@import "../../../../../styles/dashboard/variables.scss";

#ShipperChartSelectDate {
  position: relative;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid $secondary100;
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        font-size: 20px;
        color: $secondary300;
      }
      &.disabled {
        cursor: not-allowed;
        svg {
          color: $secondary100;
        }
      }
    }
    .date {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary300;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  .calendar-popup {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background-color: $secondary0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 100%;
    z-index: 1;
    .calendar {
      width: 250px;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 5px;
      background-color: #f9f9f9;
    }
    .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
      background-color: $secondary100;
      color: $secondary0;
      border-radius: 4px;
    }
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
      background-color: $primary500;
      color: $secondary0;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ShipperChartSelectDate {
    margin-top: 12px;
    .container {
      align-items: center;
    }
  }
}
