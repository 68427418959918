@import "./../../../../../../../styles/dashboard/variables.scss";

#SendBidDetailsDescriptionGroup {
  .SendBidDetailsPreview {
    h3 {
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 5px;
      margin-left: 5px;
    }
    .SendBidDetailsPreviewContainer {
      padding: 20px 30px;
      border-radius: 8px;
      width: 100%;
      min-height: 256px;
      border: 1px solid $secondary50;
      background: $secondary25;

      display: flex;
      flex-direction: column;
      gap: 24px;

      h4 {
        color: $secondary500;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      h5 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .note {
          // margin-bottom: 24px;
        }
        .placeholder {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          color: $secondary100;
          margin-bottom: 24px;
        }
        .typography {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 24px;
        }
      }
    }
  }
}
