@import "./../../../../../styles/dashboard/variables.scss";

#OrdersSidebarItem {
  overflow: hidden;
  width: 100%;
  height: 140px;
  border-radius: $borderRadiusLarge;
  border: 2px solid $secondary50;
  background: $white;
  padding: 16px 0px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    gap: 4px;

    .code {
      display: flex;
      align-items: center;
      gap: 8px;

      .tracking {
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        // border: 1px solid $secondary50;
        h3 {
          color: $secondary300;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          white-space: nowrap;
        }
      }
      h4 {
        color: $secondary300;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
      }
    }

    .status {
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      h4 {
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }

  .line {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    .color {
      width: 100%;
      height: 1px;
      background-color: $secondary50;
    }
  }

  .content {
    padding: 0px 12px;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;

    .item {
      display: inline-flex;
      gap: 4px;
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
      h5 {
        color: $secondary300;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;

        span {
          color: $secondary100;
        }
      }
    }
  }

  &.active {
    border: 2px solid $primary500;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #OrdersSidebarItem {
    .header {
      padding: 0px $pageWrapperResponsive;
    }
    .content {
      padding: 0px $pageWrapperResponsive;
    }
  }
}
