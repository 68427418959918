@import "./../../../styles/dashboard/variables.scss";

#BoardDetailAction {
  display: inline-flex;
  padding: 12px 24px 12px 0px;
  gap: 32px;
  align-items: center;
  align-self: flex-start;
  margin-top: 16px;
  border: 1px solid $secondary50;
  border-radius: $borderRadiusLarge;

  .Noinformation {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 16px;

    h3 {
      color: $secondary300;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .newBidInformation {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 16px;

    // background-color: $infoBG;
    border-radius: 8px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $info;
        font-size: 24px;
      }
    }

    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $info;
    }
  }

  .TakeBidInformation {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 16px;

    // background-color: $successBG;
    border-radius: 8px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $success;
        font-size: 24px;
      }
    }

    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $success;
    }
  }

  .action {
    .newBid {
      #DButton {
        button {
          background-color: $secondary500;
          border: 1px solid $secondary500;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #BoardDetailAction {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 8px;

    .Noinformation {
      padding: 8px;
    }
    .newBidInformation {
      padding: 8px;
    }
    .TakeBidInformation {
      padding: 8px;
    }
  }
}
