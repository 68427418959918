@import "./../../../../styles/dashboard/variables.scss";

#DLogoContainer {
  display: flex;
  .DLogoContainerWrapper {
    transition: all 0.3s ease-in-out;
    width: 150px;
    height: 44px;
    overflow: hidden;
  }
  &.max {
    .DLogoContainerWrapper {
      width: 150px;
    }
  }
  &.min {
    .DLogoContainerWrapper {
      width: 44px;
    }
  }
}
