@import "../../../styles/dashboard/variables.scss";

#FinancialDetailsShipper {
  border-radius: $borderRadiusLarge;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  padding: 12px 24px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: $white;
  .FinancialDetailsContent {
    border-top: 1px solid $secondary50;
    .FinancialDetailslist {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;
      padding-top: 16px;

      .orderPrice {
        display: flex;
        flex-direction: column;
        h4 {
          color: $secondary400;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          white-space: nowrap;
        }
        .money {
          h5 {
            color: $secondary300;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            white-space: nowrap;
          }
        }
      }

      .totalPayment {
        display: flex;
        flex-direction: column;
        h4 {
          color: $secondary400;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          white-space: nowrap;
        }
        .money {
          h5 {
            color: $secondary300;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            white-space: nowrap;
          }
        }
      }

      .costs {
        display: flex;
        flex-direction: column;
        h4 {
          color: $secondary400;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          white-space: nowrap;
        }
        .money {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          gap: 4px;
          h5 {
            color: $secondary300;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            white-space: nowrap;
          }
          .icon {
            margin-left: 8px;
            // visibility: 0;
            // opacity: 0;
            // transition: 0.3s ease-in-out;
            display: flex;
            align-items: center;
            gap: 2px;
            flex-wrap: nowrap;

            span {
              color: #2e5aac;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
            }
            #DShowButton {
              min-width: 18px;
              min-height: 18px;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: $mediaSmall) {
  #FinancialDetailsShipper {
    padding: 12px;
  }
}
