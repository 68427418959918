@import "../../../../../styles/dashboard/variables.scss";

#LandProductDetails {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  .index {
    color: $primary500;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .mainContent {
    .item {
      display: flex;
      align-items: center;
      gap: 4px;
      .itemTitle {
        color: $secondary100;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
      .itemText {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        .hsCode {
          color: $secondary100;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
        .light {
          color: $secondary100;
        }
      }
    }
    .adr,
    .oversize {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      user-select: none;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        .warningIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: $warning;
            font-size: 16px;
          }
        }
        .text {
          color: $secondary300;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondary300;
          cursor: pointer;
          svg {
            font-size: 24px;
          }
        }
      }
      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          gap: 0;
        }
        .documentContainer {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          border-radius: 4px;
          border: 1px solid $secondary50;
          background: $secondary25;
          .documentContent {
            display: flex;
            align-items: center;
            gap: 8px;
            .documentIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                font-size: 24px;
                color: $secondary200;
              }
            }
            .documentTitle {
              color: $secondary200;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #LandProductDetails {
    .adr,
    .oversize {
      .content {
        grid-template-columns: 1fr;
      }
    }
  }
}
