.ModalComponent {
  background-color: transparent;
  justify-content: center;
  &:focus {
    outline: 0;
  }
}

.ReactModal__Content {
  display: inline-block;
}

.OverlayComponent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;

  align-items: center;
  justify-content: center;
  &.transparent {
    background-color: rgba(0, 0, 0, 0);
  }
}
.ReactModal__Overlay {
  transition: opacity 2000ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Overlay--after-open {
  //   opacity: 1;
}

.ReactModal__Overlay--before-close {
  //   opacity: 0;
}
