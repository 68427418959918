@import "../../../styles/dashboard/variables.scss";

#FilterCustomerSelect {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .selectComponent {
    .selected {
      border: 1px solid $secondary300 !important;
    }
    .select__control {
      border-radius: 25px;
      border: none;
      background: $secondary25;
      padding: 0px 0px 0px 10px;
      transition: all 0.3s ease-in-out;
      height: 40px;
      cursor: pointer;
      .select__value-container {
        padding: 0;
        .select__single-value {
          margin: 0;
          overflow: hidden;
          line-height: 20px;
        }
      }
    }
    .select__control--is-focused {
      border-color: $secondary100;
      box-shadow: none;
    }
    .select__placeholder {
      color: $secondary200;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
    .select__indicators {
      user-select: none;
      .select__indicator {
        padding: 0;
        margin: 0;
        margin-right: 8px;
        margin-left: 8px;
        user-select: none;
        cursor: pointer;

        svg {
          color: $secondary100;
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }
    }
    .select__input-container {
      margin: 0;
      padding: 0;
    }
    .css-1dimb5e-singleValue {
      color: $secondary300;

      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .select__menu {
      // max-height: 200px;
      border-radius: 0;
      overflow: hidden;
      border: 1px solid $secondary100;
      box-shadow: none;
      margin-bottom: 0;
      margin-top: 5px;
      // overflow: hidden;
      .select__menu-list {
        max-height: 145px;
        padding: 0;
        .select__option {
          cursor: pointer;
          color: black;
          font-size: 16px;
          padding: 10px 15px;
        }
        .select__option--is-focused {
          transition: all 0.2s ease-in-out;
          background-color: $secondary25;
        }
        .select__option--is-selected {
          transition: all 0.2s ease-in-out;
          background-color: $secondary50;
        }
      }
    }
  }

  // .buttton {
  //   width: 100%;
  //   height: 40px;
  //   background-color: orange;
  // }

  &.error {
    .selectComponent {
      .select__control {
        border: 1px solid $error;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
}

@media only screen and (max-width: 700px) {
  #FilterCustomerSelect {
    width: 100%;
  }
}
