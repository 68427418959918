@import "./../../../../styles/dashboard/variables.scss";

#EditPartnerModal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .EditPartnerModalgroup {
    width: 450px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    #DTextButton {
      button {
        color: $info;
      }
    }
    .showSecondaryPhoneButtonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .showSecondaryPhoneButton {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        cursor: pointer;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $info;
        }
      }
      .text {
        color: $info;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .hideSecondaryPhoneButtonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .hideSecondaryPhoneButton {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: $secondary0;
        cursor: pointer;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $error;
        }
      }
      .text {
        color: $error;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .prefferedTrailerTypesContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .prefferedTrailerTypesTitle {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .prefferedTrailerTypesList {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;
      }
    }
    .prefferedRoutesContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .prefferedRoutesTitle {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .prefferedRoutesList {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #EditPartnerModal {
    .EditPartnerModalgroup {
      width: 100%;
      gap: 6px;
    }
  }
}
