@import "./../../../../../../styles/dashboard/variables.scss";

#OceanPackageItem {
  width: 100%;
  padding: $pageWrapper;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid $secondary50;
  background: rgba(244, 246, 249, 0.3);
  position: relative;

  .productAndPackageContainer {
    width: 100%;
    display: flex;
    gap: 12px;
    .productWrapper {
      width: 100%;
      max-width: 732px;
    }
    .packageTypeContainer {
      width: 100%;
      max-width: 250px;
    }
  }

  .productAdrWrapper {
    width: 100%;
    max-width: 732px;
  }
  .productOversizedWrapper {
    width: 100%;
    margin-top: 12px;
  }
  .unknownContainerContainer {
    width: 100%;
    max-width: 732px;
    margin-bottom: 12px;
    .unknownContainer {
      width: 100%;
      max-width: 200px;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OceanPackageItem {
    padding: $pageWrapperResponsive;

    .productAndPackageContainer {
      flex-direction: column;
      gap: 12px;
      .productWrapper {
        max-width: 100%;
      }
      .packageTypeContainer {
        max-width: 100%;
      }
    }
  }
}
