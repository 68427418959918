@import "../../../../styles/landing/variables.scss";

.TalkExpertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  align-items: center;
  justify-content: center;

  .TalkExpertContainer {
    background-color: transparent;
    justify-content: center;
    &:focus {
      outline: 0;
    }
  }
  .ReactModal__Content {
    display: inline-block;
  }

  #TalkExpert {
    top: $headerHeight;
    top: 0;
    position: absolute;
    right: 0;

    .container {
      height: $pageMinHeight;
      height: 100vh;
      width: 385px;
      background-color: $primaryContent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 30px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          color: $primarySurfance;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            font-size: 32px;
            color: $primarySurfance;
          }
        }
      }
      .form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        #Label {
          label {
            color: $primaryBrand;
          }
        }
        #RoleRadio {
          .item {
            p {
              color: $primarySurfance;
            }
          }
        }

        #TermsAgree {
          p {
            color: $primarySurfance;
          }
        }
      }
      .text {
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.32px;
          text-align: center;
          color: $primarySurfance;
        }
      }
    }
  }
}

.ReactModal__Overlay--before-close {
  pointer-events: none;
}

@media only screen and (max-width: $mediaLarge) {
  .TalkExpertOverlay {
    #TalkExpert {
      .container {
        width: 100vw;
        height: 100vh;
        gap: 12px;
        padding: 12px 24px;
      }
    }
  }
}
