@import "../../../../styles/dashboard/variables.scss";

#TrackingContainerModal {
  background-color: $white;
  height: 80vh;
  width: 80vw;
  border-radius: 12px;
  overflow: hidden;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .trackingContainerHeaderContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    justify-content: space-between;

    .headerTitle {
      color: #4a5056;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      svg {
        font-size: 20px;
        color: $secondary200;
      }
    }
  }
  .containersContainer {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    min-height: 40px;

    &::-webkit-scrollbar {
      height: 5px;
    }
    .containerItem {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      &.active {
        border-radius: 4px 4px 0px 0px;
        border-top: 3px solid $primary500;
        background: $primary50;
      }

      cursor: pointer;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary200;
        }
      }
      .containerNumber {
        color: $secondary200;
        text-align: center;

        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        &.active {
          color: $secondary300;
        }
      }
    }
  }
}
