@import "../../../../styles/landing/variables.scss";

#CarrierSubscribeNowWhyUs {
  margin-top: 24px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    .title {
      color: $gray300;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      span {
        padding: 4px 12px;
        border-radius: 18px;
        border: 1px solid $green100;
        background: $secondarySurface;
        backdrop-filter: blur(6px);
        white-space: nowrap;

        color: $green700;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
      }
    }
    .whyUsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .whyUs {
        display: flex;
        gap: 8px;
        .text {
          color: #848a8d;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .icon {
          width: 20px;
          height: 20px;
          svg {
            color: #848a8d;
            font-size: 20px;
          }
        }
      }
    }
    .linker {
      display: flex;
      align-items: flex-end;
      gap: 48px;
      .answersContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 24px;
        .answer {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 24px;

          width: 650px;

          border-radius: 8px;
          background: $primarySurfance;
          box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
          .icon {
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            justify-content: center;
            align-items: center;

            border-radius: 41px;
            background: $primaryBrand;
            svg {
              font-size: 24px;
              color: $primarySurfance;
            }
          }
          .text {
            color: $gray200;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mediaOrigin) {
  #CarrierSubscribeNowWhyUs {
    .wrapper {
      .linker {
        .arrow {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #CarrierSubscribeNowWhyUs {
    .wrapper {
      align-items: center;
      .title {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $mediaMedium) {
  #CarrierSubscribeNowWhyUs {
    .wrapper {
      .title {
        line-height: normal;

        span {
          border: none;
          background: none;
          padding: 0;
        }
      }
      .linker {
        .answersContainer {
          .answer {
            width: 100%;
            .text {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
