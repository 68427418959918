@import "./../../../styles/dashboard/variables.scss";

#OrderDetailsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  gap: 12px;

  .right {
    display: flex;
    gap: 4px;
    align-items: center;
    align-self: flex-end;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #OrderDetailsHeader {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OrderDetailsHeader {
    .right {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
