@import "./../../../styles/dashboard/variables.scss";

#DTextarea {
  textarea {
    background-color: $white;
    width: 100%;
    resize: none;
    height: 80px;
    padding: 10px 12px;
    box-sizing: border-box;
    border: 1px solid $secondary100;
    border-radius: $borderRadius;
    outline: none;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    user-select: none;
    color: $primaryContent;

    &::placeholder {
      color: $secondary75;
      font-weight: 400;
    }

    &:focus {
      // border-color: $primaryBrand;
      &::placeholder {
        color: $secondary75;
        font-weight: 400;
      }
    }
  }

  &.error {
    textarea {
      border: 1px solid $error;
    }
  }
}
