@import "../../../styles/dashboard/variables.scss";

#OrderDetailsWrapper {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid $secondary50;
  margin-top: 24px;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerItems {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .moreDetails {
      display: flex;
      align-items: center;
      justify-content: flex-end !important;
      gap: 8px;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $info;
          font-size: 22px;
        }
      }
      .title {
        color: $info;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .orderDetailsContainer {
    width: 100%;
    .orderDetails {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
    }
  }
}

@media only screen and (max-width: $mediaOrigin) {
  #OrderDetailsWrapper {
    .orderDetailsContainer {
      .orderDetails {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #OrderDetailsWrapper {
    .header {
      flex-direction: column;
      gap: 8px;
      .headerItems {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .orderDetailsContainer {
      .orderDetails {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #OrderDetailsWrapper {
    padding: 12px;
    .header {
      .headerItems {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
