@import "./../../../../../styles/dashboard/variables.scss";
#ShipperCardsContainerItem {
  h1 {
    color: $secondary200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .desc {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
    flex-wrap: wrap;
    h3 {
      color: $secondary300;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      white-space: nowrap;
    }
    p {
      color: $secondary100;
      font-size: 13px;
      font-style: normal;
      white-space: nowrap;
      font-weight: 400;
      span {
        color: $primary500;
      }
      &.dec {
        span {
          color: $error;
        }
      }
      &.inc {
        span {
          color: $primary500;
        }
      }
    }
  }
}
