@import "./../../../../../styles/dashboard/variables.scss";
#SendInvitationButton {
  padding: 8px 10px;
  vertical-align: middle;
  text-align: center;
  #DButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      font-size: 14px;
      min-height: 35px;
    }
  }
}
