@import "../../../../styles/landing/variables.scss";

#SignUpCarrier {
  // display: grid;
  // grid-template-columns: 1fr 600px;
  min-height: $pageMinHeight;
  display: flex;
  align-items: center;
}

// @media only screen and (max-width: $mediaLarge) {
//   #SignUpCarrier {
//     grid-template-columns: 1fr;
//   }
// }
