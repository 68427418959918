@import "../../../styles/dashboard/variables.scss";

#ShipperCalendarHeader {
  width: 100%;
  display: flex;

  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid $secondary50;
  padding: 6px 0;
  .shipperCalendarHeaderItem {
    width: calc(100% / 14);
    display: flex;
    align-items: center;
    justify-content: center;

    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

@media screen and (max-width: $mediaLarge) {
  #ShipperCalendarHeader {
    .shipperCalendarHeaderItem {
      width: 100px;
      min-width: 100px;
    }
  }
}
