@import "./../../../../styles/dashboard/variables.scss";

#AddDriversModal {
  width: 100%;
  display: flex;
  justify-content: center;
  .AddDriversModalGroup {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #AddDriversModal {
    .AddDriversModalGroup {
      width: 100%;
    }
  }
}
