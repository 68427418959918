@import "./../../../../../styles/dashboard/variables.scss";

#SelectDriverModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h1 {
      color: $secondary300;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      span {
        color: $secondary100;
        font-size: 15px;
      }
    }
  }
  .SelectDriverModalHeaderIcon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 18px;
      color: $secondary100;
    }
  }
}
