@import "../../../../styles/dashboard/variables.scss";

#ProductDetailsShipper {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $secondary50;
    background-color: $primary100;
    padding: 12px 24px;
    gap: 10px;
    border-radius: $borderRadius;
    .leftSide {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 20px;
          color: $secondary200;
        }
      }
      .title {
        color: $secondary500;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
    }
    .rightSide {
      color: $secondary100;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .items {
    max-height: 408px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .cargoReadyDate {
      padding: 12px 16px;
      border-radius: 4px;
      background: $infoBG;

      color: $info;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      span {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #ProductDetailsShipper {
    .titleContainer {
      padding: 12px;
    }
  }
}
