@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderSectionTitle {
  color: $secondary300;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

@media only screen and (max-width: $mediaSmall) {
  #CreateOrderSectionTitle {
    font-size: 18px;
  }
}
