@import "../../../../../styles/dashboard/variables.scss";

#AirProgressBar {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 50%;
  width: 100%;
  .dotContainer {
    position: absolute;
    transform: translateX(-50%);
    top: 14px;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $white;
    }
  }

  .progressLine {
    position: absolute;
    left: 0;
    top: 12px;
    background: $primary500;
    height: 10px;
    border-radius: 8px;
  }
  .progressBar {
    width: 100%;
    height: 10px;

    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0;

    border-radius: 8px;
    background: $secondary50;
  }
  .progressIconContainer {
    position: absolute;
    transform: translateX(-50%);
    top: 2px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .progressIcon {
      width: 30px;
      height: 30px;
      border: 2px solid $primary500;
      border-radius: 100%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $primary500;
        }
      }
    }
  }
  .shippingTypeInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 2px solid #e9ebec;
    border-radius: 4px;
    border-top: none;
    .shippingTypeInfo {
      margin-bottom: 0;
      color: #878a99;

      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $primary500;
      }
      &.progress {
        svg {
          color: $info;
        }
      }
    }
  }
}
