@import "../../../../styles/dashboard/variables.scss";

#ShippingTypeField {
  vertical-align: middle;
  padding: 12px 10px;

  .ShippingTypeFieldContainer {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $secondary100;
      }
    }
    p {
      color: $secondary300;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
    }
  }
}

.ShippingTypeFieldTooltip {
  background-color: #00b56c !important;
  color: #ffffff;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 16px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}
