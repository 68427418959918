@import "./../../../styles/dashboard/variables.scss";

#DDatepicker {
  width: 100%;

  #DDatepickerButton {
    width: 100%;
    background-color: $white;
    border: 1px solid $secondary100;
    height: 40px;
    border-radius: $borderRadius;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      user-select: none;
      color: $primaryContent;
      .valuetext {
        color: $primaryContent;
      }
      .placeholder {
        color: $secondary100;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $secondary100;
        font-size: 18px;
      }
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    /* Large */
    box-shadow: 0px 4px 40px 0px #eee, 0px 4px 8px 0px rgba(66, 71, 76, 0.05),
      0px 0px 0.5px 0px rgba(66, 71, 76, 0.32);
    border: none;
    border-radius: 12px;
    width: 100%;

    .react-datepicker__triangle {
      display: none;
    }
  }

  .react-datepicker__day {
    color: $primaryContent;
    user-select: none;
  }
  .react-datepicker__day--outside-month {
    color: $secondary50;
    pointer-events: none;
    user-select: none;
  }
  .react-datepicker__day--selected {
    background-color: $primaryBrand;
  }
  .react-datepicker__header {
    background-color: $white;
    border-bottom: 1px solid #f4f6f9;

    .react-datepicker__day-names {
      padding: 4px 16px;
      border-top: 1px solid #f4f6f9;
      .react-datepicker__day-name {
        color: $secondary100;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  .react-datepicker__current-month {
    padding: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  &.error {
    #DDatepickerButton {
      border: 1px solid $error;
      .icon {
        svg {
          fill: $error;
        }
      }
    }
  }
}
