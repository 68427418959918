@import "./../../../../styles/landing/variables.scss";

#CoverFinance.CoverFinance {
  width: 100%;
  height: $pageMinHeight;
  background-color: $grayNormal;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 300px;

  .blar {
    width: 100%;
    height: 100%;
    background-color: rgba($primaryContent, $alpha: 0.3);

    .wrapper {
      height: 100%;
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        width: 100%;
        z-index: 1;
        text-align: center;
        margin-bottom: 50px;
        h2 {
          font-size: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #CoverFinance.CoverFinance {
    .blar {
      .container {
        .text {
          margin-bottom: 30px;
          h2 {
            font-size: 50px;
          }
        }
      }
    }
  }
}
