@import "../../../../styles/landing/variables.scss";

#CoverCarrier.CoverCarrier {
  width: 100%;
  height: $coverHeight;
  background-color: $grayNormal;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 300px;
  .blar {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      179deg,
      rgba(30, 37, 41, 0) 0.97%,
      rgba(30, 37, 41, 0.6) 97.16%
    );
    .wrapper {
      height: 100%;
    }
    .container {
      position: relative;
      width: 100%;
      height: 100%;

      .text {
        position: absolute;
        width: 80%;
        bottom: 50px;
        left: 0;
        z-index: 1;
        .desc {
          width: auto;
          display: flex;
          align-items: flex-start;
          p {
            color: $primarySurfance;
            border-radius: 16px;
            background: linear-gradient(
                0deg,
                rgba(132, 138, 141, 0.1) 0%,
                rgba(132, 138, 141, 0.1) 100%
              ),
              rgba(240, 252, 247, 0.2);
            backdrop-filter: blur(1px);
            padding: 12px 24px;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #CoverCarrier.CoverCarrier {
    .blar {
      background-color: rgba($primaryContent, $alpha: 0.3);
      .container {
        .text {
          width: 100%;
          bottom: 50%;
          .desc {
            justify-content: center;
            p {
              font-size: 24px;
            }
          }
          h2 {
            text-align: center;
          }
        }
      }
    }
  }
}
