@import "./../../../../../styles/landing/variables.scss";
#CarrierAppCover {
  width: 100%;
  height: 486px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media only screen and (max-width: $mediaSmall) {
  #CarrierAppCover {
    height: 300px;
  }
}
