@import "./../../../../../styles/dashboard/variables.scss";
#ShipperNavItem {
  align-items: center;
  user-select: none;
  a {
    color: $secondary25;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 4px 16px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    &:hover,
    &.active {
      background: $secondary200;
    }
    &.disabled {
      pointer-events: none;
      color: $secondary75;
    }
  }
}
