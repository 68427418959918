@import "./../../../../styles/dashboard/variables.scss";

#TruckStatusField {
  padding: 4px 10px;
  vertical-align: middle;

  .text {
    padding: 4px 12px;
    background-color: #edf9f0;
    display: inline-flex;
    border-radius: 34px;

    p {
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: #287d3c;
      white-space: nowrap;
    }
    &.inActive {
      background-color: #feefef;
      p {
        color: $error;
      }
    }
  }
}
