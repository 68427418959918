@import "./../../../../styles/dashboard/variables.scss";

#ShipperActiveOrdersList {
  width: 100%;

  .table {
    border-collapse: collapse;
    width: 100%;
    thead {
      background-color: $primary50;
      tr {
        width: 100%;
        th {
          padding: 12px 10px;
          color: $secondary500;
          font-size: 14px;
          font-weight: 600;
          text-align: start;
          white-space: nowrap;
          vertical-align: middle;
          border-bottom: 1px solid $secondary50;
        }
      }
    }
  }
}
