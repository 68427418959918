@import "./../../../../../styles/dashboard/variables.scss";
#ShipperChartSwitcher {
  border: 1px solid $secondary50;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  .chartsSwitcherItem {
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    &.active {
      color: $secondary0;
      background: $secondary200;
      transition: all 0.3s ease-in-out;
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ShipperChartSwitcher {
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    flex-direction: column;
    .chartsSwitcherItem {
      width: 100%;
      padding: 4px 6px;
    }
  }
}
