@import "./../../../../../styles/dashboard/variables.scss";

#RouteNewOrder {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background-color: $white;

  .RouteNewOrderWrapper {
    display: flex;
    gap: 100px;
    padding-left: 30px;
    width: 100%;
    max-width: 1500px;

    .locationsWrapper {
      width: 50%;
      max-width: 320px;
    }
    .dateWrapper {
      width: 50%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      .pickUpContainer {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 14px;
        .timeSelectContainer {
          width: 100%;
          display: flex;
          gap: 12px;
          .removeButton {
            padding-top: 26px;
          }
        }
        .addTimeRangeButton {
          padding-top: 26px;
        }
      }
      .dropOffDate {
        display: flex;
        flex-direction: column;
        .dropOffContainer {
          width: 100%;
          display: flex;
          align-items: flex-start;
          gap: 14px;
          .dropOffTimeSelectContainer {
            width: 100%;
            display: flex;
            gap: 12px;
            .dropOffRemoveButton {
              padding-top: 26px;
            }
          }
          .dropOffAddTimeRangeButton {
            padding-top: 26px;
            svg {
              color: $info;
            }
          }
        }
        #DTextButton {
          button {
            padding-left: 0;
            .buttonIcon {
              color: $primary500;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  #RouteNewOrder {
    .RouteNewOrderWrapper {
      flex-direction: column;
      gap: 50px;
      .locationsWrapper {
        width: 70%;
      }
      .dateWrapper {
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: $mediaMedium) {
  #RouteNewOrder {
    padding: 12px;
    gap: 12px;
    .RouteNewOrderWrapper {
      flex-direction: column;
      gap: 30px;
      .locationsWrapper {
        width: 80%;
      }
      .dateWrapper {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #RouteNewOrder {
    .RouteNewOrderWrapper {
      padding-left: 20px;
      padding-right: 40px;
      width: 100%;
      .locationsWrapper {
        width: 100%;
      }
      .dateWrapper {
        width: 100%;
        gap: 12px;
        .pickUpContainer {
          flex-direction: column;
          .addTimeRangeButton {
            padding-top: 0;
          }
          .timeSelectContainer {
            .removeButton {
              padding-top: 29px;
            }
          }
        }
        .dropOffDate {
          .dropOffContainer {
            flex-direction: column;
            .dropOffTimeSelectContainer {
              .dropOffRemoveButton {
                padding-top: 29px;
              }
            }
            .dropOffAddTimeRangeButton {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}
