@import "../../../styles/landing/variables.scss";

#ForCustomers.ForCustomers {
  overflow: hidden;
  background-color: $primarySurfance;
  margin-top: 48px;
  margin-bottom: 48px;
  .container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #ForCustomers.ForCustomers {
    .container {
      grid-template-columns: 1fr;
    }
  }
}
