@import "../../../../../../styles/dashboard/variables.scss";

#InvoiceFormFooter {
  width: 100%;
  padding: 20px 10px;

  #DButton {
    button {
      width: 100%;
    }
  }
}
