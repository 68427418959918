@import "./../../../../styles/dashboard/variables.scss";
#ShipperCardsSwitcher {
  margin-top: 12px;
  .ShipperCardsSwitcherHeader {
    padding: 4px;
    display: flex;
    align-items: center;
    .shipperCardsSwitcherItem {
      padding: 8px 12px;
      cursor: pointer;
      color: $secondary200;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      border-bottom: 1px solid $secondary50;
      &.active {
        border-bottom: 1px solid $success;
        color: $success;
      }
    }
  }
}
@media only screen and (max-width: $mediaSmall) {
  #ShipperCardsSwitcher {
    margin-top: 12px;
    .ShipperCardsSwitcherHeader {
      width: 100%;
      flex-direction: column;
      .shipperCardsSwitcherItem {
        width: 100%;
      }
    }
  }
}
