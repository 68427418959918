@import "../../../../styles/landing/variables.scss";

#ProgressBar {
  width: 100%;
  height: 2px;
  position: relative;
  background-color: #d9d9d9;

  .progress {
    height: 100%;
    background-color: $primaryBrand;
  }
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    // overflow: hidden;
    background-color: $primaryBrand;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
