@import "../../../styles/landing/variables.scss";

#SelectForm {
  .selectComponent {
    .select__control {
      border-radius: $borderRadius;
      border: 1px solid $secondaryBorder;
      background-color: $primarySurfance;
      padding: 10px 0px 10px 12px;
      transition: all 0.3s ease-in-out;
      height: 44px;
      cursor: pointer;
      .select__value-container {
        padding: 0;
        .select__single-value {
          margin: 0;
          overflow: visible;
        }
      }

      &:hover {
        border-color: $primaryBrand;
      }
    }
    .select__control--is-focused {
      border-color: $primaryBrand;
      box-shadow: none;
    }
    .select__placeholder {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: $tetriaryContent;
      margin: 0;
    }
    .select__indicators {
      user-select: none;
      .select__indicator-separator {
        margin: 0;
        display: none;
      }
      .select__indicator {
        padding: 0;
        margin: 0;
        margin-right: 8px;
        margin-left: 8px;
        user-select: none;
        cursor: pointer;

        svg {
          color: $secondaryContent;
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }
    }
    .select__input-container {
      margin: 0;
      padding: 0;
    }
    .css-1dimb5e-singleValue {
      color: black;
      font-size: 16px;
    }
    .select__menu {
      // max-height: 200px;
      border-radius: $borderRadius;
      border: 1px solid #bfbfcd;
      box-shadow: none;
      margin-bottom: 0;
      margin-top: 5px;
      // overflow: hidden;
      .select__menu-list {
        max-height: 150px;
        padding: 0;
        .select__option {
          cursor: pointer;
          color: black;
          font-size: 16px;
          padding: 10px 15px;
        }
        .select__option--is-focused {
          transition: all 0.2s ease-in-out;
          background-color: $green100;
        }
        .select__option--is-selected {
          transition: all 0.2s ease-in-out;
          background-color: #8addbb;
        }
      }
    }
  }
  .selectComponent.separator {
    .select__indicators {
      .select__indicator-separator {
        display: block;
      }
    }
  }
}

#SelectForm.error {
  .selectComponent {
    .select__control {
      border: 1px solid rgb(236, 12, 12);
    }
  }
}
