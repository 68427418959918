@import "../../../styles/dashboard/variables.scss";

#PlanningAssistantTableRow {
  display: flex;
  width: auto;
  overflow: hidden;
  .trailerContainer {
    display: flex;
    padding: 24px 16px;
    align-items: center;
    gap: 8px;
    border-right: 1px solid $secondary50;
    border-top: 1px solid $secondary50;
    border-bottom: 1px solid $secondary50;
    width: 200px;
    min-height: 86px;
    &.noOrder {
      background: $errorBG;
    }
    .trailerInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .trailerName {
        color: #2b353a;
        white-space: nowrap;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .trailerRegNums {
        color: #2b353a;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .tableMainRow {
    position: relative;
    display: flex;
    overflow: hidden;
    #PlanningAssistantTableDay {
      width: 70px;
      border-right: 1px solid $secondary50;
      border-top: 1px solid $secondary50;
      border-bottom: 1px solid $secondary50;
    }
    .noActiveShipmentContainer {
      position: absolute;
      top: 50%;
      left: 130px;
      transform: translate(-50%, -50%);

      display: flex;
      padding: 6px 8px;
      align-items: center;
      gap: 12px;

      border-radius: 8px;
      border: 1px solid $warning;
      background: $warningBG;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
          color: $secondary100;
        }
      }
      p {
        color: $secondary200;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
