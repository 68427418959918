@import "../../../styles/landing/variables.scss";

#LoginForm.LoginForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  #FormTitle {
    p {
      color: $primaryBrand;
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .additional {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      gap: 4px;
    }
  }
}
