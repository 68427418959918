@import "../../../../styles/dashboard/variables.scss";

#DGradientButton {
  button {
    min-height: 40px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 8px;
    border: none;
    background: $violetGradient;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    text-align: center;
    white-space: nowrap;
    &:hover {
      opacity: 0.8;
    }
    .buttonIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      svg {
        font-size: 16px;
      }

      &.leftIconButton {
        margin-right: 8px;
      }
      &.rightIconButton {
        margin-left: 8px;
      }
    }
  }

  &.disabled {
    button {
      background: linear-gradient(
        300deg,
        rgba(94, 43, 255, 0.3) 18.14%,
        rgba(94, 43, 255, 0.3) 69.4%
      );
      cursor: not-allowed;
    }
  }
}

@media only screen and (max-width: $mediaSmall) {
  #DGradientButton {
    width: 100%;
    button {
      min-width: 100%;
      font-size: 14px;

      .buttonIcon {
        height: 14px;
        width: 14px;
        svg {
          font-size: 14px;
        }

        &.leftIconButton {
          margin-right: 4px;
        }
        &.rightIconButton {
          margin-left: 4px;
        }
      }
    }
  }
}
