@import "../../../styles/dashboard/variables.scss";

#BCurrencyInput {
  width: 100%;
  .container {
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 4px;
  }
}
