@import "./../../../../../../../styles/dashboard/variables.scss";

#ChargeFormItem {
  width: 100%;
  display: grid;
  grid-template-columns: 10fr 3fr 3fr 3fr 1fr;
  gap: 8px;

  .remove {
    .icon {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 18px;
        color: $error;
      }
    }
  }
}
