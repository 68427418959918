@import "./../../../../../styles/dashboard/variables.scss";

#TrackerGPS {
  padding: $pageWrapper;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}

@media only screen and (max-width: $mediaLarge) {
  #TrackerGPS {
    padding: $pageWrapperResponsive;
  }
}
