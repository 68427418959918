@import "./../../../../styles/dashboard/variables.scss";
#ShipperNav {
  display: flex;
  gap: 16px;
  align-items: center;
  border-left: 1px solid $secondary200;
  justify-content: space-between;
  width: 100%;
  .ShipperNavLeft {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: 24px;
  }
  .ShipperNavRight {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
@media only screen and (max-width: $mediaLarge) {
  #ShipperNav {
    width: auto;
    border-left: 0;
    .ShipperNavLeft {
      display: none;
    }
    .ShipperNavRight {
      gap: 16px;
    }
  }
}
