@import "./../../../styles/dashboard/variables.scss";

#GenerateInvoiceModalLoadingView {
  background-color: white;
  width: 90vw;
  height: 85vh;
  max-width: 1400px;
  max-height: 800px;
  overflow: hidden;
}
#GenerateInvoiceModal {
  background-color: $secondary25;
  width: 90vw;
  height: 85vh;
  max-width: 1400px;
  max-height: 800px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 3fr 2fr;
  position: relative;

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;

    width: 28px;
    height: 28px;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px;
    background-color: white;

    svg {
      color: $secondary100;
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #GenerateInvoiceModal {
    display: grid;
    grid-template-columns: 1fr;

    .closeButton {
      right: 3px;
      top: 0px;
      width: 20px;
      height: 20px;
      svg {
        font-size: 18px;
      }
    }
  }
}
