@import "./../../../../styles/dashboard/variables.scss";

#DeleteExpensesModal {
  width: 100%;
  height: auto;
  p {
    color: $secondary200;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
  }
}
