@import "./../../../../styles/dashboard/variables.scss";

#DriversListHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 12px;
  flex-wrap: wrap;
  .DriversListHeaderLeft {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
@media only screen and (max-width: $mediaSmall) {
  #DriversListHeader {
    flex-direction: column-reverse;
    .DriversListHeaderLeft {
      flex-direction: column;
    }
  }
}
