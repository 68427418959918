@import "../../../../styles/landing/variables.scss";

#Copyright {
  padding-top: 16px;
  padding-bottom: 16px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      color: $tetriaryContent;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .controls {
    display: flex;
    align-items: center;
    gap: 12px;
    #Lang.Lang {
      display: flex;
      align-items: center;
      justify-content: center;
      .langButton {
        padding: 0px;
        color: $primarySurfance;
        .icon {
          svg {
            color: $primarySurfance;
          }
        }
      }
      .langContent {
        bottom: 100%;
      }
    }
    #CountrySelector {
      display: flex;
      align-items: center;
      justify-content: center;
      .countrySelectorButton {
        padding: 0px;
        color: $primarySurfance;
      }
      .countrySelectorContent {
        bottom: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mediaLarge) {
  #Copyright {
    .container {
      justify-content: center;
      h6 {
        text-align: center;
      }
    }
    .controls {
      display: none;
    }
  }
}
