@import "./../../../../styles/dashboard/variables.scss";

#CheckBoxField {
  width: 100%;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  .icon {
    width: 14px;
    height: 14px;
    border: 1px solid $primary500;
    border-radius: 2px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
      background-color: $primary500;
      svg {
        font-size: 14px;
        color: $white;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    .icon {
      border: 1px solid $primary100;
      &.checked {
        background-color: $primary100;
      }
    }
  }
}
