@import "../../../styles/landing/variables.scss";

#CountrySelector {
  position: relative;
  height: 100%;
  .countrySelectorButton {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    cursor: pointer;
    .title {
      color: $primarySurfance;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.14px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $primarySurfance;
        font-size: 16px;
      }
    }
  }
  .countrySelectorContent {
    display: none;
    right: 50%;
    transform: translateX(50%);
    width: auto;
    position: absolute;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: $borderRadiusLarge;
    .item {
      text-align: center;
      transition: all 0.3s ease-in-out;
      padding: 12px 24px;
      cursor: pointer;
      color: $primaryContent;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      background-color: $primarySurfance;
      &:hover {
        background-color: $green100;
      }
    }
  }
  &:hover .countrySelectorContent {
    display: block;
  }
}

@media only screen and (max-width: $mediaLarge) {
  #CountrySelector {
    display: none;
  }
}
