@import "../../../../styles/dashboard/variables.scss";

#FilterShippingTypeItem {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid $secondary100;
  cursor: pointer;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $secondary100;
      font-size: 16px;
    }
  }
  .title {
    color: $secondary100;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  }
  &.active {
    background-color: $secondary300;
    .icon {
      svg {
        color: $secondary0;
      }
    }
    .title {
      color: $secondary0;
    }
  }
}
