@import "../../../../styles/landing/variables.scss";

#LinkItem.LinkItem {
  margin-bottom: 12px;
  a {
    position: relative;
    color: $primarySurfance;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    &:hover .line {
      width: 100%;
      transition: width 0.3s ease, left 0.3s ease;
    }
    &:hover {
      color: #00b46c !important;
      transition: all 0.3s;
    }
  }
  .line {
    width: 0;
    height: 1px;
    background-color: #00b46c;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
  .phone {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: $mediaMedium) {
  #LinkItem.LinkItem {
    margin-bottom: 6px;
    a {
      font-size: 14px;
    }
  }
}
