@import "../../../../styles/dashboard/variables.scss";

#PaymentStatusField {
  vertical-align: middle;
  padding: 12px 10px;
  .PaymentStatusFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      width: 28px;
      height: 20px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 16px;
        color: $error;
      }
      &.done {
        svg {
          color: $primaryBrand;
        }
      }
    }
  }
}
.PaymentStatusFieldWarningTooltip {
  box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
  background: $secondary0 !important;
  border-radius: $borderRadius;
  .StatusFieldContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $secondary300;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      p {
        color: $secondary100;
      }
    }
  }
}
