@import "./../../../../../styles/dashboard/variables.scss";

#CreateOrderSectionSecondaryTitle {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  margin-top: 24px;
  h5 {
    color: $secondary300;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.84px;
    font-variant: all-small-caps;
    white-space: nowrap;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: $secondary50;
  }
}
