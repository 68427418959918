@import "./../../../styles/dashboard/variables.scss";

#FinancialFinishedTable {
  .FinancialSmalltitle {
    color: $secondary300;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    margin-bottom: 16px;
  }
}
