@import "../../../../styles/landing/variables.scss";

#CarrierSubscribeForm {
  width: 400px;
  display: flex;
  padding: 26px 24px;
  flex-direction: column;
  // height: 850px;

  border-radius: 8px;
  background: $primarySurfance;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
}

@media only screen and (max-width: 1200px) {
  #CarrierSubscribeForm {
    height: auto;
  }
}

@media only screen and (max-width: $mediaSmall) {
  #CarrierSubscribeForm {
    width: calc(100vw - 30px);
  }
}
